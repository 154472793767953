import { Box } from '@mui/material';
import { useMemo } from 'react';

interface IProps {
  url: string;
}
export const YouTubeFrame = ({ url }: IProps) => {
  const getYoutubeId = (youtubeUrl: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = youtubeUrl.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };
  const id = useMemo(() => getYoutubeId(url), [url]);
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <iframe
        width="560"
        height="315"
        src={'https://www.youtube.com/embed/' + id}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
};
