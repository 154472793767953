import { IFeSettings } from 'interfaces/settings.interface';
import appService from 'services/common';

const getSettings = () => {
  return appService.get('/fe-settings-manage');
};
const getUserSettings = () => {
  return appService.get('/fe-settings');
};
const postSettings = (data: IFeSettings) => {
  return appService.post('/fe-settings-manage', data);
};

export { getSettings, postSettings, getUserSettings };
