import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const UserRoundedProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 23.2297 7.36349 26.1993 9.22752 28.5458C10.2876 25.743 12.9963 23.75 16.1704 23.75H23.8296C27.0037 23.75 29.7124 25.743 30.7725 28.5458C32.6365 26.1993 33.75 23.2297 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25ZM28.7208 30.6312C28.4522 28.1674 26.3648 26.25 23.8296 26.25H16.1704C13.6352 26.25 11.5478 28.1674 11.2792 30.6312C13.6523 32.5801 16.6895 33.75 20 33.75C23.3105 33.75 26.3477 32.5801 28.7208 30.6312ZM3.75 20C3.75 11.0254 11.0254 3.75 20 3.75C28.9746 3.75 36.25 11.0254 36.25 20C36.25 28.9746 28.9746 36.25 20 36.25C11.0254 36.25 3.75 28.9746 3.75 20ZM20 11.25C17.9289 11.25 16.25 12.9289 16.25 15C16.25 17.0711 17.9289 18.75 20 18.75C22.0711 18.75 23.75 17.0711 23.75 15C23.75 12.9289 22.0711 11.25 20 11.25ZM13.75 15C13.75 11.5482 16.5482 8.75 20 8.75C23.4518 8.75 26.25 11.5482 26.25 15C26.25 18.4518 23.4518 21.25 20 21.25C16.5482 21.25 13.75 18.4518 13.75 15Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
