import { Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog, CustomInput } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IMainUserData, IUserOnboardingData } from 'interfaces/user.interface';
import { useState } from 'react';

interface IChangeNameDialogProps {
  open: boolean;
  editUser: IUserOnboardingData | undefined;
  onClose: () => void;
  onSubmit: (userId: string, data: IMainUserData) => void;
}

export const ChangeNameDialog = ({ open, onClose, onSubmit, editUser }: IChangeNameDialogProps) => {
  const { t } = useTranslation();
  const [changedPersonalData, setchangedPersonalData] = useState<IMainUserData | undefined>(undefined);
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<IMainUserData>({
    defaultValues: {
      firstName: editUser?.userFirstName,
      lastName: editUser?.userLastName,
    },
  });

  const localSubmit = (data: IMainUserData) => {
    if (data) {
      setchangedPersonalData(data);
    }
  };
  const handleClose = () => {
    onClose();
    resetField('firstName');
    resetField('lastName');
  };

  const submitChanges = () => {
    if (changedPersonalData && editUser) {
      setchangedPersonalData(undefined);
      onSubmit(editUser?.userId ?? '', changedPersonalData);
      handleClose();
    }
  };

  return (
    <>
      <CustomDialog open={open} onClose={handleClose}>
        <Stack component={'form'} onSubmit={handleSubmit(localSubmit)} spacing={3}>
          <Typography variant="h3">{t('registerView.nameEditionTitle')}</Typography>
          <Controller
            control={control}
            name={`firstName`}
            defaultValue={editUser?.userFirstName}
            render={({ field }) => (
              <CustomInput
                error={!!errors?.firstName}
                {...field}
                label={t('users.userRegister.userName')}
                type={'text'}
              />
            )}
          />
          <Controller
            control={control}
            name={`lastName`}
            defaultValue={editUser?.userLastName}
            render={({ field }) => (
              <CustomInput
                error={!!errors?.lastName}
                {...field}
                label={t('users.userRegister.userSurname')}
                type={'text'}
              />
            )}
          />
          <CustomButton
            text={t('button.saveAs')}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            onClick={() => null}
            type={'submit'}
            sx={{
              width: 1,
              mb: 2,
            }}
          />
          <CustomButton
            text={t('button.cancel')}
            buttonType={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={handleClose}
            sx={{
              width: 1,
            }}
          />
        </Stack>
      </CustomDialog>
      <CustomDialog open={!!changedPersonalData} onClose={() => setchangedPersonalData(undefined)}>
        <Stack spacing={3}>
          <Typography variant="body1">
            {t('registerView.nameEditionPopupMessage', {
              name: `${editUser?.userFirstName} ${editUser?.userLastName}`,
            })}
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <CustomButton
              text={t('common.yesIDo')}
              onClick={submitChanges}
              buttonType={ButtonType.PRIMARY}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
            <CustomButton
              text={t('button.cancel')}
              onClick={() => setchangedPersonalData(undefined)}
              buttonType={ButtonType.SECONDARY_ACCENT}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
          </Stack>
        </Stack>
      </CustomDialog>
    </>
  );
};
