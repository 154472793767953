import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const BadgeAddView: IView = {
  key: 'viewBadgeAdd',
  title: 'Dodawanie odznaki',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./add-bagde-manage')),
  path: ['/dodaj-odznake', '/edytuj-odznake/:id'],
};

export default BadgeAddView;
