import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserPreonboarding: IView = {
  key: 'viewUserPreonboarding',
  title: 'preonboardingHome.title',
  roles: IRoles.CANDIDATE,
  view: React.lazy(() => import('./home-employee')),
  path: '/',
};

export default ViewUserPreonboarding;
