import React from 'react';
import { CardMedia, Box, CardActions, Button, Theme, Typography, Link } from '@mui/material';
import FileIcon from 'assets/icons/FileIcon.svg';
import { UploadIcon } from 'assets/icons/UploadIcon';
import { useTranslation } from 'react-i18next';
interface IProps {
  onUpload?: any;
  onDownload?: () => void;
}
export const UploadFileCard: React.FC<IProps> = ({ onUpload, onDownload }) => {
  const { t } = useTranslation();
  return (
    <>
      <CardMedia
        component="img"
        image={FileIcon}
        alt="random icon"
        sx={(theme: Theme) => ({
          transform: 'translateY(13px)',
          width: 72,
          height: 92,
          mb: 5,
        })}
      />
      <Box position="absolute" top={127} left={130}>
        {/* <IconButton
          aria-label="show document"
          sx={(theme: Theme) => ({
            bgcolor: theme.palette.grey[300],
            width: 32,
            height: 32,
            '&:hover': {
              bgcolor: theme.palette.grey[300],
            },
          })}
        >
          <RemoveRedEyeOutlinedIcon />
        </IconButton> */}
      </Box>
      <Typography variant="subtitle2">
        Potrzebujesz znowu pliku?
        <Link
          variant="subtitle2"
          onClick={() => onDownload && onDownload()}
          sx={{ p: 0, cursor: 'pointer', fontWeight: 700 }}
        >
          Pobierz ponownie
        </Link>
      </Typography>
      <CardActions
        sx={{
          p: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Button
          fullWidth
          sx={{ borderRadius: 0, height: 74, fontSize: 16 }}
          variant="contained"
          color="primary"
          onClick={onUpload}
          startIcon={<UploadIcon />}
        >
          {t('button.addfile')}
        </Button>
      </CardActions>
    </>
  );
};
