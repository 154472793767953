import React from 'react';
import { TableCell, TableHead, TableRow, Typography, Stack, TableSortLabel, Box } from '@mui/material';
import { ICustomTableHeadProps, ITableColumn, Order } from 'interfaces/table.interface';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { v4 as uuid } from 'uuid';

export const CustomTableHead = (props: ICustomTableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {props.columns.map((column: ITableColumn) => (
          <TableCellCustom column={column} {...props} key={uuid()} />
        ))}
      </TableRow>
    </TableHead>
  );
};

interface ICellCustom {
  column: ITableColumn;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof any) => void;
  order?: Order;
  orderBy?: string;
}

export const TableCellCustom: React.FC<ICellCustom> = ({ column, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) onRequestSort(event, property);
  };

  return (
    <TableCell
      padding="none"
      width={`${column.width}%`}
      sortDirection={orderBy === column.id ? order : false}
      sx={{
        pb: '18px',
        '& .MuiTableSortLabel-root': {
          margin: 0,
        },
      }}
    >
      <Stack direction="row" spacing={1}>
        {column.label &&
          (column.id ? (
            <TableSortLabel
              active={orderBy === column.id}
              sx={(theme) => ({
                fontSize: '16px',
                mt: theme.spacing(1.25),
                textDecoration: orderBy === column.id ? 'underline' : 'none',
                '& .MuiTableSortLabel-icon': {
                  display: 'block',
                  opacity: 0.5,
                  color: 'rgba(0,0,0,0.6)',
                  fontSize: '18px',
                },
              })}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(String(column.id))}
              IconComponent={({ className }) => (
                <Box className={className} sx={{ ml: (theme) => theme.spacing(1) }}>
                  <PlayArrowOutlinedIcon
                    className="MuiTableSortLabel-icon"
                    sx={(theme) => ({
                      transform: 'rotate(-90deg)',
                      mb: theme.spacing(-1),
                    })}
                  />
                  <PlayArrowOutlinedIcon
                    className="MuiTableSortLabel-icon"
                    sx={(theme) => ({
                      transform: 'rotate(90deg)',
                      fill: orderBy === column.id ? theme.palette.primary.main : 'rgba(0,0,0,0.8)',
                    })}
                  />
                </Box>
              )}
            >
              {column.label}
            </TableSortLabel>
          ) : (
            <Typography variant="subtitle1" color={'rgba(0,0,0,0.8)'} sx={{ fontSize: '16px', fontWeight: '500' }}>
              {column.label}
            </Typography>
          ))}
      </Stack>
    </TableCell>
  );
};
