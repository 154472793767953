import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseDialogButtonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect width="24" height="24" fill="#323B4C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46968 4.46967C4.76258 4.17678 5.23745 4.17678 5.53034 4.46967L12 10.9393L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.0607 12L19.5304 18.4697C19.8232 18.7626 19.8232 19.2374 19.5304 19.5303C19.2375 19.8232 18.7626 19.8232 18.4697 19.5303L12 13.0607L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.9394 12L4.46968 5.53033C4.17679 5.23744 4.17679 4.76256 4.46968 4.46967Z"
        fill="white"
      />
    </SvgIcon>
  );
};
