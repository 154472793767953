import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.25C9.40608 3.25 3.25 9.40608 3.25 17C3.25 24.5939 9.40608 30.75 17 30.75C24.5939 30.75 30.75 24.5939 30.75 17C30.75 9.40608 24.5939 3.25 17 3.25ZM0.75 17C0.75 8.02537 8.02537 0.75 17 0.75C25.9746 0.75 33.25 8.02537 33.25 17C33.25 25.9746 25.9746 33.25 17 33.25C8.02537 33.25 0.75 25.9746 0.75 17ZM17 7.41667C17.6904 7.41667 18.25 7.97631 18.25 8.66667V16.4822L22.8839 21.1161C23.372 21.6043 23.372 22.3957 22.8839 22.8839C22.3957 23.372 21.6043 23.372 21.1161 22.8839L16.1161 17.8839C15.8817 17.6495 15.75 17.3315 15.75 17V8.66667C15.75 7.97631 16.3096 7.41667 17 7.41667Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
