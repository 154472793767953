import React from 'react';
import { Box, Stack, Theme, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { GreenCheckIcon, PlainCheckIcon } from 'assets/icons';
export interface ProgressItem {
  title: string;
  path: string;
  status: 'active' | 'done' | 'none';
}

interface ICreationProgressBarProps {
  progressItems: ProgressItem[];
  finishText: string;
  finishUrl: string;
  onClick?: () => void;
}

export const CreationProgressBar = ({ progressItems, finishText, finishUrl, onClick }: ICreationProgressBarProps) => {
  const handleItemStatus = (status: string) => {
    switch (status) {
      case 'active':
        return (
          <GreenCheckIcon
            width={20}
            height={20}
            viewBox="0 0 20 20"
            sx={(theme: Theme) => ({ mr: theme.spacing(1) })}
          />
        );
      case 'done':
        return (
          <PlainCheckIcon
            width={12}
            height={12}
            viewBox="0 0 12 12"
            sx={(theme: Theme) => ({ mr: theme.spacing(1), width: 12, height: 12 })}
          />
        );
      case 'none':
        return null;
      default:
        return null;
    }
  };

  const onClickFinshed = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Box
      sx={(theme: Theme) => ({
        border: '1px solid black',
        borderRadius: '50px',
        position: 'relative',
        display: 'flex',
        height: 60,
        p: theme.spacing(0, 4, 0, 4),
      })}
    >
      <Box
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.secondary.main,
          width: 119,
          height: 60,
          position: 'absolute',
          top: 0,
          right: -1,
          borderRadius: '0px 50px 50px 0px',
          zIndex: -1,
        })}
      />
      <Stack
        direction="row"
        spacing="70px"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {progressItems.map((item: ProgressItem) => (
          <Box
            key={item.title}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              maxWidth: 128,
            }}
          >
            {handleItemStatus(item.status)}
            <Typography variant="subtitle2">{item.title}</Typography>
          </Box>
        ))}
        <Link
          component={RouterLink}
          to={finishUrl}
          onClick={onClickFinshed}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CloseIcon sx={{ width: 24, height: 24, color: 'white', mr: '8px' }} />
          <Typography variant="subtitle2" color="white">
            {finishText}
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
};
