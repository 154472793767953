import axios from 'axios';
import { API_URL } from 'config/const';
import qs from 'qs';

const appService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params: string | string[]) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default appService;
