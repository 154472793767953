import { IDefaultInteface } from './common';

export interface IOnboardingTemplates extends IDefaultInteface {
  idUserCreated: string;
  value: string;
  createdDate: string;
  modifiedDate: string;
}

export interface IOnboadingUserTemplates extends IOnboardingTemplates {
  idTemplate: string;
  idTemplateUser: string;
}

export enum ITemplateFieldType {
  TEXT = 'text',
  MEDIA = 'media',
}

export interface ITemplateField {
  row: number;
  name: string;
  label: string;
  type: ITemplateFieldType;
  value?: string;
  defaultValue?: string;
}

export interface ITemplateSection {
  id?: string;
  name: string;
  label: string;
  index: number;
  disabled?: boolean;
  rows: number;
  fields: ITemplateField[];
}
export interface ITemplateRoot {
  label: string;
  sections: ITemplateSection[];
}
