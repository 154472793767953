import { userState } from './userSlice';
import { defaultConfig } from 'config/config';

const token = localStorage.getItem(defaultConfig.KEY_TOKEN);
// eslint-disable-next-line no-extra-boolean-cast
export const initialState: userState = token
  ? {
      token,
      loading: 'idle',
      details: {
        id: '',
        email: '',
        name: '',
        surname: '',
        avatar: '',
        round: {
          id: '',
          name: '',
        },
        created: '',
        claims: [''],
        roles: [
          {
            id: '',
            name: '',
          },
        ],
        language: '',
        hidePersonalData: false,
        gender: undefined,
        hideInRanking: false,
        extraDetails: {},
      },
    }
  : {
      token: '',
      loading: 'idle',
      details: {
        id: '',
        email: '',
        name: '',
        surname: '',
        avatar: '',
        round: {
          id: '',
          name: '',
        },
        created: '',
        roles: [
          {
            id: '',
            name: '',
          },
        ],
        claims: [''],
        language: '',
        hidePersonalData: false,
        gender: undefined,
        hideInRanking: false,
        extraDetails: {},
      },
    };
