import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewFooterSettings: IView = {
  key: 'viewFooterSettings',
  title: 'footer.title',
  view: React.lazy(() => import('./footer')),
  path: '/rejestracja',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  navigation: true,
};

export default ViewFooterSettings;
