import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 30 34" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9998 3.25C8.3264 3.25 2.9165 8.65989 2.9165 15.3333C2.9165 21.8103 8.07624 27.3383 14.9998 30.6224C21.9234 27.3383 27.0832 21.8103 27.0832 15.3333C27.0832 8.65989 21.6733 3.25 14.9998 3.25ZM0.416504 15.3333C0.416504 7.27918 6.94568 0.75 14.9998 0.75C23.054 0.75 29.5832 7.27918 29.5832 15.3333C29.5832 23.4242 23.0615 29.7229 15.5153 33.1388C15.1877 33.2871 14.812 33.2871 14.4844 33.1388C6.93817 29.7229 0.416504 23.4242 0.416504 15.3333ZM14.9998 11.5833C12.9288 11.5833 11.2498 13.2623 11.2498 15.3333C11.2498 17.4044 12.9288 19.0833 14.9998 19.0833C17.0709 19.0833 18.7498 17.4044 18.7498 15.3333C18.7498 13.2623 17.0709 11.5833 14.9998 11.5833ZM8.74984 15.3333C8.74984 11.8816 11.5481 9.08333 14.9998 9.08333C18.4516 9.08333 21.2498 11.8816 21.2498 15.3333C21.2498 18.7851 18.4516 21.5833 14.9998 21.5833C11.5481 21.5833 8.74984 18.7851 8.74984 15.3333Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
