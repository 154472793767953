import { SvgIcon, SvgIconProps } from '@mui/material';

export const SmileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <circle cx="12.5" cy="12" r="10" fill="#FFBE00" />
      <path
        d="M8.5 14C8.5 14 10 16 12.5 16C15 16 16.5 14 16.5 14M9.5 9H9.51M15.5 9H15.51"
        stroke="#111826"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
