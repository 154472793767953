import { usersTasksState } from './userTasksSlice';

export const initialState: usersTasksState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
};
