import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import PDFDefaultImage from 'assets/images/bg_pdf_default.png';
import { PdfView } from '../pdf-view';
import Slider, { Settings } from 'react-slick';
import { ArrowChevronUp } from 'assets/icons';
import { DownArrow } from 'assets/icons/DownArrow';
interface IProps {
  presetations: any[];
  filesTitle: string[];
}
export const PresentationSlider: React.FC<IProps> = ({ presetations, filesTitle }) => {
  const slider = useRef<Slider>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const settingsPresentationSlider: Settings = {
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 2,
    dots: true,
    afterChange: (current) => setCurrentPage(current + 1),
    customPaging: (i: number) => <></>,
    appendDots: (dots) => (
      <Box
        sx={(theme) => ({
          top: 'auto',
          left: 'auto',
          right: 132,
          fontSize: '16px',
          width: 'auto',
          bottom: -27,
          color: theme.palette.common.white,
        })}
      >
        {currentPage} / {presetations.length}
      </Box>
    ),
    prevArrow: (
      <DownArrow
        sx={(theme) => ({
          transform: 'rotate(90deg)',
          right: 72,
          bottom: -24,
          width: 17,
          height: 17,
          top: 'auto',
          left: 'auto',
          '& path': {
            fill: theme.palette.common.white,
          },
        })}
      />
    ),
    nextArrow: (
      <DownArrow
        sx={(theme) => ({
          transform: 'rotate(-90deg)',
          right: 24,
          bottom: -24,
          width: 17,
          height: 17,
          top: 'auto',
          left: 'auto',
          '& path': {
            fill: theme.palette.common.white,
          },
        })}
      />
    ),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        '& .slick-slider .slick-list': {
          width: '70vw',
        },
      }}
    >
      {presetations.length === 1 ? (
        <div key={presetations[0].id}>
          <PdfView
            key={presetations[0].id}
            link={presetations[0].url}
            text={filesTitle[0]}
            background={presetations[0].thumbnail ?? PDFDefaultImage}
          />
        </div>
      ) : (
        <Slider ref={slider} {...settingsPresentationSlider}>
          {presetations.map(({ id, url, text, thumbnail }, index) => (
            <div key={id}>
              <PdfView key={id} link={url} text={filesTitle[index]} background={thumbnail ?? PDFDefaultImage} />
            </div>
          ))}
        </Slider>
      )}
    </Box>
  );
};
