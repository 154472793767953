import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { PutSchemaField, SchemaField } from 'interfaces/schema-form';
import { IUserOnboardingDetails, IUserOnboardingExtraDetails } from 'interfaces/user.interface';
import { RootState } from 'store';
import { getUsersListAPI, postUserAPI, putUserAPI } from 'store/usersList/usersListAPI';
import {
  fetchOnboardingSchemaForm,
  fetchOnboardingSchemaUserForm,
  fetchUserExtraDetails,
  postOnboardingSchemaForm,
  putOnboardingSchemaForm,
  putUserExtraDetails,
} from './onboardingUserDetailsAPI';
import { initialState } from './onboardingUserDetailsInitial';

export interface OnboardingUserState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  status: string;
  data: IUserOnboardingDetails;
  schema: SchemaField[];
  currentRequestId?: string;
}

export const fetchOnboardingUserDetails = createAsyncThunk('onboardingDetails/fetch', async (userId: string) => {
  try {
    const response = await getUsersListAPI({ userIds: userId });
    return response.data.value.records[0];
  } catch (err) {
    return err;
  }
});

export const fetchOnboardingUserExtraDetails = createAsyncThunk(
  'onboardingDetails/fetchExtra',
  async (userId: string) => {
    try {
      const response = await fetchUserExtraDetails(userId);
      return response.data.value;
    } catch (err) {
      return err;
    }
  }
);

export const createUser = createAsyncThunk('onboardingDetails/postUser', async (params: object) => {
  try {
    const response = await postUserAPI(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const editUser = createAsyncThunk('onboardingDetails/putUser', async (params: object) => {
  try {
    const response = await putUserAPI(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const fetchSchemaForm = createAsyncThunk('onboardingDetails/fetchSchema', async () => {
  try {
    const response = await fetchOnboardingSchemaForm();
    return response;
  } catch (err) {
    return err;
  }
});
export const fetchUserSchemaForm = createAsyncThunk('onboardingDetails/fetchUserSchema', async () => {
  try {
    const response = await fetchOnboardingSchemaUserForm();
    return response;
  } catch (err) {
    return err;
  }
});

interface IPutExtraDetails {
  userId: string;
  content: {
    [k: string]: string | boolean | number;
  };
}

export const putOnboardingUserExtraDetails = createAsyncThunk(
  'onboardingExtradetails/putExtraDetails',
  async ({ userId, content }: IPutExtraDetails, { getState }) => {
    try {
      const response = (await putUserExtraDetails(userId, content)) as any;
      return response;
    } catch (err) {
      return err;
    }
  }
);
interface IPayload {
  field: PutSchemaField;
  currentId?: string;
}
export const putSchemaForm = createAsyncThunk('onboardingDetails/putSchema', async ({ field, currentId }: IPayload) => {
  try {
    if (currentId) {
      await putOnboardingSchemaForm(currentId, field);
    }
  } catch (error) {
    return error;
  }
});
export const { actions, reducer } = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    createNewUser(state, action: PayloadAction<IUserOnboardingDetails>) {
      state.data = action.payload;
    },
    addExtraDetails(state, action: PayloadAction<IUserOnboardingExtraDetails>) {
      state.data.extraDetails = action;
    },
    addCVId(state, action: PayloadAction<string>) {
      state.data.extraDetails.push({
        CvId: action,
      });
    },
    clearOnboardingUser: () => initialState,
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingUserDetails.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserDetails.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(editUser.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = {
            ...state.data,
            ...action.meta.arg,
          };
          state.currentRequestId = undefined;
        }
      })
      .addCase(editUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(editUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchOnboardingUserExtraDetails.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.extraDetails = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserExtraDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserExtraDetails.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(putOnboardingUserExtraDetails.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          if (action.meta.arg) {
            if (state.data.extraDetails) {
              if (state.data.extraDetails.content) {
                state.data.extraDetails = {
                  ...state.data.extraDetails,
                  content: {
                    ...state.data.extraDetails.content,
                    ...action.meta.arg.content,
                  },
                };
              } else {
                state.data.extraDetails = {
                  ...state.data.extraDetails,
                  content: action.meta.arg.content,
                };
              }
            } else {
              state.data.extraDetails = {
                content: action.meta.arg.content,
              };
            }
          }
          state.currentRequestId = undefined;
        }
      })
      .addCase(putOnboardingUserExtraDetails.rejected, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(putOnboardingUserExtraDetails.pending, (state, action: any) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const { requestId, arg } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          if (action.meta.arg) {
            state.data = action.meta.arg as any;
          }
          state.currentRequestId = undefined;
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchSchemaForm.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchSchemaForm.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.schema = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchSchemaForm.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUserSchemaForm.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUserSchemaForm.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.schema = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUserSchemaForm.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(putSchemaForm.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(putSchemaForm.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(putSchemaForm.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectUserDetails = (state: RootState) => state.onBoardingUserDetails;
export const selectSchema = (state: RootState) => state.onBoardingUserDetails.schema;
export const { createNewUser, addExtraDetails, addCVId, clearOnboardingUser, reset } = actions;
export default reducer;
