import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface ITinymceEditorProps {
  value: string;
  setFormValues: (value: string, name: string) => void;
  height?: number;
  onLoad?: () => void;
}
export const TinymceEditor = ({ value, setFormValues, height, onLoad }: ITinymceEditorProps) => {
  const editorRef = useRef<any>(null);

  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      onInit={(evt, editor) => {
        editorRef.current = editor;
        if (onLoad) onLoad();
      }}
      value={value}
      onEditorChange={(newValue, editor) => setFormValues(newValue, 'content')}
      init={{
        height: height ?? 500,
        mobile: {
          width: 600,
          menubar: true,
        },
        menubar: true,
        convert_urls: false,
        relative_urls: false,
        extended_valid_elements: 'iframe[src|title|width|height|allowfullscreen|frameborder|class|id]',
        language: 'pl',
        entity_encoding: 'raw',
        branding: true,
        language_url: '/tinymce/langs/pl.js',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family: "niveau-grotesk",sans-serif; font-size:20px }',
      }}
    />
  );
};
