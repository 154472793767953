import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, Box, Theme } from '@mui/material';

interface ILinkItemProps {
  url: string;
  title: string;
  active: boolean;
  onClick?: () => void;
}
export const LinkItem = ({ active, url, title, onClick }: ILinkItemProps) => {
  return (
    <Link
      onClick={onClick}
      component={RouterLink}
      to={url}
      underline="none"
      sx={{
        position: 'relative',
        width: 'fit-content',
        mt: { xs: 4, md: 0 },
        color: (theme) => theme.palette.secondary.dark,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: { xs: 40, md: 20 },
        }}
      >
        {title}
      </Typography>
      {active && (
        <Box
          width={1}
          bgcolor={(theme: Theme) => theme.palette.secondary.dark}
          height={6}
          sx={{
            position: 'absolute',
          }}
        />
      )}
    </Link>
  );
};
