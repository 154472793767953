import { Box, Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useTranslation } from 'react-i18next';

interface IDeleteTagDialogProps {
  open: boolean;
  onClose: () => void;
  id: string;
  onDelete: (id: string) => void;
}
export const DeleteTagDialog = ({ open, id, onClose, onDelete }: IDeleteTagDialogProps) => {
  const { t } = useTranslation();
  return (
    <CustomDialog open={open} onClose={onClose}>
      <Stack spacing={3}>
        <Typography variant="body1">{t('knowledgePages.dialogs.deleteTag')}</Typography>
        <Box display={'flex'}>
          <CustomButton
            type="button"
            text={t('button.delete')}
            onClick={() => onDelete(id)}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            sx={{
              width: 1,
              marginRight: 2,
            }}
          />
          <CustomButton
            type="button"
            text={t('button.back')}
            onClick={onClose}
            buttonType={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            sx={{
              width: 1,
            }}
          />
        </Box>
      </Stack>
    </CustomDialog>
  );
};
