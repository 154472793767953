import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingTasksView: IView = {
  key: 'viewOnboardingTasks',
  title: 'Onboarding zadania',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR, IRoles.SUPERIOR],
  view: React.lazy(() => import('./onboarding-tasks')),
  path: ['/onboarding/assigned-tasks/:userId'],
};

export default OnboardingTasksView;
