import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IRoles } from 'interfaces/roles.interface';
import { IFeSettings } from 'interfaces/settings.interface';
import { RootState } from 'store';
import { setCustomTranslations } from 'utils/translations';
import { getSettings, getUserSettings, postSettings } from './settingsAPI';
import { initialState } from './settingsInitial';

export interface settingsState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IFeSettings;
  currentRequestId?: string;
}

export const fetchFeSettings = createAsyncThunk('settings/fetch', async (_, { getState }) => {
  try {
    const state = getState() as RootState;
    if (state.user.details.roles?.find((role) => role.id === IRoles.ADMINISTRATOR || role.id === IRoles.HR)) {
      const response = await getSettings();
      return response.data.value;
    } else {
      const response = await getUserSettings();
      return response.data.value;
    }
  } catch (err) {
    return false;
  }
});
export const postFeSettings = createAsyncThunk('settings/post', async (settings: IFeSettings) => {
  try {
    await postSettings(settings);
    return settings;
  } catch (err) {
    return false;
  }
});

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeSettings.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFeSettings.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
          if(action.payload.other_settings?.customTranslations?.length) {
            setCustomTranslations(action.payload.other_settings.customTranslations);
          }
        }
      })
      .addCase(fetchFeSettings.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFeSettings.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postFeSettings.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          if (action.payload) {
            state.data = action.payload;
          }
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFeSettings.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectSettings = (state: RootState) => state.settings;
export const selectNavSettings = (state: RootState) => state.settings.data.user_menu;

export default settingsSlice.reducer;
