import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WriteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4493 1.11612C22.9374 0.627961 23.7289 0.627961 24.2171 1.11612L30.8837 7.78278C31.3719 8.27094 31.3719 9.06239 30.8837 9.55055L9.21705 31.2172C8.98263 31.4516 8.66469 31.5833 8.33317 31.5833H1.6665C0.976148 31.5833 0.416504 31.0237 0.416504 30.3333V23.6667C0.416504 23.3351 0.5482 23.0172 0.78262 22.7828L22.4493 1.11612ZM2.9165 24.1844V29.0833H7.8154L23.2321 13.6667L18.3332 8.76777L2.9165 24.1844ZM20.1009 7L24.9998 11.8989L28.2321 8.66667L23.3332 3.76777L20.1009 7ZM29.1665 31.5833H14.9998C14.3095 31.5833 13.7498 31.0237 13.7498 30.3333C13.7498 29.643 14.3095 29.0833 14.9998 29.0833H29.1665C29.8569 29.0833 30.4165 29.643 30.4165 30.3333C30.4165 31.0237 29.8569 31.5833 29.1665 31.5833Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
