import { useAppSelector } from 'hooks';
import { selectFileTags } from 'store/file-tags/fileTagsSlice';
import { useMemo } from 'react';

export const useFileTags = (selectedTagIds: string[]) => {
  const { data: fileTags } = useAppSelector(selectFileTags);
  const selectedTags = useMemo(() => {
    if (selectedTagIds?.length && fileTags.length) {
      return fileTags.filter((tag) => selectedTagIds.includes(tag.id));
    } else {
      return fileTags;
    }
  }, [selectedTagIds, fileTags]);
  return {
    selectedTags,
  };
};
