import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const UsersPage: IView = {
  key: 'viewEditTemplate',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  title: 'preonboardingTemplateUser.templateUserView.title',
  view: React.lazy(() => import('./template-user')),
  path: ['/edycja-szablonu/:userId'],
};

export default UsersPage;
