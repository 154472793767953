import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/common';
import { IOnboardingTemplates } from 'interfaces/onboarding-templates.interface';
import { RootState } from 'store';
import { getOnboardingTemplates } from './onboardingTemplatesAPI';
import { initialState } from './onboardingTemplatesInitial';
import { IResponseWithPagination } from 'interfaces/common';
import { PaginationClassKey } from '@mui/material';

export interface onboardingTemplateState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<IOnboardingTemplates[]>;
  pagination: IPagination;
  currentRequestId?: string;
}

export const fetchOnboardingTemplates = createAsyncThunk('onboardingTemplates/fetch', async (params?: object) => {
  try {
    const response = await getOnboardingTemplates(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const onboardingTemplates = createSlice({
  name: 'onboardingTemplates',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.data.page = action.payload + 1;
    },
    newPageSize: (state, action) => {
      state.data.size = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingTemplates.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.pagination = {
            page: action.payload.page,
            size: action.payload.size,
          };
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingTemplates.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingTemplates.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      });
  },
});

export const selectOnboardingTemplates = (state: RootState) => state.onBoardingTemplates;

export default onboardingTemplates.reducer;
