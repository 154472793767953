import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewFileManage: IView = {
  key: 'viewFileTemplateManage',
  title: 'fileTemplateView.title',
  view: React.lazy(() => import('./template-files-manage')),
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  navigation: true,
  path: '/szablony-plikow',
};

export default ViewFileManage;
