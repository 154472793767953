import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const DictionariesView: IView = {
  key: 'view.admin.dictionaries',
  title: 'dictionariesPage.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  navigation: true,
  view: React.lazy(() => import('./dictionaries-page')),
  path: '/slowniki-danych',
};

export default DictionariesView;
