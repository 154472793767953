export interface ITemplates {
  id: string;
  name: string;
  idUserCreated: string;
  createdDate: string;
  modifiedDate: string;
  value: string;
}

export enum ITemplateStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}
export enum EditFormType {
  USER = 'User',
  EMPLOYEE = 'Employee',
  TEMPLATE = 'Template',
}

export enum TemplateState {
  EDIT_USER = 'isEditUser',
  NEW_TEMPLATE = 'isNewTemplate',
  EDIT_TEMPLATE = 'isEditTemplate',
}

export enum TemplateViewState {
  VIEW_TEMPLATE = 'viewTemplate', //Widok podglądu szablonu z poziomu zakładki Szablony
  VIEW_USER = 'viewUser', //Widok kandydata
  VIEW_USER_TEMPLATE = 'viewUserTemplate', //Widok z poziomu edycji oraz podglądu szablonu z tabeli pracownicy
  VIEW_CREATED_USER = 'viewCreatedUser', //Widok z poziomu tworzenia użytkownika
}
