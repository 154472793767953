import React from 'react';
import { Grid, Box, SxProps, Theme } from '@mui/material';
import { centerFlex } from 'common-styles/common-styles';
import { MediaCustom } from './media';
import { IFileType } from './data-uploader/media-uploader';
import { YOUTUBE_EMBED } from 'config/const';
interface IProps {
  sx?: SxProps<Theme>;
  imageSrc: string;
  rtl?: boolean;
}
export const ContentWithImage: React.FC<IProps> = ({ children, imageSrc, sx = [], rtl = false }) => {
  const regexVideo =
    /((?:https?(?:%3A%2F%2F|:\/\/))(?:www\.)?(?:\S+)(?:%2F|\/)(?:(?!\.(?:mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)).)*\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))(?!\/|\.[a-z]{1,3})/gim;
  const isVideo = new RegExp(regexVideo).test(imageSrc);
  const isUrl = imageSrc.includes(YOUTUBE_EMBED);
  return (
    <Grid maxWidth={'xl'} sx={[{ ...centerFlex, margin: '0 auto' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {!rtl ? (
        <Grid
          container
          columns={16}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Grid item xs={16} md={8}>
            {children}
          </Grid>
          <Grid item xs={16} md={8}>
            <MediaCustom
              type={isVideo ? IFileType.VIDEO : isUrl ? IFileType.YOUTUBE : IFileType.IMAGE}
              src={imageSrc}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          columns={16}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Grid item xs={16} md={8}>
            <MediaCustom
              type={isVideo ? IFileType.VIDEO : isUrl ? IFileType.YOUTUBE : IFileType.IMAGE}
              src={imageSrc}
            />
          </Grid>
          <Grid item xs={16} md={8}>
            {children}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
