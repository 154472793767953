import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';
import BadgeAddView from './views/add';

const BadgesManage: IView = {
  key: 'viewBadgesManage',
  title: 'badgesView.title',
  roles: [IRoles.ADMINISTRATOR],
  view: React.lazy(() => import('./badges-manage')),
  path: '/zarzadzanie-odznakami',
  navigation: true,
  childrens: [BadgeAddView],
};

export default BadgesManage;
