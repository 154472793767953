import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import type { PreloadedState } from '@reduxjs/toolkit';
import currentPageSlice from './currentPage/currentPageSlice';
import faqSlice from './faq/faqSlice';
import filesSlice from './files/filesSlice';
import onboardingTemplatesSlice from './onboardingTemplates/onboardingTemplatesSlice';
import templateUserSlice from './onboardingUserTemplates/onboardingUserTemplatesSlice';
import onboardingUserDetailsSlice from './onboardingUserDetails/onboardingUserDetailsSlice';
import roundsSlice from './rounds/roundsSlice';
import rolesSlice from './roles/rolesSlice';
import userTasksSlice from './userTasks/userTasksSlice';
import settingsSlice from './settings/settingsSlice';
import userSlice from './user/userSlice';
import dictionariesSlice from './dictionaries/dictionariesSlice';
import usersListSlice from './usersList/usersListSlice';
import knowledgePagesSlice from './knowledgePages/knowledgePagesSlice';
import onboardingTasks from './onboardingTasks/onboardingTasksSlice';
import missionFilesSlice from './missionsFiles/missionsFilesSlice';
import onboardingResourcesSlice from './onboardingResources/onboardingResourcesSlice';
import onboardingKnowledgePagesSlice from './onboardingKnowledgePages/onboardingKnowledgePagesSlice';
import faqManageSlice from './faq-manage/faqSlice';
import staticPageSlice from './staticPage/staticPageSlice';
import badgesSlice from './badges/badgesSlice';
import setUpInterceptor from '../services/interceptors';
import fileTagsSlice from './file-tags/fileTagsSlice';

const reducers = combineReducers({
  currentPage: currentPageSlice,
  dictionaries: dictionariesSlice,
  user: userSlice,
  rounds: roundsSlice,
  roles: rolesSlice,
  settings: settingsSlice,
  files: filesSlice,
  usersList: usersListSlice,
  faqList: faqSlice,
  templateUser: templateUserSlice,
  onBoardingUserDetails: onboardingUserDetailsSlice,
  onBoardingTemplates: onboardingTemplatesSlice,
  knowledgePagesList: knowledgePagesSlice,
  userTasks: userTasksSlice,
  missionFiles: missionFilesSlice,
  onboardingTasks: onboardingTasks,
  onboardingResources: onboardingResourcesSlice,
  onboardingKnowledgePages: onboardingKnowledgePagesSlice,
  faqManage: faqManageSlice,
  staticPage: staticPageSlice,
  badgesUser: badgesSlice,
  fileTags: fileTagsSlice,
});

const resetRootReducer = (state: ReturnType<typeof reducers>, action: AnyAction) => {
  if (action.type === 'store/reset') {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: (state, action) => resetRootReducer(state, action),
    preloadedState,
  });
};

setUpInterceptor(setupStore);
const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
export default store;
