import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PlainCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2466 3.21042C11.393 3.35687 11.393 3.5943 11.2466 3.74075L5.1966 9.79075C5.12627 9.86108 5.03089 9.90059 4.93143 9.90059C4.83198 9.90059 4.73659 9.86108 4.66627 9.79075L0.753268 5.87775C0.606821 5.7313 0.606821 5.49387 0.753268 5.34742C0.899714 5.20097 1.13715 5.20097 1.2836 5.34742L4.93143 8.99526L10.7163 3.21042C10.8627 3.06397 11.1002 3.06397 11.2466 3.21042Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
