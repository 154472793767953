import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserSurvey: IView = {
  key: 'viewTasksUserSurvey',
  title: 'tasks.taskCreation.category.survey',
  roles: [IRoles.ADMINISTRATOR, IRoles.BUDDY, IRoles.HR, IRoles.SUPERIOR, IRoles.EMPLOYEE, IRoles.NEWEMPLOYEE],
  view: React.lazy(() => import('./survey-view')),
  path: '/zadania/ankieta/:id',
};

export default ViewUserSurvey;
