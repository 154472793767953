import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DownArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.0303 11.4697C21.3232 11.7626 21.3232 12.2374 21.0303 12.5303L13.0303 20.5303C12.8839 20.6768 12.6919 20.75 12.5 20.75C12.3983 20.75 12.3013 20.7298 12.2129 20.6931C12.1245 20.6565 12.0416 20.6022 11.9697 20.5303L3.96967 12.5303C3.67678 12.2374 3.67678 11.7626 3.96967 11.4697C4.26256 11.1768 4.73744 11.1768 5.03033 11.4697L11.75 18.1893L11.75 4C11.75 3.58579 12.0858 3.25 12.5 3.25C12.9142 3.25 13.25 3.58579 13.25 4L13.25 18.1893L19.9697 11.4697C20.2626 11.1768 20.7374 11.1768 21.0303 11.4697Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
