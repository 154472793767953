import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './onboardingKnowledgePagesInitial';
import { getKnowledgePagesInGroupAPI, getKnowledgePagesTagsAPI } from './onboardingKnowledgePagesAPI';
import { IKnowledgePage, ITags } from 'interfaces/knowledge-page.interface';
import { IResponseWithPagination } from 'interfaces/common';

export interface onboardingKnowledgePagesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: {
    onboardingKnowledgePages: IResponseWithPagination<IKnowledgePage[]>;
    tags: ITags[];
  };
  currentRequestId?: string;
}
interface IfetchData {
  roundId: string;
  params?: object;
}
export const fetchOnboardingKnowledgePagesList = createAsyncThunk(
  'onboardingKnowledgePages/fetch',
  async (data: IfetchData) => {
    try {
      const pagesResponse = await getKnowledgePagesInGroupAPI(data.roundId, data.params);
      const tagsResponse = await getKnowledgePagesTagsAPI();
      return {
        onboardingKnowledgePages: pagesResponse.data.value,
        tags: tagsResponse.data.value,
      };
    } catch (err) {
      return err;
    }
  }
);

export const onboardingKnowledgePagesSlice = createSlice({
  name: 'onboardingKnowledgePages',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.data.onboardingKnowledgePages.page = action.payload + 1;
    },
    prevPage: (state, action) => {
      state.data.onboardingKnowledgePages.page = action.payload - 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingKnowledgePagesList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchOnboardingKnowledgePagesList.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingKnowledgePagesList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectOnboardingKnowledgePagesList = (state: RootState) => state.onboardingKnowledgePages;
export const selectTagList = (state: RootState) => state.onboardingKnowledgePages.data.tags;

export default onboardingKnowledgePagesSlice.reducer;
