import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { IUserDetails, IUserOnboardingData, UserAssignItem } from 'interfaces/user.interface';
import { RootState } from 'store';
import { initialState } from './usersListInitial';
import { postUserAPI, getOnboardingUsersListAPI } from './usersListAPI';
import { IResponseWithPagination } from 'interfaces/common';

export interface usersListState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  sort: string;
  data: IResponseWithPagination<IUserOnboardingData[]>;
  currentRequestId?: string;
}

export const fetchUsersList = createAsyncThunk('users/fetchUsersList', async (params?: object) => {
  try {
    const response = await getOnboardingUsersListAPI(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});
export const createUser = createAsyncThunk('users/postUser', async (params?: object) => {
  try {
    const response = await postUserAPI(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const usersListSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.data.page = action.payload + 1;
    },
    newPageSize: (state, action) => {
      state.data.size = action.payload;
    },
    onSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectUsersList = (state: RootState) => state.usersList;

export default usersListSlice.reducer;
