import { SvgIcon, SvgIconProps } from '@mui/material';

export const RedFailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.0009 91.2342C70.9826 91.2342 91.2342 70.9826 91.2342 46.0009C91.2342 21.0192 70.9826 0.767578 46.0009 0.767578C21.0192 0.767578 0.767578 21.0192 0.767578 46.0009C0.767578 70.9826 21.0192 91.2342 46.0009 91.2342Z"
        fill="#FF0E4F"
      />
      <path
        d="M43.8306 45.9988L25.748 64.0814L27.9165 66.2498L45.9991 48.1673L64.0816 66.2498L66.2501 64.0814L48.1675 45.9988L66.2498 27.9165L64.0814 25.748L45.9991 43.8304L27.9167 25.748L25.7483 27.9165L43.8306 45.9988Z"
        fill="white"
      />
    </SvgIcon>
  );
};
