import React from 'react';
import { Stack, Typography, Theme } from '@mui/material';
import { ITitleWithIconProps } from 'interfaces/common';

export const TitleWithIcon = ({ title, icon }: ITitleWithIconProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" height={'24px'}>
      {icon}
      <Typography variant="subtitle1" sx={(theme: Theme) => ({ color: theme.palette.grey[800] })}>
        {title}
      </Typography>
    </Stack>
  );
};
