import { DocumentStatus } from 'components/cards/document-card/document-card';
import { ITag, MeetingType, PriorityType, TaskStatusType, TaskType, TaskUserType } from './tasks.interface';
import { IAcceptorInfo, IAssignee, IBaseUserInfo } from './user.interface';

export enum CompletionStatus {
  ACTIVE = 'Active',
  APPROVE = 'Approve',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
}
export enum MissionCategory {
  MISSION = 'mission',
  MEETING = 'meeting',
  FILE = 'file',
  CONFIRM = 'confirm',
}

export interface IUserMission {
  completionFile: null;
  completionStatus: CompletionStatus;
  endDescription: null;
  id: string;
  rejectReason?: string;
  roundId: string;
  tokens: number;
  mission: IMission;
  attempts: number;
  workerGroupId?: string;
  to: string;
  userId: string;
  history?: object;
  meetingUserId?: string;
  updatedDate?: string;
  creatorId?: string;
  meetingType: MeetingType;
  currentAttempt: number;
  comments?: string;
  acceptorInfo?: IAcceptorInfo;
}
export interface IUserMissionApprove {
  id: string;
  name?: string;
  description?: string;
  completionDate?: string;
  endDescription?: string;
  declarantId?: string;
  hasFile: boolean;
  tokens: number;
  canEditPoints: boolean;
  workerGroupId?: string;
  content?: string;
  updatedDate?: string;
  creatorId?: string;
  to?: string;
  completionStatus: CompletionStatus;
  verifier: IAssignee;
  comments?: string;
  rejectReason?: string;
  showVerifierComments: boolean;
  showVerifierRejectReason: boolean;
}
export interface IMission {
  attempts: number;
  canEditPoints: boolean;
  daysToFinish?: number;
  description: string;
  id: string;
  isExternal: boolean;
  missionApprovingUsers: [];
  missionFiles: IMissionFiles[];
  name: string;
  roundIds: string[];
  tagsMission: ITag[];
  tokens: number;
  type: TaskUserType;
  content: string;
  creatorId?: string;
  category: MissionCategory;
  updatedDate?: string;
  meetingType: MeetingType;
  priorityType: PriorityType;
  positionId?: string;
  isAcceptorForApprove: boolean;
  predefinedMeetingUserId?: string;
  predefinedMeetingUserName?: string;
  predefinedMeetingUserLastName?: string;
  predefinedMeetingUserAvatarId?: string;
  predefinedMeetingUserJobTitle?: string;
}
export interface IMissionFiles {
  fileId: string;
  missionId: string;
  orginalLink: string;
  userLink: null | string;
  orginalFileName: string;
  contentType: string;
  fileNameFormated: null | string;
  fileStatus: DocumentStatus;
  size: number;
  templateDescription: string;
  templateName: string;
  templateStorageFileName: string;
  uploadedFileName?: string;
}
export interface ISingleMission extends IMission {
  description: string;
  id: string;
  isExternal: boolean;
  name: string;
  tokens: number;
  type: TaskUserType;
  priorityType: PriorityType;
}
export interface ISingleUserMission {
  id: string;
  attempts: number;
  endDescription?: string;
  completionFile: string;
  completionStatus: CompletionStatus;
  roundId: string;
  mission: ISingleMission;
  rejectReason?: string;
  tokens: number;
  workerGroupId?: string;
  currentAttempt: number;
  meetingUserId?: string;
  meetingType: MeetingType;
  updatedDate?: string;
  creatorId?: string;
  userId: string;
  to?: string;
  comments?: string;
  verifierInfo: IAssignee;
}

export interface ICreateTasksData {
  name: string;
  description: string;
  content: string;
  type: TaskUserType;
  attempts: number;
  tokens: number;
  canEditPoints: boolean;
  isExternal: boolean;
  missionApprovingUsers?: string[];
  tagids?: string[];
  daysToFinish: number;
  category: MissionCategory | TaskType;
  meetingType: MeetingType;
  priorityType: PriorityType;
  positionId?: string;
  isAcceptorForApprove?: boolean;
  fileIds?: string[];
  templateIds?: string[];
  predefinedMeetingUserId?: string;
}
export interface IEditedMission extends ICreateTasksData {
  missionFiles?: IMissionFiles[];
}
export interface IAcceptMissionData {
  accepted: boolean;
  rejectReason?: string;
  comments?: string;
  points?: number;
}

export interface IAssignItem {
  userId?: string;
  roundId: string;
  groupId?: string;
  meetingUserId?: string;
}

export interface IAssignItemMissions {
  email: string;
  id: string;
  roundId: string;
  userId: string;
  workerGroupId: null | string;
}
export interface IAssignUsersToMission {
  id?: string;
  assignments: IAssignItem[];
}

export interface IMissionPreview {
  name: string;
  tokens: number;
  content: string;
  daysToFinish: number;
  priorityType: PriorityType;
  tags: ITag[];
}

export interface IMissionAssignments {
  id: string;
  userInfo: IBaseUserInfo;
  meetingUserWith: IBaseUserInfo;
  meetingUsersForApprove: IBaseUserInfo;
  roundId: string;
  workerGroupId: string;
  category: MissionCategory;
  meetingType: MeetingType;
}
