import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DocumentHatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2785 4.30364C12.0997 4.23212 11.9003 4.23212 11.7215 4.30364L1.72146 8.30364C1.43671 8.41754 1.25 8.69332 1.25 9C1.25 9.30668 1.43671 9.58246 1.72146 9.69636L5.25 11.1078L5.25 15.9996C5.25 17.2232 6.20379 18.1827 7.42195 18.7917C8.67519 19.4184 10.3223 19.7496 12 19.7496C13.6777 19.7496 15.3248 19.4184 16.5781 18.7917C17.7962 18.1827 18.75 17.2232 18.75 15.9996L18.75 11.1078L21.25 10.1078V15C21.25 15.4142 21.5858 15.75 22 15.75C22.4142 15.75 22.75 15.4142 22.75 15V9C22.75 8.69332 22.5633 8.41754 22.2785 8.30364L12.2785 4.30364ZM17.7487 9.89275C17.7304 9.89924 17.7125 9.90642 17.6949 9.91426L12 12.1922L6.30505 9.91425C6.2875 9.90642 6.26958 9.89925 6.25134 9.89276L4.01944 9L12 5.80777L19.9806 9L17.7487 9.89275ZM6.75 11.7078L11.7215 13.6964C11.9003 13.7679 12.0997 13.7679 12.2785 13.6964L17.25 11.7078L17.25 15.9996C17.25 16.3673 16.9395 16.934 15.9072 17.4501C14.91 17.9487 13.5049 18.2496 12 18.2496C10.4951 18.2496 9.08996 17.9487 8.09277 17.4501C7.06049 16.934 6.75 16.3673 6.75 15.9996L6.75 11.7078Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
