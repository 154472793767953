import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HelpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.75C7.94365 3.75 4.25 7.44365 4.25 12C4.25 16.5563 7.94365 20.25 12.5 20.25C17.0563 20.25 20.75 16.5563 20.75 12C20.75 7.44365 17.0563 3.75 12.5 3.75ZM2.75 12C2.75 6.61522 7.11522 2.25 12.5 2.25C17.8848 2.25 22.25 6.61522 22.25 12C22.25 17.3848 17.8848 21.75 12.5 21.75C7.11522 21.75 2.75 17.3848 2.75 12ZM12.5 7.75C11.3917 7.75 10.4674 8.47508 10.2241 9.3771C10.1163 9.77702 9.70465 10.0138 9.30472 9.90595C8.90479 9.7981 8.66802 9.38647 8.77587 8.98654C9.20934 7.37913 10.7678 6.25 12.5 6.25C14.5801 6.25 16.25 7.87091 16.25 9.90909C16.25 11.3648 15.1776 12.0945 14.4756 12.5722C14.4577 12.5844 14.4399 12.5964 14.4225 12.6083C13.5988 13.1699 13.25 13.4581 13.25 14C13.25 14.4142 12.9142 14.75 12.5 14.75C12.0858 14.75 11.75 14.4142 11.75 14C11.75 12.6066 12.8013 11.8947 13.4848 11.4319C13.5165 11.4104 13.5475 11.3894 13.5775 11.369C14.3779 10.8232 14.75 10.5071 14.75 9.90909C14.75 8.73398 13.7867 7.75 12.5 7.75ZM12.5 16.24C12.9142 16.24 13.25 16.5758 13.25 16.99V17C13.25 17.4142 12.9142 17.75 12.5 17.75C12.0858 17.75 11.75 17.4142 11.75 17V16.99C11.75 16.5758 12.0858 16.24 12.5 16.24Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
