import React, { useRef, useState } from 'react';
import { Box, Grid, Typography, Stack } from '@mui/material';
import { TaskCard } from 'components/cards';
import Slider, { Settings } from 'react-slick';
import { ITask } from 'interfaces/tasks.interface';
import { DownArrow } from 'assets/icons/DownArrow';
import { useTranslation } from 'react-i18next';
import { ButtonSize, ButtonType } from 'interfaces/button.interface';
import { CustomButton } from '../custom-button';
import { useWindowSize } from 'hooks/useWindowSize';

interface IProps {
  tasks: ITask[] | null | undefined;
  slideToShow?: number;
}

export const TaskSlider: React.FC<IProps> = ({ tasks, slideToShow }) => {
  const slider = useRef<Slider>(null);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [showAll, setShowAll] = useState(false);
  const onShowAll = () => {
    setShowAll(!showAll);
  };
  const numberSliderShow = () =>
    width && width > 1400 ? 5 : width && width > 1100 ? 4 : width && width > 800 ? 3 : width && width > 520 ? 2 : 1;
  const settingsTaskSlider: Settings = {
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    prevArrow: (
      <DownArrow
        sx={(theme) => ({
          transform: 'rotate(90deg)',
          right: 140,
          bottom: -53,
          width: 17,
          height: 17,
          top: 'auto',
          left: 'auto',
          '& path': {
            fill: theme.palette.common.black,
          },
        })}
      />
    ),
    nextArrow: (
      <DownArrow
        sx={(theme) => ({
          transform: 'rotate(-90deg)',
          right: 85,
          bottom: -53,
          width: 17,
          height: 17,
          top: 'auto',
          left: 'auto',
          '& path': {
            fill: theme.palette.common.black,
          },
        })}
      />
    ),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{
        '& .slick-slider': {
          margin: '0 32px',
        },
        '& .slick-slider .slick-list': {
          width: '100%',
        },
        '& .slick-slider .slick-track': {
          padding: '20px, 0',
        },
      }}
    >
      {tasks && tasks.length !== 0 ? (
        !showAll && tasks.length > numberSliderShow() ? (
          <Slider ref={slider} {...settingsTaskSlider}>
            {tasks.map((task) => (
              <TaskCard task={task} key={task.id} />
            ))}
          </Slider>
        ) : (
          <Box display={'flex'} justifyContent={'center'}>
            <Grid maxWidth={'xl'} container columns={15} justifyContent={'center'}>
              {tasks.map((task) => (
                <Grid key={task.id} item sx={(theme) => ({ mb: theme.spacing(5), mx: theme.spacing(2) })}>
                  <TaskCard task={task} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )
      ) : (
        <Typography variant="h2">{t('tasks.noTasks')}</Typography>
      )}
      {tasks && tasks.length > 5 && (
        <CustomButton
          text={t(showAll ? 'button.hideall' : 'button.showall')}
          buttonType={ButtonType.SECONDARY}
          onClick={onShowAll}
          sx={{ fontSize: '14px', m: '30px auto', width: '200px', display: 'flex' }}
          size={ButtonSize.SMALL}
        />
      )}
    </Box>
  );
};
