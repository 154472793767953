import appService from 'services/common';

const getOnboardingUsersListAPI = (params?: object) => {
  return appService.get('/onboarding-user-details-manage', { params });
};
const getUsersListAPI = (params?: object) => {
  return appService.get('/users-manage', { params });
};
const postUserAPI = (params?: object) => {
  return appService.post('/users-manage/add', { ...params });
};
const putUserAPI = (params?: any) => {
  return appService.put('/users-manage/', { ...params });
};

export { getUsersListAPI, postUserAPI, putUserAPI, getOnboardingUsersListAPI };
