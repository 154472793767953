import { IPagination } from 'interfaces/common';
import { IAnswersReview, IQuiz, IQuizAnswer, IQuizAssign, IQuizPlain, Quiz } from 'interfaces/quiz.interface';
import appService from './common';

export const getQuizzes = async (params?: IPagination) => {
  return await appService.get('/quizes-manage', {
    params,
  });
};
export const getQuizzById = async (quizId: string) => {
  return await appService.get(`/quizes-manage/${quizId}`);
};

export const addQuiz = async (data: IQuizPlain) => {
  return await appService.post('/quizes-manage', {
    ...data,
  });
};

export const deleteQuiz = async (id: string) => {
  return await appService.delete(`/quizes-manage/${id}`);
};

export const assignUserToQuizzById = async (quizId: string, data: IQuizAssign) => {
  return appService.post(`/quizes-manage/${quizId}/user-assign`, {
    ...data,
  });
};
export const editQuizzByID = async (quizId: string, data: Quiz) => {
  return await appService.put(`/quizes-manage/${quizId}`, {
    ...data,
  });
};

export const getUserSolvedQuizById = async (quizId: string, userId: string) => {
  return await appService.get(`/quizes-manage/${quizId}/user/${userId}`);
};

export const reviewUserAnswers = async (data: IAnswersReview) => {
  return await appService.post('/quizes-manage/user-answers/review', {
    ...data,
  });
};

export const getUsersAssignToQuiz = async (quizId: string) => {
  return await appService.get('/quiz-manage/' + quizId + '/users');
};

export const getUserQuizById = async (objectId: string) => {
  return await appService.get(`/quizes/${objectId}`);
};
export const solveQuiz = async (quizId: string, data: IQuizAnswer) => {
  return await appService.post(`/quizes/${quizId}/user-answers`, {
    ...data,
  });
};

export const unassignQuizFromUser = async (quizId: string) => {
  return await appService.delete('/quizes-manage/individual-assign', {
    data: {
      assignmentId: quizId,
    },
  });
};
