import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './missionsFilesInitial';
import { getMissionFiles, postFileToMission } from './missionsFilesAPI';
import { IMissionFiles, IUserMission } from 'interfaces/missions.interface';

export interface missionFilesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IUserMission[];
  currentRequestId?: string;
}

export const fetchMissionFiles = createAsyncThunk('missionFiles/fetch', async () => {
  const pagesResponse = await getMissionFiles();
  return pagesResponse;
});

export const postAnwersFileToMission = createAsyncThunk(
  'missionFiles/post',
  async (
    {
      missionFile,
      mission,
      file,
    }: {
      missionFile: Pick<IMissionFiles, 'fileId'>;
      mission: Pick<IUserMission, 'id'>;
      file: FormData;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await postFileToMission(missionFile, mission, file);
      return response.data.value;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const missionFilesSlice = createSlice({
  name: 'knowledgePages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMissionFiles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchMissionFiles.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload.data.value;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchMissionFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postAnwersFileToMission.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postAnwersFileToMission.fulfilled, (state, action: any) => {
        const { requestId, arg } = action.meta;
        const missionList = [...state.data];
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = missionList.map((missionUser) => {
            if (missionUser.mission.missionFiles[0].fileId === arg.missionFile.fileId) {
              return {
                ...missionUser,
                mission: {
                  ...missionUser.mission,
                  missionFiles: [
                    {
                      ...missionUser.mission.missionFiles[0],
                      fileStatus: action.payload.fileStatus,
                      userLink: action.payload.userLink,
                    },
                  ],
                },
              };
            }
            return missionUser;
          });
          state.currentRequestId = undefined;
        }
      })
      .addCase(postAnwersFileToMission.rejected, (state, action) => {
        const { requestId, arg } = action.meta;
        const missionList = [...state.data];
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = missionList.map((missionUser) => {
            // if (missionUser.mission.missionFiles[0].fileId === arg.mission.fileId) {
            //   return {
            //     ...missionUser,
            //     fileStatus: DocumentStatus.FAILED,
            //   };
            // }
            return missionUser;
          });
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectMissionsFiles = (state: RootState) => state.missionFiles;

export default missionFilesSlice.reducer;
