import { faqListState } from './faqSlice';

export const initialState: faqListState = {
  loading: 'idle',
  data: {
    faq: {
      records: [],
      recordsCount: 0,
      page: 1,
      size: 10,
    },
    faqTags: [],
  },
};
