import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ITemplateRoot, ITemplateSection } from 'interfaces/onboarding-templates.interface';
import { RootState } from 'store';
import { fetchOUserTemplatesManage, fetchUserTemplate, postUserTemplateManage } from './onboardingUserTemplatesAPI';
import { initialState } from './onboardingUserTemplatesInitial';

export interface TemplateRootState {
  error?: SerializedError;
  template: ITemplateRoot;
  templateName: string;
  templateId: string;
  formTemplate?: any;
  loading: 'idle' | 'pending';
  currentRequestId?: string;
  isEdit?: boolean;
}

interface TemplatePayload {
  templateRoot: TemplateRootState;
  idUser: string;
}

export const postOnboardingUserTemplatesManage = createAsyncThunk(
  'userTemplatesManage/post',
  async (payload: TemplatePayload, { rejectWithValue }) => {
    try {
      const response = await postUserTemplateManage({
        id: payload.templateRoot.templateId,
        idTemplate: payload.templateRoot.templateId,
        idTemplateUser: payload.idUser,
        name: 'userTemplate',
        value: JSON.stringify(payload.templateRoot.template.sections),
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
      return err;
    }
  }
);

export const fetchOnboardingUserTemplatesManage = createAsyncThunk(
  'userTemplatesManage/fetch',
  async (params?: any) => {
    try {
      const response = await fetchOUserTemplatesManage(params);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const fetchOnboardingUserTemplates = createAsyncThunk(
  'userTemplates/fetch',
  async (params: any | undefined, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      if (state.user.details) {
        const response = await fetchUserTemplate(params);
        if (response.data.value) {
          const currentTemplate = response.data.value.records[response.data.value.records.length - 1];
          if (currentTemplate) {
            return {
              id: currentTemplate.idTemplate,
              value: JSON.parse(currentTemplate.value.replaceAll("'", '"') as string),
            };
          } else {
            return {
              id: '',
              value: state.templateUser.template,
            };
          }
        }
      } else {
        return new Error('No user details');
      }
    } catch (err) {
      rejectWithValue(err);
      return err;
    }
  }
);

export const { actions, reducer } = createSlice({
  name: 'templateUser',
  initialState,
  reducers: {
    fillTemplate(
      state,
      action: PayloadAction<{
        id: string;
        templateName?: string;
        template: ITemplateSection[];
        formData?: any;
        isEdit?: boolean;
      }>
    ) {
      state.templateId = action.payload.id;
      state.template.label = 'filled';
      state.template.sections = action.payload.template;
      if (action.payload.formData) {
        state.formTemplate = action.payload.formData;
      }
      if (action.payload.templateName) {
        state.templateName = action.payload.templateName;
      }
      if (action.payload.isEdit) {
        state.isEdit = action.payload.isEdit;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(postOnboardingUserTemplatesManage.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(postOnboardingUserTemplatesManage.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postOnboardingUserTemplatesManage.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchOnboardingUserTemplates.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          if (action.payload?.response?.status) {
            if (action.payload?.response?.status === 400) {
              state.templateId = '';
              state.template.sections = [];
            }
          } else {
            state.templateId = action.payload.id;
            state.template.sections = action.payload.value;
          }
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserTemplates.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingUserTemplates.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      });
  },
});

export const selectUserTemplates = (state: RootState) => state.templateUser;
export const { fillTemplate, reset } = actions;
export default reducer;
