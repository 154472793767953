import { ReactElement } from 'react';
import { JsxElement } from 'typescript';
import { IDefaultInteface } from './common';

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NOTSET = 'NotSet',
}
export enum StatusProgress {
  ONBOARDING = 'Onboarding',
  PREONBOARDING = 'PreOnboarding',
  BOARDED = 'Boarded',
  ONLY_ONBOARDING_AND_BOARDED = 'OnlyOnboardingAndBoarded',
}
export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ARCHIVED = 'Archived',
}
export interface IUserDetails extends IDefaultInteface {
  email?: string;
  surname: string;
  avatar: string;
  round?: IDefaultInteface;
  created?: string;
  claims?: string[];
  username?: string;
  language?: string;
  hidePersonalData?: boolean;
  gender?: Gender;
  roles?: IDefaultInteface[];
  workerGroups?: IDefaultInteface[];
  isSubmitUserForm?: boolean;
  hideInRanking?: boolean;
}

export interface IBaseUserInfo {
  userId: string;
  userName: string;
  userSurname: string;
  userEmail: string;
}

export interface IAssignee {
  firstName: string;
  lastName: string;
  avatarId: string;
  positionId: string;
}
export interface IAcceptorInfo {
  acceptorFirstName: string;
  acceptorLastName: string;
  acceptorAvatarId: string;
  acceptorPosition: string;
}

export interface IUserOnboardingDetails extends IUserDetails {
  extraDetails: IUserOnboardingExtraDetails;
  status?: string;
  employmentStartDate?: string;
  onboardingEndDate?: string;
}

export interface IUserOnboardingExtraDetails {
  [keyDetails: string]: any;
  progress?: IUserProgress;
}

export interface ContactItem {
  label: string;
  text: string;
  dictionaryInfo?: {
    dictionaryName: string;
    dictionaryValue: string;
  };
  element?: JsxElement | string | Element;
}
export interface IInfoCardProps {
  title?: string;
  icon?: ReactElement;
  contactItems: ContactItem[];
}

export interface UserAssignItem {
  userId: string;
  linkedUserId: string;
  description: string;
  userUserName: string;
  userFirstName: string;
  userLastName: string;
  userAvatar: string;
  linkedUserUserName: string;
  linkedUserFirstName: string;
  linkedUserLastName: string;
  linkedUserAvatar: string;
  linkedUserIsSuperior: boolean;
  linkedUserIsBuddy: boolean;
  linkedUserJobTitle: string;
  linkedUserPhoneNumber: string;
  progress?: IUserProgress;
}
export interface IUserOnboardingData {
  buddyAvatar: string;
  buddyFirstName: string;
  buddyId: string;
  buddyLastName: string;
  buddyUserName: string;
  content: any;
  employmentStartDate: string;
  onboardingEndDate: string;
  onboardingWorkerGroupId: string;
  preonboardingWorkerGroupId: string;
  status: StatusProgress;
  superiorAvatar: string;
  superiorFirstName: string;
  superiorId: string;
  superiorLastName: string;
  superiorUserName: string;
  userAvatar: string;
  userFirstName: string;
  userId: string;
  roles: IUserOnboardingRoles[];
  userLastName: string;
  userUserName: string;
  isBlocked?: boolean;
  progress?: IUserProgress;
  created: string;
  lastLogin: string;
}
export interface IUserOnboardingAdditionalData {
  phoneNumber: string;
  email: string;
  contractType: string;
  onboardingEndDate: string;
  employmentStartDate: string;
}
export interface IOnboardingUserListData {
  userId: string;
  fullName: string;
  avatar: string;
  superiorFullName: string;
  buddyFullName: string;
  isBlocked: boolean;
  progress: IUserProgress;
  office: string;
  position: string;
  status: StatusProgress;
}

export interface IUserOnboardingRoles {
  roleName: string;
  roleId: string;
  userId: string;
}
export interface IUserProgress {
  daysLeftPreonboarding: number;
  daysLeftOnboarding: number;
  percentOfTasksCompletedPreonboarding: number;
  percentOfTasksCompletedOnboarding: number;
  pointsPreonboarding: number;
  pointsOnboarding: number;
}

export interface IUserReviewData {
  userId?: string;
  comment?: string;
  badgeIds?: string[];
}
export interface IArchivedUser extends IDefaultInteface {
  surname: string;
  email: string;
  status: UserStatus;
}
export interface IChangeUserEmail {
  newEmail: string;
}
export interface IChangeUserRoles {
  roleIds: string[];
}

export interface IMainUserData {
  firstName: string;
  lastName: string;
}
