import React from 'react';
import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { centerFlex } from 'common-styles/common-styles';

interface IPdfViewProps {
  sx?: SxProps<Theme>;
  text?: string;
  background?: string;
  link: string;
}
export const PdfView = ({ text, link, background, sx = [] }: IPdfViewProps) => {
  return (
    <Box
      component={'div'}
      sx={[
        (theme: Theme) => ({
          mb: theme.spacing(2),
          ...centerFlex,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Link
        sx={(theme) => ({
          ...centerFlex,
          flexDirection: 'column',
          textDecoration: 'none',
          width: '600px',
          position: 'relative',
          minHeight: '340px',
          background: theme.palette.common.white,
          backgroundSize: '100% auto',
          '&:before': {
            content: "''",
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.4,
            background: background ? `url(${background}) no-repeat` : 'none',
            backgroundSize: 'cover',
          },
        })}
        target={'_blank'}
        href={link}
      >
        <Box sx={{ position: 'relative' }}>
          {text && (
            <Typography variant={'h5'} sx={(theme) => ({ color: theme.palette.secondary.dark, textAlign: 'center' })}>
              {text}
            </Typography>
          )}
          <Typography
            sx={(theme) => ({
              background: theme.palette.secondary.main,
              color: theme.palette.common.white,
              padding: theme.spacing(0, 3),
              lineHeight: '42px',
              width: '107px',
              margin: '0 auto',
              borderRadius: '21px',
              display: 'block',
            })}
          >
            Zobacz
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};
