import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const EmailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 4.99935C1.875 4.19393 2.52792 3.54102 3.33333 3.54102H16.6667C17.4721 3.54102 18.125 4.19393 18.125 4.99935V14.9994C18.125 15.8048 17.4721 16.4577 16.6667 16.4577H3.33333C2.52792 16.4577 1.875 15.8048 1.875 14.9994V4.99935ZM4.00888 4.79102L9.26343 10.0456C9.67023 10.4524 10.3298 10.4524 10.7366 10.0456L15.9911 4.79102H4.00888ZM16.875 5.6749L11.6205 10.9294C10.7255 11.8244 9.2745 11.8244 8.37955 10.9294L3.125 5.6749V14.9994C3.125 15.1144 3.21827 15.2077 3.33333 15.2077H16.6667C16.7817 15.2077 16.875 15.1144 16.875 14.9994V5.6749Z"
        fill="white"
      />
    </SvgIcon>
  );
};
