import React from 'react';
import { Card, CardContent, Chip, Divider, Typography, Box, IconButton, Theme } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { GUAvatar } from 'components/common/gu-avatar';
import { useTranslation } from 'react-i18next';

interface IProps {
  name?: string;
  surname?: string;
  position?: string;
  avatarId?: string;
  isBuddy?: boolean;
  phoneNumber?: string;
  email?: string;
}

export const UserContactCard: React.FC<IProps> = ({
  name = false,
  surname = false,
  position = false,
  isBuddy = false,
  avatarId,
  email,
  phoneNumber,
}) => {
  const buttonStyles = (theme: Theme) => ({
    bgcolor: theme.palette.primary.main,
    width: 36,
    height: 36,
    '&:hover': {
      bgcolor: theme.palette.primary.main,
    }
  });
  const { t } = useTranslation();
  return (
    <Card
      sx={(theme: Theme) => ({
        p: theme.spacing(5),
        width: 320,
        height: 342,
        ...(isBuddy
          ? {
              height: 382,
              position: 'relative',
              top: '-20px',
            }
          : {}),
        bgcolor: theme.palette.grey[100],
        boxShadow: 0,
      })}
    >
      <CardContent
        sx={{
          position: 'relative',
          p: 0,
        }}
      >
        {isBuddy && (
          <Chip
            label={t('infoCard.yourBuddy')}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bgcolor: 'white',
              fontSize: 12,
            }}
          />
        )}
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          {name ?? ''}
        </Typography>
        <Typography
          variant="h5"
          sx={(theme: Theme) => ({
            mb: theme.spacing(3),
            fontWeight: 400,
          })}
        >
          {surname ?? ''}
        </Typography>
        <Divider />
        <Typography
          variant="subtitle2"
          sx={(theme: Theme) => ({
            my: theme.spacing(3),
          })}
        >
          {position ?? ''}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <GUAvatar avatarId={avatarId} sx={{ width: 120, height: 120 }} />
          {email && (
            <IconButton aria-label="send email" sx={[buttonStyles, { mr: '16px' }]} href={`mailto:${email}`}>
              <EmailOutlinedIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          {phoneNumber && (
            <IconButton title={`Call to user ${phoneNumber}`} href={`tel:${phoneNumber}`} sx={buttonStyles}>
              <PhoneOutlinedIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
