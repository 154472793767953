import { ICreateTasksData, IAcceptMissionData, IAssignUsersToMission } from 'interfaces/missions.interface';
import appService from './common';

export const getSingleMissionById = async (id: string) => {
  return await appService.get(`/users/missions/assignments/${id}`);
};
export const getMissionsByUserId = async (userId: string) => {
  try {
    const response = await appService.get(`/users-manage/${userId}/missions`);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const getMissionToApproveById = async (missionId: string) => {
  return await appService.get(`/missions-manage/assignments/${missionId}/approve`);
};

export const completeMissionById = async (id: string) => {
  try {
    await appService.post(`/missions/assignments/${id}/complete`);
  } catch (err) {
    return err;
  }
};

export const addMission = async (data: ICreateTasksData) => {
  return await appService.post('/missions-manage/add', {
    ...data,
  });
};
export const addMissionWithFiles = async (data: ICreateTasksData, params: string) => {
  return await appService.post('/missions-manage/add/files/ids' + params, {
    ...data,
  });
};
export const acceptMission = async (id: string, data: IAcceptMissionData) => {
  return appService.post(`/missions-manage/assignments/${id}/accept`, {
    ...data,
  });
};
export const getUsersAssignToMission = async (taskId: string) => {
  try {
    return appService.get(`/missions-manage/${taskId}/users`);
  } catch (error) {
    return error;
  }
};

export const assignUssersToMission = async (taskId: string, data: IAssignUsersToMission) => {
  try {
    return appService.post(`/missions-manage/${taskId}/users`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
};
export const unassignUsersFromMission = async (data: { id: string }[]) => {
  try {
    return appService.delete(`/missions-manage/assignments`, {
      data: {
        assignments: data,
      },
    });
  } catch (error) {
    return error;
  }
};

export const deleteTaskById = async (taskId: string) => {
  try {
    return appService.delete(`/missions-manage/${taskId}`);
  } catch (error) {
    return error;
  }
};

export const getMissionByIds = async (id: string) => {
  try {
    const response = await appService.get('/missions-manage/ids', {
      params: {
        MissionIds: id,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const editMissionsId = async (id: string, data: ICreateTasksData) => {
  return await appService.put(`/missions-manage/${id}`, {
    ...data,
  });
};
export const editMissionsFilesId = async (id: string, data: ICreateTasksData, params: string) => {
  return await appService.put(`/missions-manage/${id}/file/ids` + params, {
    ...data,
  });
};
