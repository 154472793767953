export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_ACCENT = 'secondaryAccent',
  THIRDARY = 'thirdary',
  SUCCESS = 'success',
  WHITE = 'white',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum IconName {
  ARROW_DOWN = 'arrowDown',
  ARROW_UP = 'arrowUp',
  ARROW_LEFT = 'arrowLeft',
  ARROW_RIGHT = 'arrowRight',
  CHECK = 'check',
  ADD = 'add',
  ADD_PLAIN = 'addPlain',
  NOTIFICATION = 'notification',
  NOTIFICATION_WHITE = 'notificationWhite',
  FILTER = 'filter',
  EDIT = 'edit',
  DOWNLOAD = 'download',
  VIEW = 'view',
  ALERT = 'alert',
  AGREE_CIRCLE = 'agree-circle',
}
