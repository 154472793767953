import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const UsersPage: IView = {
  key: 'viewTemplateList',
  title: 'preonboardingTemplateUser.templateView.title',
  navigation: true,
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./list-templates')),
  path: '/szablony/',
};

export default UsersPage;
