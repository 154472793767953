import appService from 'services/common';

export const getMissionEmployee = async (userId: string) => {
  try {
    const response = await appService.get('mission/employee', {
      params: {
        UserId: userId,
        RoundId: 'ca94ac5d-3566-cd7e-380f-91050b01449e',
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMissionEmployees = async (userIds: string[]) => {
  try {
    const response = await appService.get('mission/employees', {
      params: {
        UserIds: userIds,
        RoundId: 'ca94ac5d-3566-cd7e-380f-91050b01449e'
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMissionQuest = async (missionId: string, userId: string) => {
  try {
    const response = await appService.get('mission/quest', {
      params: {
        MissionId: missionId,
        UserId: userId,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMissionQuiz = async (quizId: string, userId: string) => {
  try {
    const response = await appService.get('mission/quiz', {
      params: {
        QuizId: quizId,
        UserId: userId,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMissionSurvey = async (surveyId: string, userId: string) => {
  try {
    const response = await appService.get('mission/survey', {
      params: {
        SurveyId: surveyId,
        UserId: userId,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSurveyTotal = async (surveyId: string) => {
  try {
    const response = await appService.get('survey/total', {
      params: {
        SurveyId: surveyId,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTotal = async (userIds: string[]) => {
  try {
    const response = await appService.get('total', {
      params: {
        RoundId: 'ca94ac5d-3566-cd7e-380f-91050b01449e',
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

function enqueueSnackbar(message: any, arg1: { variant: string; }) {
  throw new Error('Function not implemented.');
}
