import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';
import SchemaAddView from './views/add';

const SchemaManage: IView = {
  key: 'viewSchemaManage',
  title: 'schemaView.title',
  roles: [IRoles.ADMINISTRATOR],
  view: React.lazy(() => import('./details-schema')),
  path: '/zarzadzanie-polami',
  navigation: true,
  childrens: [SchemaAddView],
};

export default SchemaManage;
