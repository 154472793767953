import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewAssignsHome: IView = {
  key: 'viewAssignsHome',
  title: 'assignsHome.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.BUDDY, IRoles.SUPERIOR, IRoles.EMPLOYEE, IRoles.NEWEMPLOYEE],
  view: React.lazy(() => import('./home-assigns')),
  path: '/',
};

export default ViewAssignsHome;
