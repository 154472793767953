import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingEditTaskTemplateView: IView = {
  key: 'viewOnboardingTaskTemplateUser',
  title: 'preonboardingTaskTemplateUser.templateView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR, IRoles.SUPERIOR],
  view: React.lazy(() => import('./edit-tasks')),
  path: ['/szablony/zadania/:templateId', '/szablony/zadania/'],
};

export default OnboardingEditTaskTemplateView;
