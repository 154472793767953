import { Box, Chip, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import React from 'react';
import { selectTagList } from 'store/knowledgePages/knowledgePagesSlice';
import { selectNavSettings } from 'store/settings/settingsSlice';

export const KnowledgePageItem = () => {
  const settingsFooterNav = useAppSelector(selectNavSettings);
  const tags = useAppSelector(selectTagList);
  const widget =
    settingsFooterNav?.footerWidgets && settingsFooterNav?.footerWidgets.find((value) => value.name === 'Baza wiedzy');
  const tagsValue = widget?.items.find((value) => value.name === 'tags')?.value;
  const chips = typeof tagsValue !== 'string' ? tagsValue : [];
  return (
    <Box maxWidth={{ md: 257, xs: 1 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 300,
          color: 'white',
        }}
      >
        {widget?.name}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 300, mt: 1 }}></Typography>
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        {chips?.map((item: any, index: number) => (
          <Chip
            key={index}
            label={tags.find((tag) => tag.id === item)?.name}
            size="small"
            sx={{
              bgcolor: 'white',
              mr: 2,
              mt: 2,
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};
