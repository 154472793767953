import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ScenariosListView: IView = {
  key: 'viewScenariosList',
  title: 'scenariosList.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./scenarios-list-page')),
  navigation: true,
  path: '/lista-scenariuszy',
};

export default ScenariosListView;
