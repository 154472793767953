import { Stack } from '@mui/material';
import React from 'react';

interface IDefintionList {
  list?: { key: string; value: string }[];
}

export const DefintionList = ({ list }: IDefintionList) => {
  return (
    <Stack sx={{ clear: 'both' }} component={'dl'}>
      {list &&
        list.map(
          (item) =>
            item && (
              <React.Fragment key={item.key}>
                <Stack
                  sx={(theme) => ({ fontSize: '12px', m: 0, display: 'block', color: theme.palette.grey[800] })}
                  component={'dl'}
                >
                  {item.key}
                </Stack>
                <Stack
                  sx={(theme) => ({
                    fontSize: '20px',
                    m: theme.spacing(0, 0, 1),
                    display: 'block',
                    color: theme.palette.secondary.dark,
                  })}
                  component={'dd'}
                >
                  {item.value}
                </Stack>
              </React.Fragment>
            )
        )}
    </Stack>
  );
};
