import i18n from "i18n";

interface customTranslation {
  key: string,
  value: string,
}

const changePhrase = (newPhrase: customTranslation) => {
    i18n.addResource(newPhrase.key, newPhrase.value, '', '');
};

export const setCustomTranslations = (newTranslations: customTranslation[]) => {
  if(newTranslations.length === 0) {
    return;
  }
  newTranslations.forEach(translation => {
    changePhrase(translation);
  })
}