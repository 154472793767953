import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';
import DictionariesView from './views/Dictionaries';
import FileManageView from './views/Files-manage';
import ViewFileManage from './views/Register';
import FooterView from './views/Footer';
import BadgesView from './views/Badges';
import ThemeManage from './views/ThemeManage';
import ViewRegister from './views/Template-files-manage/views/manage';
import SchemaManage from './views/DetailsSchema';
import ReportsView from './views/Reports';
const ViewSettings: IView = {
  key: 'viewSettings',
  title: 'Ustawienia',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  navigation: true,
  childrens: [
    ReportsView,
    DictionariesView,
    FileManageView,
    ViewFileManage,
    ViewRegister,
    FooterView,
    BadgesView,
    SchemaManage,
    ThemeManage,
  ],
  view: React.lazy(() => import('./settings')),
  path: '/ustawienia',
};

export default ViewSettings;
