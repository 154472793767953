import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4697 2.46967C16.7626 2.17678 17.2374 2.17678 17.5303 2.46967L21.5303 6.46967C21.8232 6.76256 21.8232 7.23744 21.5303 7.53033L8.31066 20.75H4C3.58579 20.75 3.25 20.4142 3.25 20L3.25 15.6893L16.4697 2.46967ZM4.75 16.3107L4.75 19.25H7.68934L16.9393 10L14 7.06066L4.75 16.3107ZM15.0607 6L18 8.93934L19.9393 7L17 4.06066L15.0607 6Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
