import { SxProps, Tab, Tabs, Theme } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';

interface ICustomSelectProps {
  sx?: SxProps<Theme>;
  value?: string | undefined;
  tabs?: any[];
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
}
export const CustomTabs: React.FC<ICustomSelectProps> = ({ onChange, value, tabs, sx = [] }) => {
  return (
    <Tabs
      value={value}
      sx={(theme) => ({
        '& .MuiTabs-indicator': {
          background: theme.palette.secondary.dark,
        },
      })}
      onChange={onChange}
    >
      {tabs?.map((tab) => (
        <Tab
          sx={(theme) => ({
            color: theme.palette.secondary.dark,
            '&.Mui-selected': {
              color: theme.palette.secondary.dark,
            },
          })}
          label={tab.label}
          value={tab.value}
          key={tab.value}
        />
      ))}
    </Tabs>
  );
};
