import { ITemplateFieldType } from 'interfaces/onboarding-templates.interface';
import { TemplateRootState } from './onboardingUserTemplatesSlice';

export const initialState: TemplateRootState = {
  loading: 'idle',
  templateId: '',
  templateName: '',
  template: {
    label: 'notSaved',
    sections: [
      {
        name: '',
        index: 0,
        label: '',
        rows: 0,
        fields: [
          {
            row: 0,
            name: '',
            label: '',
            type: ITemplateFieldType.TEXT,
          },
        ],
      },
    ],
  },
};
