import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const TriangleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M3 4.80078L0 0.000781059L6 0.000781059L3 4.80078Z" fill="#111826" />
    </SvgIcon>
  );
};
