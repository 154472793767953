import { Box, Stack, Typography, TextareaAutosize } from '@mui/material';
import { centerFlex } from 'common-styles/common-styles';
import { CustomButton } from 'components/common';
import { ButtonSize, ButtonType } from 'interfaces/button.interface';
import { PlainUserInfo } from './components/plain-user-info';
import { useDictionaries } from 'hooks/useDictionaries';
import { useTranslation } from 'react-i18next';
import { Badges } from 'components/badges/badges';
import { useForm, Controller } from 'react-hook-form';
import { IUserReviewData } from 'interfaces/user.interface';

interface IReviewUser {
  title: string;
  name: string;
  positionId: string;
  avatarId: string;
  opinionTitle: string;
  opinionPlaceholder: string;
  buttonText: string;
  handleReview: (data: IUserReviewData) => void;
}

export const ReviewUser = ({
  title,
  name,
  positionId,
  avatarId,
  opinionPlaceholder,
  opinionTitle,
  buttonText,
  handleReview,
}: IReviewUser) => {
  const { t } = useTranslation();
  const { getDictionaryValue } = useDictionaries();
  const { handleSubmit, control } = useForm<IUserReviewData>({
    defaultValues: {
      comment: '',
      badgeIds: [],
    },
  });
  const onSubmit = (data: IUserReviewData) => {
    handleReview(data);
  };
  return (
    <>
      <Box
        sx={{
          ...centerFlex,
        }}
      >
        <Stack
          direction="column"
          spacing={5}
          sx={{
            ...centerFlex,
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <PlainUserInfo
            name={name}
            profession={`${getDictionaryValue('PositionId', positionId) ?? ''}`}
            avatar={avatarId}
            tableCell
            avatarSx={{ width: 77, height: 77 }}
            nameSx={{ fontSize: 24, mb: '5px' }}
            professionSx={{ fontSize: 16 }}
            sx={{
              alignItems: 'center',
            }}
          />
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Box
          mt={10}
          sx={{
            ...centerFlex,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" mb={5}>
            {t('finishOnboardingPage.giveBadges')}
          </Typography>
          <Controller
            name="badgeIds"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Badges variant="large" onChange={onChange} value={value} isManage />
            )}
          />
        </Box> */}
        <Box
          mt={10}
          sx={{
            ...centerFlex,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5">{opinionTitle}</Typography>
          <Controller
            name="comment"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextareaAutosize
                value={value}
                minRows={4}
                onChange={onChange}
                placeholder={opinionPlaceholder}
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  height: 103,
                  fontFamily: '"niveau-grotesk", sans-serif',
                  fontSize: '20px',
                  padding: '17px 12px',
                  borderColor: '#B1B5BD',
                  resize: 'none',
                  marginTop: '40px',
                }}
              />
            )}
          />
          <CustomButton
            text={buttonText}
            size={ButtonSize.MEDIUM}
            buttonType={ButtonType.SECONDARY_ACCENT}
            type="submit"
            onClick={() => null}
            sx={{
              height: 58,
              fontSize: 20,
              width: 1,
              maxWidth: '400px',
              mt: 5,
            }}
          />
        </Box>
      </form>
    </>
  );
};
