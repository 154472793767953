import { Loader } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { RouteObject, Router, useLocation } from 'react-router-dom';
import { selectUser } from 'store/user/userSlice';
import { useAppSelector } from './useStore';
import { IView } from 'interfaces/common';
import { INavigation } from 'components/layout/navbar';
import { defaultConfig } from 'config/config';
import { useTranslation } from 'react-i18next';

export const useViews = (views: IView[]) => {
  const {
    details: { roles },
  } = useAppSelector(selectUser);
  const location = useLocation();
  const { t } = useTranslation();
  const prepareModules = useCallback(
    () =>
      views.reduce((prev: RouteObject[], { path, view: View, roles: routeRoles, title, key }: IView) => {
        if (location.pathname === path) {
          document.title = `${defaultConfig.TITLE} - ${t(title)}`;
        }
        const isAvaiable = roles?.find((value) =>
          typeof routeRoles === 'string'
            ? routeRoles === value.id
            : routeRoles?.some((routeRole: string) => routeRole === value.id)
        );
        const isPublic = routeRoles === undefined;
        if (isPublic || isAvaiable) {
          if (typeof path === 'string') {
            prev.push({
              path: path,
              element: (
                <React.Suspense key={key} fallback={<Loader open={true} />}>
                  <View />
                </React.Suspense>
              ),
            });
          } else {
            path.forEach((value) => {
              prev.push({
                path: value,
                element: (
                  <React.Suspense key={key} fallback={<Loader open={true} />}>
                    <View />
                  </React.Suspense>
                ),
              });
            });
          }
        }
        return prev;
      }, []),
    [location, roles]
  );

  const prepareNav = useCallback(
    () =>
      views.reduce((prev: INavigation[], { path, title, navigation, roles: routeRoles }: IView) => {
        const isAvaiable = roles?.find((value) =>
          typeof routeRoles === 'string'
            ? routeRoles === value.id
            : routeRoles?.some((routeRole: string) => routeRole === value.id)
        );
        if (navigation && isAvaiable) {
          if (typeof path === 'string') {
            prev.push({
              title,
              url: path,
            });
          }
        }
        return prev;
      }, []),
    [location, roles]
  );

  return {
    views: prepareModules(),
    navigation: prepareNav(),
  };
};
