import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './faqInitial';
import { getFaqManageAPI, postFaqManageAPI, getFaqTagAPI, postFaqTagAPI, deleteFaqTagAPI } from './faqAPI';
import { IFaq } from 'interfaces/faq.interface';
import { IResponseWithPagination } from 'interfaces/common';
import { ITags } from 'interfaces/knowledge-page.interface';

export interface faqListState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: {
    faq: IResponseWithPagination<IFaq[]>;
    faqTags: ITags[];
  };
  currentRequestId?: string;
}

export const fetchFaqManageList = createAsyncThunk('users/fetchFaqManage', async (params?: object) => {
  try {
    const response = await getFaqManageAPI(params);
    const tagsResponse = await getFaqTagAPI();
    return {
      faq: response.data.value,
      faqTags: tagsResponse.data.value.records,
    };
  } catch (err) {
    return err;
  }
});

export const postFaqManage = createAsyncThunk('users/postFaqManage', async (params?: object) => {
  try {
    const response = await postFaqManageAPI(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});
export const deleteFaqManage = createAsyncThunk('users/deleteFaqManage', async (params?: object) => {
  try {
    await postFaqManageAPI(params);
    return params;
  } catch (err) {
    return err;
  }
});

export const fetchFaqTags = createAsyncThunk('faq/getTags', async (params?: object) => {
  try {
    const response = await getFaqTagAPI(params);
    return response.data.value.records;
  } catch (err) {
    return err;
  }
});
export const postFaqTags = createAsyncThunk('faq/addTag', async (name: string, { rejectWithValue }) => {
  try {
    const response = await postFaqTagAPI(name);
    return response.data.value;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteFaqTag = createAsyncThunk('faq/deleteTag', async (id: string, { rejectWithValue }) => {
  try {
    await deleteFaqTagAPI(id);
    return id;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const { reducer, actions } = createSlice({
  name: 'faqManage',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.data.faq.page = action.payload + 1;
    },
    prevPage: (state, action) => {
      state.data.faq.page = action.payload - 1;
    },
    newPageSize: (state, action) => {
      state.data.faq.size = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaqManageList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFaqManageList.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFaqManageList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFaqManage.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteFaqManage.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload.frequentlyAskedQuestions;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFaqManage.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFaqTags.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFaqTags.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.faqTags = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFaqTags.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFaqTags.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postFaqTags.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.faqTags = [...state.data.faqTags, { ...action.payload }];
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFaqTags.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFaqTag.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteFaqTag.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.faqTags = state.data.faqTags.filter((tag) => tag.id !== action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFaqTag.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectFaqList = (state: RootState) => state.faqManage;
export const selectFaqTagsList = (state: RootState) => state.faqManage.data.faqTags;
export const { newPage, newPageSize, prevPage } = actions;

export default reducer;
