import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RedExclamationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7L12.75 14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14L11.25 7C11.25 6.58579 11.5858 6.25 12 6.25ZM12 16.24C12.4142 16.24 12.75 16.5758 12.75 16.99V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V16.99C11.25 16.5758 11.5858 16.24 12 16.24Z"
        fill="#FF0E4F"
      />
    </SvgIcon>
  );
};
