import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 5C8.25 3.48122 9.48122 2.25 11 2.25H13C14.5188 2.25 15.75 3.48122 15.75 5V5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H18.75V18C18.75 19.5188 17.5188 20.75 16 20.75H8C6.48122 20.75 5.25 19.5188 5.25 18V6.75H4C3.58579 6.75 3.25 6.41421 3.25 6C3.25 5.58579 3.58579 5.25 4 5.25H8.25V5ZM6.75 6.75V18C6.75 18.6904 7.30964 19.25 8 19.25H16C16.6904 19.25 17.25 18.6904 17.25 18V6.75H6.75ZM14.25 5.25H9.75V5C9.75 4.30964 10.3096 3.75 11 3.75H13C13.6904 3.75 14.25 4.30964 14.25 5V5.25ZM10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V10C9.25 9.58579 9.58579 9.25 10 9.25ZM14 9.25C14.4142 9.25 14.75 9.58579 14.75 10V16C14.75 16.4142 14.4142 16.75 14 16.75C13.5858 16.75 13.25 16.4142 13.25 16V10C13.25 9.58579 13.5858 9.25 14 9.25Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
