import { ITemplateFieldType } from 'interfaces/onboarding-templates.interface';
import { OnboardingUserState } from './onboardingUserDetailsSlice';

export const initialState: OnboardingUserState = {
  loading: 'idle',
  status: 'Onboarding',
  data: {
    id: '',
    email: '',
    name: '',
    surname: '',
    avatar: '',
    round: {
      id: '',
      name: '',
    },
    created: '',
    claims: [''],
    language: '',
    hidePersonalData: false,
    hideInRanking: false,
    gender: undefined,
    extraDetails: {},
  },
  schema: [],
};
