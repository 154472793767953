import { dictionariesState } from './badgesSlice';

export const initialState: dictionariesState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
};
