import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.75 9C10.75 8.58579 10.4142 8.25 10 8.25C9.58579 8.25 9.25 8.58579 9.25 9V9.01C9.25 9.42421 9.58579 9.76 10 9.76C10.4142 9.76 10.75 9.42421 10.75 9.01V9Z"
        fill="#111826"
      />
      <path
        d="M6 8.25C6.41421 8.25 6.75 8.58579 6.75 9V9.01C6.75 9.42421 6.41421 9.76 6 9.76C5.58579 9.76 5.25 9.42421 5.25 9.01V9C5.25 8.58579 5.58579 8.25 6 8.25Z"
        fill="#111826"
      />
      <path
        d="M14.75 9C14.75 8.58579 14.4142 8.25 14 8.25C13.5858 8.25 13.25 8.58579 13.25 9V9.01C13.25 9.42421 13.5858 9.76 14 9.76C14.4142 9.76 14.75 9.42421 14.75 9.01V9Z"
        fill="#111826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4336 0.237216C8.13763 0.144182 5.87362 0.799341 3.98223 2.10395C3.0764 2.69465 2.29687 3.45938 1.68891 4.35381C1.07895 5.25117 0.65392 6.26108 0.438643 7.32454C0.223366 8.38801 0.222159 9.48372 0.435093 10.5477C0.623354 11.4883 0.975669 12.3875 1.47497 13.2043L0.288501 16.7637C0.203438 17.0189 0.261552 17.3001 0.440804 17.5007C0.620056 17.7012 0.89299 17.7904 1.15609 17.7345L5.60791 16.7873C7.61055 17.707 9.85455 17.973 12.0206 17.5437C14.2729 17.0974 16.3163 15.9239 17.8369 14.2036L17.8481 14.1907C18.5471 13.3627 19.0732 12.403 19.3952 11.3684C19.7172 10.3337 19.8287 9.24502 19.723 8.16657C19.6173 7.08812 19.2966 6.04178 18.7798 5.08931C18.2638 4.13813 17.5626 3.29984 16.7177 2.62377C14.9372 1.16796 12.7317 0.330334 10.4336 0.237216ZM4.82647 3.34385C6.45187 2.22033 8.3986 1.65599 10.3729 1.73599C12.3472 1.81598 14.2419 2.53598 15.771 3.78732L15.7778 3.79276C16.4677 4.3442 17.0402 5.02829 17.4614 5.80463C17.8826 6.58097 18.144 7.43384 18.2301 8.31287C18.3163 9.1919 18.2254 10.0793 17.9629 10.9226C17.7013 11.7633 17.2744 12.5432 16.7074 13.2166C15.4049 14.6872 13.6562 15.6904 11.729 16.0724C9.79902 16.4549 7.79684 16.1931 6.03002 15.3274C5.87928 15.2535 5.70812 15.2324 5.54393 15.2673L2.12604 15.9945L3.01153 13.3381C3.08528 13.1168 3.0519 12.8738 2.92122 12.6806C2.4249 11.947 2.07975 11.1218 1.90593 10.2533C1.7321 9.38476 1.73309 8.4903 1.90882 7.62215C2.08456 6.75401 2.43153 5.92958 2.92946 5.19704C3.42739 4.46449 4.0663 3.83851 4.80887 3.35565L4.82647 3.34385Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
