import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useToggle } from 'hooks/useToggle';
import { IFiles } from 'interfaces/files.interface';
import { UserAssignItem } from 'interfaces/user.interface';
import { IFaq } from 'interfaces/faq.interface';
import { IDefaultInteface, IPagination, KeyValues } from 'interfaces/common';
import { IResponseWithPagination } from 'interfaces/common';
import { CustomButton, CustomDialog, SearchField } from '..';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { Loader } from '../loader';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { FilterChips } from 'views/Knowledge-page/components/filter-chips';

export enum IUploaderType {
  USERS = 'users',
  FILES = 'files',
  FAQ = 'faq',
}

interface IUploaderProps {
  fetch: any;
  buttonAddText: string;
  buttonCenter?: boolean;
  oneChoice?: boolean;
  noFile?: boolean;
  extraParams?: string;
  removeChoice?: boolean;
  onRemove?: any;
  hiddenButton?: boolean;
  requireTags?: boolean;
  searchTags?: IDefaultInteface[];
  paramsSort?: { sort: string; asc: boolean };
  defaultValue?: KeyValues[];
  itemSelected: (item: any, index: number) => JSX.Element;
  itemListLabel: (item: any) => JSX.Element;
  useSelection?: (id: string) => void;
}

interface MissionPagination extends IPagination {
  filesFilter?: 'withfiles' | 'all' | 'nofiles';
  sort?: string;
  asc?: boolean;
  tag?: string;
}

// eslint-disable-next-line react/prop-types
export const CustomDataPicker: React.FC<IUploaderProps> = ({
  buttonAddText,
  oneChoice,
  buttonCenter,
  extraParams,
  paramsSort,
  defaultValue,
  removeChoice,
  itemSelected,
  hiddenButton,
  onRemove,
  noFile,
  fetch,
  itemListLabel,
  useSelection,
  searchTags = [],
  requireTags = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useToggle();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checked, setChecked] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTag, setCurrentTag] = useState<IDefaultInteface | undefined>();

  const [items, setItems] = useState<IResponseWithPagination<any[]>>();
  const [pagination, setPagination] = useState<MissionPagination>(
    paramsSort
      ? {
          page: 1,
          size: 10,
          filesFilter: noFile ? 'nofiles' : 'withfiles',
          ...paramsSort,
        }
      : {
          page: 1,
          size: 10,
          filesFilter: noFile ? 'nofiles' : 'withfiles',
        }
  );

  React.useEffect(() => {
    if (defaultValue) {
      setChecked([...defaultValue]);
      setSelected([...defaultValue]);
    }
  }, [defaultValue]);

  const getValueId = (value: any) => (extraParams ? value[extraParams] : value.id);

  const handleToggle = (valueItem: string) => () => {
    const currentIndex = checked.findIndex((value) => getValueId(value) === valueItem);
    const currentItem = items?.records.find((value) => getValueId(value) === valueItem);
    const newChecked = oneChoice ? [] : [...checked];
    if (currentIndex === -1) {
      newChecked.push(currentItem);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    if (searchTags && searchTags.length) {
      setCurrentTag(searchTags[0]);
    }
  }, [searchTags]);

  const debounceSearch = useCallback(
    _debounce((searchValue) => setSearchTerm(searchValue), 500),
    []
  );

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceSearch(event.target.value);
    if (pagination.page !== 1) {
      setPagination({
        ...pagination,
        page: 1,
      });
    }
  };

  useEffect(() => {
    if (open) fetchItems();
  }, [open, pagination.page, pagination.size, pagination.term, searchTerm, currentTag]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      if (requireTags && searchTags.length) {
        const response = await fetch({
          ...pagination,
          term: searchTerm,
          TagIds: [currentTag?.id],
        });
        if (response.data.success) {
          setItems(response.data.value);
          setPagination({
            ...pagination,
            recordCount: response.data.value.recordsCount,
          });
        } else {
          setItems(undefined);
        }
      } else {
        const {
          data: { value },
        } = await fetch({
          ...pagination,
          term: searchTerm,
        });
        setPagination({
          ...pagination,
          recordCount: value.recordsCount,
        });
        setItems(value);
      }
    } catch (e) {
      console.error(e);
      setItems(undefined);
    } finally {
      setLoading(false);
    }
  };
  const onSelect = () => {
    if (checked.length > 0) {
      setSelected([]);
      setTimeout(() => {
        if (useSelection) {
          useSelection(getValueId(checked[0]));
        }
        setSelected(checked);
        setOpen();
      });
    } else {
      setSelected([]);
      setOpen();
    }
  };

  const onRemoveChoice = () => {
    setSelected([]);
    setChecked([]);
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <>
      {open ? (
        <CustomDialog onClose={setOpen} sx={{ minWidth: '400px' }} maxWidth={'lg'} open={open}>
          <DialogContent>
            <DialogTitle sx={{ m: 0, p: 0, fontSize: '24px', textTransform: 'uppercase' }}>
              {t('common.choice')}
            </DialogTitle>
            <Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" spacing={5} sx={{ mb: 2 }}>
              <Box>
                <Typography variant="body1" sx={{ mb: '16px' }}>
                  Liczba wyników: {pagination.recordCount}{' '}
                </Typography>
              </Box>
              {requireTags && searchTags.length > 1 && (
                <Stack direction="row" flexWrap="wrap" spacing="15px">
                  <Typography variant="body2">{t('button.filter')}:</Typography>
                  {searchTags.length &&
                    searchTags.map((item) => (
                      <FilterChips
                        {...item}
                        active={currentTag?.name === item.name}
                        key={item.id}
                        setTags={setCurrentTag}
                        requiredChoice
                      />
                    ))}
                </Stack>
              )}
            </Stack>
            <SearchField
              sx={{ mb: 2 }}
              onChange={onChangeSearch}
              placeholder={t('common.search')}
              value={searchValue}
            />
            {loading ? (
              <Loader open={loading} />
            ) : items && items.records.length === 0 ? (
              <Box>{t('commonTable.noData')}</Box>
            ) : (
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {items &&
                  items.records.map((item: any) => (
                    <ListItem key={item.id} disablePadding>
                      <ListItemButton role={undefined} onClick={handleToggle(getValueId(item))} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.findIndex((value) => getValueId(value) === getValueId(item)) > -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': getValueId(item) }}
                          />
                        </ListItemIcon>
                        <ListItemText id={item.id} primary={itemListLabel(item)} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            )}
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Pagination
              page={pagination.page}
              count={pagination.recordCount ? Math.ceil(pagination.recordCount / pagination.size) : 0}
              onChange={(event, page) =>
                setPagination({
                  ...pagination,
                  page,
                })
              }
            />
            <Box>
              <CustomButton
                text={t('button.cancel')}
                buttonType={ButtonType.SECONDARY}
                onClick={setOpen}
                sx={{ fontSize: '14px', mr: '16px' }}
                size={ButtonSize.SMALL}
              />
              <CustomButton
                text={t('button.select')}
                buttonType={ButtonType.PRIMARY}
                onClick={onSelect}
                sx={{ fontSize: '14px' }}
                size={ButtonSize.SMALL}
              />
            </Box>
          </DialogActions>
        </CustomDialog>
      ) : (
        <></>
      )}
      {selected.length > 0 && (
        <Grid container spacing={4} columns={12}>
          {selected.map((item: IFiles | UserAssignItem | IFaq, index) => {
            return itemSelected(item, index);
          })}
        </Grid>
      )}
      {!hiddenButton && (
        <Box display={'flex'} justifyContent={buttonCenter ? 'center' : 'left'}>
          <Button variant="contained" onClick={selected.length > 0 && removeChoice ? onRemoveChoice : setOpen}>
            {selected.length > 0 && removeChoice ? 'Usuń' : '+ ' + buttonAddText}
          </Button>
        </Box>
      )}
    </>
  );
};
