import { Theme } from '@mui/material';

export const centerFlex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const boxShadow = {
  boxShadow: '4px 20px 30px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease-in-out',
};

export const iconButtonStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.grey[100],
  '&:hover': {
    boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.15)',
  },
});
