import { LinearProgress, Box, Typography, Theme, Stack } from '@mui/material';
import { IProgressBarProps } from 'interfaces/common';
import { StatusProgress } from 'interfaces/user.interface';
import { userProgressValue } from 'utils/progress';

export const ProgressBar = ({
  title = '',
  value,
  width = 1,
  status = StatusProgress.ONBOARDING,
}: IProgressBarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: width,
      }}
    >
      <Stack direction="row" spacing={1} mb={1}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {userProgressValue(value ?? 0)}%
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={userProgressValue(value ?? 0)}
        sx={(theme: Theme) => ({
          width: '80%',
          '&.MuiLinearProgress-root': {
            backgroundColor: theme.palette.grey[200],
            height: '8px',
            '& .MuiLinearProgress-bar': {
              bgcolor: status === StatusProgress.BOARDED ? '#43bf886b' : theme.palette.secondary.main,
            },
          },
        })}
      />
    </Box>
  );
};
