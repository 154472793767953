import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ReportsView: IView = {
  key: 'ReportsView',
  title: 'ReportsView.title',
  view: React.lazy(() => import('./reports')),
  path: '/reports',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
};

export default ReportsView;
