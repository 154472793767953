import { IResponse } from 'interfaces/common';
import { PutSchemaField, SchemaField } from 'interfaces/schema-form';
import { StatusProgress } from 'interfaces/user.interface';
import appService from 'services/common';

export interface ReponseExtraDetails<ContentKey> {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userAvatar: string;
  userUserName: string;
  status: 'Onboarding';
  content: {
    [key in keyof ContentKey]: string;
  };
}

const fetchUserExtraDetails = (userId: string) => {
  return appService.get<IResponse<ReponseExtraDetails<string>>>(`/onboarding-user-details-manage/${userId}`);
};

const putUserExtraDetails = (userId: string, content: any, buddyId?: string, superiorId?: string) => {
  return appService.put(`/onboarding-user-details-manage/${userId}`, {
    superiorId,
    buddyId,
    content: {
      ...content,
    },
  });
};

const putStatusUserExtraDetails = (userId: string, status?: string) => {
  return appService.put(`/onboarding-user-details-manage/${userId}/status`, {
    status: status ?? StatusProgress.PREONBOARDING,
  });
};

const putEmploymentUser = (userId: string, dates: any) => {
  return appService.put(`/onboarding-user-details-manage/${userId}/employment`, {
    employmentStartDate: dates.employmentStartDate,
    onboardingEndDate: dates.onboardingEndDate,
  });
};

const fetchOnboardingSchemaForm = async () => {
  try {
    const { data } = await appService.get<IResponse<SchemaField[]>>(`/onboarding-users-schema-manage`);
    return data.value;
  } catch (error) {
    return error;
  }
};
const fetchOnboardingSchemaUserForm = async () => {
  try {
    const { data } = await appService.get<IResponse<SchemaField[]>>(`/onboarding-users-schema`);
    return data.value;
  } catch (error) {
    return error;
  }
};
const postOnboardingSchemaForm = async (field: SchemaField) => {
  return await appService.post<IResponse>(`/onboarding-users-schema-manage`, field);
};

const putOnboardingSchemaForm = async (name: string, field: PutSchemaField) => {
  return await appService.put<IResponse>(`/onboarding-users-schema-manage/${name}`, { ...field });
};

export {
  fetchUserExtraDetails,
  putUserExtraDetails,
  putStatusUserExtraDetails,
  fetchOnboardingSchemaForm,
  fetchOnboardingSchemaUserForm,
  putEmploymentUser,
  postOnboardingSchemaForm,
  putOnboardingSchemaForm,
};
