import appService from 'services/common';

const getOnboardingTemplates = (params?: object) => {
  return appService.get('/onboarding-templates-manage', { params });
};
const postOnboardingTemplates = (params?: any) => {
  return appService.post('/onboarding-templates-manage', params);
};
const putOnboardingTemplates = (params?: any) => {
  return appService.put(`/onboarding-templates-manage/${params?.id}`, params);
};
const deleteOnboardingTemplates = (id: string) => {
  return appService.delete(`/onboarding-templates-manage/${id}`);
};

export { getOnboardingTemplates, postOnboardingTemplates, putOnboardingTemplates, deleteOnboardingTemplates };
