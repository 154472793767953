import { settingsState } from './settingsSlice';

export const initialState: settingsState = {
  loading: 'idle',
  data: {
    DEFAULT_LANGUAGE: null,
    STORED_LANGUAGE_KEY: null,
    STORED_CLAIMS: null,
    STORED_TOKEN_KEY: null,
    fileTypeSettings: [],
    googleTagManagerId: '',
    group_settings: {
      dashboardTreeLevel: 0,
      resultTreeLevel: 0,
      resultsTreeType: '',
    },
    name: '',
    tenant: {
      tenantId: '',
    },
    theme: {},
    title: '',
    url: '',
  },
};
