import './App.css';
import { Footer, Navbar } from './components/layout';
import RouterViews from 'router';
import { BrowserRouter } from 'react-router-dom';
import _set from 'lodash/set';
import defaultTheme from 'themes/default-theme';
import { useAppSelector } from 'hooks';
import { selectSettings } from 'store/settings/settingsSlice';
import { useEffect, useState } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
function App() {
  const feSettings = useAppSelector(selectSettings);
  const [currentTheme, setCurrentTheme] = useState<any>(defaultTheme);
  const applyStyle = () => {
    if (feSettings.data.theme['default']) {
      const themeFetched = { ...currentTheme };
      for (const item of feSettings.data.theme['default']) {
        _set(themeFetched, item.key, item.value);
      }
      setCurrentTheme(themeFetched);
    }
  };
  useEffect(() => {
    applyStyle();
  }, [feSettings]);
  return (
    <BrowserRouter>
      <CookiesProvider>
        <ThemeProvider theme={currentTheme ?? defaultTheme}>
          <CssBaseline enableColorScheme />
          <Navbar />
          <RouterViews />
          <Footer />
        </ThemeProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
