import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C9.65279 3.75 7.75 5.65279 7.75 8V8.30899C7.99743 8.34828 8.23877 8.40672 8.47237 8.48282C8.86621 8.61112 9.08147 9.0344 8.95317 9.42824C8.82487 9.82209 8.40159 10.0374 8.00775 9.90905C7.69143 9.80601 7.353 9.75 7 9.75C5.20507 9.75 3.75 11.2051 3.75 13C3.75 14.202 4.40216 15.2526 5.37554 15.8157C5.73409 16.0231 5.85661 16.4819 5.6492 16.8404C5.4418 17.199 4.983 17.3215 4.62446 17.1141C3.20663 16.2939 2.25 14.7591 2.25 13C2.25 10.6318 3.98301 8.66846 6.25 8.30888V8C6.25 4.82436 8.82436 2.25 12 2.25C15.1756 2.25 17.75 4.82436 17.75 8V8.30888C20.017 8.66846 21.75 10.6318 21.75 13C21.75 14.7591 20.7934 16.2939 19.3755 17.1141C19.017 17.3215 18.5582 17.199 18.3508 16.8404C18.1434 16.4819 18.2659 16.0231 18.6245 15.8157C19.5978 15.2526 20.25 14.202 20.25 13C20.25 11.2051 18.7949 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V8C16.25 5.65279 14.3472 3.75 12 3.75ZM7.46967 14.4697L11.4697 10.4697C11.7626 10.1768 12.2374 10.1768 12.5303 10.4697L16.5303 14.4697C16.8232 14.7626 16.8232 15.2374 16.5303 15.5303C16.2374 15.8232 15.7626 15.8232 15.4697 15.5303L12.75 12.8107V21C12.75 21.4142 12.4142 21.75 12 21.75C11.5858 21.75 11.25 21.4142 11.25 21V12.8107L8.53033 15.5303C8.23744 15.8232 7.76256 15.8232 7.46967 15.5303C7.17678 15.2374 7.17678 14.7626 7.46967 14.4697Z"
        fill="white"
      />
    </SvgIcon>
  );
};
