import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewThemeManage: IView = {
  key: 'viewThemeManage',
  title: 'themeManageView.title',
  view: React.lazy(() => import('./theme-manage')),
  path: '/wyglad-strony',
  roles: [IRoles.ADMINISTRATOR],
};

export default ViewThemeManage;
