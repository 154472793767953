import { IDefaultInteface, IPagination, IResponse, IResponseWithPagination } from 'interfaces/common';
import appService from 'services/common';

const getFileTags = (params?: object) => {
  return appService.get<IResponse<IDefaultInteface[]>>('files-manage/tags', {
    params,
  });
};

export { getFileTags };
