import { isAfter, format } from 'date-fns';
import { t } from 'i18next';

export const getParams = (array: any[], key: string) => {
  let params = '?';

  for (let i = 0; i < array.length; i++) {
    // In this case YOUR_ARRAY == [1, 2, 3]
    params += `${key}=${array[i].value ? array[i].value : array[i]}`; // storeIds is your PARAM_NAME
    if (i !== array.length - 1) params += '&';
  }
  return params;
};

export const findByName = (fields: any[], equal: string) => fields.find((field) => field.name === equal) ?? '';

export const handleWierdDates = (date: string | Date, showUndefined: boolean) => {
  if (date === '0001-01-01T00:00:00Z' || date === '1900-01-01T00:00:00Z') {
    return '----';
  }
  if (isAfter(new Date(date), new Date(2100, 1, 1))) {
    return showUndefined ? t('common.unset') : '----';
  } else {
    return format(new Date(date), 'dd.MM.yyyy');
  }
};

export const transformMDYDateToDMY = (date: string) => {
  return format(new Date(date), 'dd.MM.yyyy');
}

export const isDateFormat = (date: string) => {
  const regex = /\d{2}\/\d{2}\/\d{4}/;
  return regex.test(date);
}

export const handleUsDate = (date: string) => {
  if(isDateFormat(date)) {
    return transformMDYDateToDMY(date);
  }
  return date;
}