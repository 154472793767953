import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, IconButton, Chip, Tooltip } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { EditIcon, EyeIcon, SmallArrowPoint, StarIcon, TrashIcon } from 'assets/icons';
import { PriorityType } from 'interfaces/tasks.interface';
import { useAppSelector } from 'hooks/useStore';
import { IRoles } from 'interfaces/roles.interface';
import { selectUser } from 'store/user/userSlice';
interface Props {
  data: any[];
  tableAction: (data: any, name: 'edit' | 'preview' | 'delete' | 'assign') => void;
}
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
export const useCreateQuizzesTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const {
    details: { roles, id },
  } = useAppSelector(selectUser);
  const { t } = useTranslation();
  const columns: ITableColumn[] = [
    {
      width: 20,
      label: t('tasks.taskTable.task'),
      hasFilter: false,
      filterAction: () => console.log('filter name'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.taskType'),
      hasFilter: false,
      filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.type'),
      hasFilter: false,
      filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.totalQuestions'),
      hasFilter: false,
      filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.points'),
      hasFilter: false,
      // filterAction: () => console.log('filter buddy'),
    },
    {
      width: 20,
      label: t('tasks.taskTable.created'),
    },
    {
      width: 20,
      // label: 'Akcja',
    },
    {},
  ];

  const handlePriorityType = (type: PriorityType) => {
    switch (type) {
      case PriorityType.OBLIGATORY:
        return <Chip label={t('tasks.taskCreation.priorityType.obligatory')} sx={{ bgcolor: '#FFBE0026' }} />;
      case PriorityType.PRIORITY:
        return <Chip label={t('tasks.taskCreation.priorityType.priority')} sx={{ bgcolor: '#FF0E4F33' }} />;
      case PriorityType.VOLUNTEERS:
        return <Chip label={t('tasks.taskCreation.priorityType.volunteers')} sx={{ bgcolor: '#004AB51A' }} />;
      case PriorityType.STANDARD:
        return <Chip label={t('tasks.taskCreation.priorityType.standard')} sx={{ bgcolor: '#43BF8826' }} />;
    }
  };
  const convertDataToRows = (data: any[]) => {
    const tempRows: any = [];
    data.forEach((item: any) => {
      const isSuperiorEditable = roles?.find((role) => role.id === IRoles.ADMINISTRATOR || role.id === IRoles.HR);
      tempRows.push({
        id: item.id,
        elements: [
          <Typography variant="subtitle1">{item.name}</Typography>, // nazwa
          <Typography variant="subtitle1">{t(`tasks.taskTable.quizType.${item.quizType}`)}</Typography>, // rodzaj
          handlePriorityType(item.priorityType), // typ
          <Typography variant="subtitle1">{item.totalQuestions}</Typography>, // ilość pytań
          <Typography
            variant="subtitle1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            <StarIcon
              sx={(theme) => ({
                '& path': {
                  fill: theme.palette.grey[600],
                },
              })}
            />
            <Stack sx={{ position: 'relative', top: '2px' }}>{item.totalPoints}</Stack>
          </Typography>, // punkty
          <Typography variant="subtitle1">
            {item.created ? format(new Date(item.created), 'dd.MM.yyyy') : '----'}
          </Typography>,
          <Stack direction="row" spacing={2}>
            {' '}
            {/* akcje */}
            {Boolean(isSuperiorEditable) && (
              <Tooltip title={'Edytuj quiz'}>
                <IconButton onClick={() => tableAction(item, 'edit')}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={'Zobacz quiz'}>
              <IconButton onClick={() => tableAction(item, 'preview')}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Przypisz użytkownika do quizu'}>
              <IconButton onClick={() => tableAction(item, 'assign')}>
                <SmallArrowPoint />
              </IconButton>
            </Tooltip>
            {Boolean(isSuperiorEditable) && (
              <Tooltip title={'Usuń quiz'}>
                <IconButton onClick={() => tableAction(item, 'delete')}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
