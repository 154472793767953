import appService from 'services/common';

const getKnowledgePagesInGroupAPI = (roundId: string, params?: object) => {
  return appService.get(`/rounds/${roundId}/knowledge-pages`, { params });
};
const getKnowledgePagesTagsAPI = (params?: object) => {
  return appService.get('/knowledge-pages/tags', { params });
};

export { getKnowledgePagesInGroupAPI, getKnowledgePagesTagsAPI };
