import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import React, { useMemo } from 'react';
import { selectNavSettings } from 'store/settings/settingsSlice';

export const ContactItem = () => {
  const settingsFooterNav = useAppSelector(selectNavSettings);
  const widget =
    settingsFooterNav?.footerWidgets && settingsFooterNav?.footerWidgets.find((value) => value.name === 'Kontakt');
  const phoneNumber = useMemo(() => widget?.items.find((item) => item.name === 'phoneNumber'), [widget]);
  const email = useMemo(() => widget?.items.find((item) => item.name === 'email'), [widget]);
  return (
    <Box maxWidth={{ md: 257, xs: 1 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 300,
          color: 'white',
        }}
      >
        {widget?.name}
      </Typography>
      {widget && (
        <Stack
          spacing={2}
          sx={{
            mt: 4,
          }}
        >
          {phoneNumber && (
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                Numer telefonu
              </Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {phoneNumber.value}
              </Typography>
            </Box>
          )}
          {email && (
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                Email
              </Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {email.value}
              </Typography>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};
