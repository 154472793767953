import { IDefaultInteface } from './common';
import { MissionCategory } from './missions.interface';
import { IAcceptorInfo, IAssignee } from './user.interface';

export enum PriorityType {
  STANDARD = 'standard',
  OBLIGATORY = 'obligatory',
  VOLUNTEERS = 'volunteers',
  PRIORITY = 'priority',
}
export enum TaskType {
  NONE = 'None',
  QUIZ = 'Quiz',
  QUEST = 'Quest',
  SURVEY = 'Survey',
  MISSION = 'Mission',
}
export enum TaskUserType {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
}
export enum TaskStatusType {
  NONE = 'None',
  NEW = 'New',
  APPROVE = 'Approve',
  REJECTED = 'Rejected',
  FINISHED = 'Finished',
  INREVIEW = 'InReview',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  SOON = 'Soon',
  ACTIVE = 'Active',
  APPROVED = 'Approved',
}

export enum MeetingType {
  STANDATD = 'standard',
  NO_MEETING = 'nomeeting',
  EMPLOYEE = 'employee',
  BUDDY = 'buddy',
  SUPERIOR = 'superior',
}
export enum VerificationOptions {
  NO_VERIFICATION = 'noVerification',
  SUPERIOR = 'superior',
  EMPLOYEE = 'employee',
}
export interface ITask extends IDefaultInteface {
  round?: string;
  actual?: boolean;
  type: TaskType;
  userType: TaskUserType;
  status: TaskStatusType;
  attempts: number;
  currentAttempt: number;
  tokens: number;
  isBonus: boolean;
  isPassed: boolean;
  objectId: string;
  to: string;
  meetingType: MeetingType;
  priorityType: PriorityType;
  asReviewer?: boolean;
  forPreview?: boolean;
  description?: string;
  content?: string;
  objectDescription?: string;
  tagsMission?: ITag[];
  userId?: string;
}
export interface ITasks {
  id: string;
  name: string;
  attempts: number;
  canEditPoints: boolean;
  description: string;
  isExternal: boolean;
  missionApprovingUsers: string[];
  missionFiles?: string[];
  roundIds: string[];
  tags: string[];
  tokens: number;
  type: TaskUserType;
  creatorId?: string | null;
  meetingType: MeetingType;
  priorityType: PriorityType;
  category: MissionCategory;
  updatedDate?: string;
  positionId?: string;
  content?: string;
  daysToFinish?: number;
  predefinedMeetingUserAvatarId?: string;
  predefinedMeetingUserId?: string;
  predefinedMeetingUserJobTitle?: string;
  predefinedMeetingUserLastName?: string;
  predefinedMeetingUserName?: string;
}

export interface ITag {
  id: string;
  name: string;
}

export interface IUserTask extends IDefaultInteface {
  type: TaskType;
  assignee: IAssignee;
  status: TaskStatusType;
  points: number;
  assignedOn: string;
  to: string;
  acceptorInfo: IAcceptorInfo;
}
