import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, Stack } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LinkItem } from './link-item';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAppSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { change } from 'store/currentPage/currentPageSlice';
import { defaultConfig } from 'config/config';
import { INavigation } from '../navbar';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'config/const';
interface Props {
  navigation: INavigation[];
}

export const MobileNavList = ({ navigation }: Props) => {
  const [openNav, setOpenNav] = useState<boolean>(false);
  const activePageName = useAppSelector((state) => state.currentPage.name);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleNavIconClick = () => {
    setOpenNav(true);
  };
  const handleNavClose = () => {
    setOpenNav(false);
  };

  const onSignout = () => {
    localStorage.removeItem(defaultConfig.KEY_TOKEN);
    window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
  };

  return (
    <>
      <MenuIcon
        onClick={handleNavIconClick}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      />
      <Drawer
        hideBackdrop
        anchor="top"
        open={openNav}
        onClose={handleNavClose}
        sx={{
          '& .MuiPaper-root': {
            width: 1,
            height: 1,
            pl: 2,
            pt: '63px',
            position: 'relative',
          },
        }}
      >
        <CloseOutlinedIcon
          onClick={handleNavClose}
          sx={{
            width: 15,
            height: 15,
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
        />
        <Stack direction="column" spacing={4}>
          {navigation.map((link) => (
            <LinkItem
              key={link.title}
              {...link}
              title={t(link.title)}
              active={link.title === activePageName}
              onClick={() => dispatch(change(link.title))}
            />
          ))}
          <Stack direction="row" spacing={4}>
            {/* TODO: Switch for changing languages - uncomment when the funcionality will be added */}
            {/* <CustomButton
              text="PL"
              buttonType={ButtonType.PRIMARY}
              onClick={() => console.log('lang pl')}
              size={ButtonSize.SMALL}
              sx={{
                fontSize: 20,
                fontWeight: 400,
                p: '8px 27px',
                maxHeight: 39,
              }}
            />
            <CustomButton
              text="EN"
              buttonType={ButtonType.SECONDARY}
              onClick={() => console.log('lang En')}
              size={ButtonSize.SMALL}
              sx={{
                fontSize: 20,
                fontWeight: 400,
                p: '8px 27px',
                maxHeight: 39,
              }}
            /> */}
          </Stack>
          {/* TODO: Profile button - uncomment when the funcionality will be added */}
          {/* <Button
            startIcon={<UserProfileIcon sx={{ width: 40, height: 45 }} />}
            color="secondary"
            sx={{
              width: 'fit-content',
              m: 0,
              p: 0,
              fontSize: 40,
              fontWeight: 400,
              maxHeight: 44,
            }}
          >
            Profil
          </Button> */}
          <Button
            startIcon={<LockOutlinedIcon sx={{ width: 40, height: 45 }} />}
            color="secondary"
            sx={{
              width: 'fit-content',
              m: 0,
              p: 0,
              fontSize: 40,
              fontWeight: 400,
              maxHeight: 44,
            }}
            onClick={onSignout}
          >
            {t('button.logout')}
          </Button>
        </Stack>
      </Drawer>
    </>
  );
};
