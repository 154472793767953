import { Chip } from '@mui/material';
import React from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export enum AlertType {
  WARNING = 'warning',
  ERROR = 'error',
}

interface IAlertIconProps {
  label: string;
  type: AlertType;
}
export const AlertIcon = ({ label, type }: IAlertIconProps) => {
  return (
    <Chip
      variant="filled"
      color={type}
      label={label}
      icon={
        type === AlertType.ERROR ? (
          <NotificationsActiveOutlinedIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              width: 16,
              height: 16,
              top: '50%',
              left: 3,
              transform: 'translateY(-50%)',
            }}
          />
        ) : (
          <InfoOutlinedIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              width: 16,
              height: 16,
              top: '50%',
              left: 3,
              transform: 'translateY(-50%)',
            }}
          />
        )
      }
      sx={{
        width: 32,
        position: 'relative',
        transition: 'width 1s',
        '&:hover': {
          width: 'auto',
        },
        '&:hover > .MuiChip-label': {
          display: 'block',
        },
        '& .MuiChip-label': {
          display: 'none',
          fontSize: 12,
          padding: '9px 8px 9px 32px',
        },
      }}
    />
  );
};
