import { SvgIcon, SvgIconProps } from '@mui/material';

export const SmallArrowPoint = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_1553_56947)">
        <path
          d="M23.7928 19.8173L19.7377 15.7622L21.7265 13.7735C21.8988 13.6012 21.9696 13.352 21.9136 13.1148C21.8576 12.8776 21.6829 12.6864 21.4517 12.6093L9.51927 8.63181C9.26666 8.54758 8.98803 8.61334 8.79973 8.80169C8.61139 8.99003 8.54562 9.26856 8.62986 9.52122L12.6073 21.4537C12.6845 21.6849 12.8757 21.8597 13.1128 21.9156C13.35 21.9717 13.5993 21.9009 13.7716 21.7285L15.7603 19.7398L19.8154 23.7948C19.9527 23.9321 20.1327 24.0008 20.3126 24.0008C20.4925 24.0008 20.6725 23.9321 20.8097 23.7948L23.7928 20.8117C24.0674 20.5371 24.0674 20.0919 23.7928 19.8173ZM20.3125 22.3032L16.2575 18.2482C15.9829 17.9736 15.5376 17.9736 15.2631 18.2482L13.5816 19.9297L10.4086 10.4106L19.9277 13.5836L18.2462 15.2651C17.9719 15.5394 17.9716 15.9849 18.2462 16.2595L22.3013 20.3145L20.3125 22.3032Z"
          fill="#111826"
        />
        <path
          d="M9.14062 5.625C9.52894 5.625 9.84375 5.31019 9.84375 4.92188V0.703125C9.84375 0.314812 9.52894 0 9.14062 0C8.75231 0 8.4375 0.314812 8.4375 0.703125V4.92188C8.4375 5.31019 8.75231 5.625 9.14062 5.625Z"
          fill="#111826"
        />
        <path
          d="M3.67296 2.67665C3.39841 2.40205 2.9532 2.40205 2.6786 2.67665C2.40401 2.95124 2.40401 3.39641 2.6786 3.67101L5.66173 6.65413C5.93627 6.92873 6.38154 6.92873 6.65609 6.65413C6.93068 6.37954 6.93068 5.93437 6.65609 5.65977L3.67296 2.67665Z"
          fill="#111826"
        />
        <path
          d="M5.66173 11.6259L2.6786 14.609C2.40401 14.8836 2.40401 15.3288 2.6786 15.6034C2.95315 15.8779 3.39841 15.8779 3.67296 15.6034L6.65609 12.6202C6.93068 12.3456 6.93068 11.9005 6.65609 11.6259C6.38149 11.3513 5.93627 11.3513 5.66173 11.6259Z"
          fill="#111826"
        />
        <path
          d="M12.6183 6.65413L15.6014 3.67101C15.876 3.39641 15.876 2.95124 15.6014 2.67665C15.3269 2.40205 14.8816 2.40205 14.607 2.67665L11.6239 5.65977C11.3493 5.93437 11.3493 6.37954 11.6239 6.65413C11.8985 6.92873 12.3437 6.92873 12.6183 6.65413Z"
          fill="#111826"
        />
        <path
          d="M5.625 9.14062C5.625 8.75231 5.31019 8.4375 4.92188 8.4375H0.703125C0.314812 8.4375 0 8.75231 0 9.14062C0 9.52894 0.314812 9.84375 0.703125 9.84375H4.92188C5.31019 9.84375 5.625 9.52894 5.625 9.14062Z"
          fill="#111826"
        />
      </g>
      <defs>
        <clipPath id="clip0_1553_56947">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
