import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const FaqView: IView = {
  key: 'view.user.faq',
  title: 'faqView.title',
  view: React.lazy(() => import('./FAQ-view')),
  roles: [IRoles.ADMINISTRATOR, IRoles.BUDDY, IRoles.HR, IRoles.SUPERIOR, IRoles.EMPLOYEE, IRoles.NEWEMPLOYEE],
  navigation: true,
  path: '/faq',
};

export default FaqView;
