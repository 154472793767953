import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewSettings: IView = {
  key: 'viewSettings',
  title: 'common parts',
  roles: [IRoles.ADMINISTRATOR],
  view: React.lazy(() => import('./main')),
  path: '/common-parts',
};

export default ViewSettings;
