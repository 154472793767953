import { IDefaultInteface, IPagination, IResponse, IResponseWithPagination } from 'interfaces/common';
import { IFiles } from 'interfaces/files.interface';
import appService from 'services/common';

const getFiles = (params?: object) => {
  return appService.get<IResponse<IResponseWithPagination<IFiles[]>>>('files-manage', {
    params,
  });
};
const deleteFile = (id: string) => {
  return appService.delete<IResponse<IResponseWithPagination<IFiles>>>('files-manage/' + id);
};

const getTagFiles = (params?: object) => {
  return appService.get<IResponse<IDefaultInteface[]>>('files-manage/tags', {
    params,
  });
};

const postFile = (data: FormData) => {
  return appService.post<IResponse<string>>('/files-manage/automatically-tagged', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export { getFiles, postFile, getTagFiles, deleteFile };
