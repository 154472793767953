import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import { IUserOnboardingData } from 'interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import { TableArchiveUserActions } from 'views/Settings/views/Register/components/users-table-view/users-table-view';
import { IRoles } from 'interfaces/roles.interface';
import LockIcon from '@mui/icons-material/Lock';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { EditIcon } from 'assets/icons';
import { format } from 'date-fns';
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
interface Props {
  data: IUserOnboardingData[];
  tableAction: (data: IUserOnboardingData, name: TableArchiveUserActions) => void;
}
export const useCreateRegisterUserTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const columns: ITableColumn[] = [
    {
      id: 'userFirstName',
      width: 30,
      label: t('users.table.fullName'),
      hasFilter: true,
    },
    {
      id: 'userUserName',
      width: 30,
      label: t('users.table.email'),
      hasFilter: true,
    },
    {
      id: 'isBlocked',
      width: 20,
      label: t('users.table.status'),
      hasFilter: true,
    },
    {
      width: 20,
      label: t('users.table.roles'),
    },
    {
      width: 20,
      label: t('users.table.createdDate'),
    },
    {
      width: 20,
      label: t('users.table.lastLoginDate'),
    },
    {
      width: 10,
      label: t('users.table.actions'),
    },
    {},
  ];
  const toggleUserActiveStatus = (item: IUserOnboardingData) => (
    <Chip
      label={item.isBlocked ? t('users.table.blocked') : t('users.table.active')}
      sx={{
        bgcolor: item.isBlocked ? 'error.light' : 'success.light',
      }}
    />
  );
  const convertDataToRows = (data: IUserOnboardingData[]) => {
    const tempRows: any = [];
    data.forEach((item: IUserOnboardingData) => {
      tempRows.push({
        id: item.userId,
        elements: [
          item.userFirstName && item.userLastName && (
            <Typography>{`${item.userFirstName} ${item.userLastName}`}</Typography>
          ),
          <Typography>{item.userUserName}</Typography>,
          toggleUserActiveStatus(item),
          <>
            <Typography variant="subtitle1">
              {item.roles ? (
                item.roles.length > 0 ? (
                  item.roles.map((role, i) => <Typography key={i}>{t('roles.' + role.roleName)}</Typography>)
                ) : (
                  t('users.table.noRole')
                )
              ) : (
                <></>
              )}
            </Typography>
          </>,
          <Typography>
            {format(new Date(item.created), 'dd.MM.yyyy HH:mm:ss')}
          </Typography>,
          <Typography>
            {format(new Date(item.lastLogin), 'dd.MM.yyyy HH:mm:ss')}
          </Typography>,
          <Stack direction="row" spacing={2}>
            <Tooltip title={`${t('registerView.tableActions.changeName')}`}>
              <IconButton onClick={() => tableAction(item, TableArchiveUserActions.CHANGE_NAME)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                item.roles.some((role) => role.roleId === IRoles.CANDIDATE || role.roleId === IRoles.NEWEMPLOYEE)
                  ? `${t('registerView.tableActions.inactiveUserText')}`
                  : `${t('registerView.tableActions.editRole')}`
              }
            >
              <span>
                <IconButton
                  onClick={() => tableAction(item, TableArchiveUserActions.EDIT_ROLE)}
                  disabled={item.roles.some(
                    (role) => role.roleId === IRoles.CANDIDATE || role.roleId === IRoles.NEWEMPLOYEE
                  )}
                >
                  <CachedOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={`${t('registerView.tableActions.mailChange')}`}>
              <IconButton onClick={() => tableAction(item, TableArchiveUserActions.CHANGE_EMAIL)}>
                <EmailOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={`${t('registerView.tableActions.archiveUser')}`}>
              <IconButton onClick={() => tableAction(item, TableArchiveUserActions.ARCHIVE)}>
                <LockIcon />
              </IconButton>
            </Tooltip>
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
