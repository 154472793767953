import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserSingle: IView = {
  key: 'viewUserSingle',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./single-user-page')),
  path: '/pracownicy/:id',
};

export default ViewUserSingle;
