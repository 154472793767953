import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.5534 16.7722C12.4784 16.8468 12.3919 16.903 12.2996 16.941C12.2073 16.979 12.1061 17 12 17C11.7997 17 11.5994 16.9241 11.4466 16.7722L6.22922 11.587C5.92359 11.2833 5.92359 10.7908 6.22922 10.4871C6.53485 10.1833 7.03037 10.1833 7.336 10.4871L11.2174 14.3445L11.2174 4.77778C11.2174 4.34822 11.5678 4 12 4C12.4322 4 12.7826 4.34822 12.7826 4.77778L12.7826 14.3445L16.664 10.4871C16.9696 10.1833 17.4652 10.1833 17.7708 10.4871C18.0764 10.7908 18.0764 11.2833 17.7708 11.587L12.5534 16.7722Z"
        fill="white"
      />
      <path
        d="M18.2222 20C18.6518 20 19 19.6642 19 19.25C19 18.8358 18.6518 18.5 18.2222 18.5H5.77778C5.34822 18.5 5 18.8358 5 19.25C5 19.6642 5.34822 20 5.77778 20H18.2222Z"
        fill="white"
      />
    </SvgIcon>
  );
};
