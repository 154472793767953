import { Box, Fade } from '@mui/material';
import Lottie from 'react-lottie';
import loaderAnimation from 'assets/animations/loaderAnimation.json';

interface Props {
  open: boolean;
  text?: string;
}
export const Loader = ({ open, text }: Props) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Fade in={open}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#1118268a',
          zIndex: 2000,
        }}
      >
        {text}
        <Lottie isClickToPauseDisabled options={animationOptions} height={100} width={100} />
      </Box>
    </Fade>
  );
};
