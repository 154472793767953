import { CardMedia, Theme, Box } from '@mui/material';
import React from 'react';
import EditIcon from 'assets/icons/EditIcon.svg';
interface IProfileAvatarProps {
  avatarUrl: string;
}
export const ProfileAvatar = ({ avatarUrl }: IProfileAvatarProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover > div, &:hover > img.MuiBox-root': {
          visibility: 'visible',
        },
      }}
    >
      <CardMedia
        component="img"
        image={avatarUrl}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '50%',
        }}
      />
      <Box
        sx={(theme: Theme) => ({
          width: 120,
          height: 120,
          bgcolor: theme.palette.grey[900],
          opacity: 0.6,
          top: '50%',
          left: '48%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          visibility: 'hidden',
        })}
      />
      <Box
        sx={{
          width: 43,
          height: 43,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          visibility: 'hidden',
        }}
        component="img"
        src={EditIcon}
      />
    </Box>
  );
};
