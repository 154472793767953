import React, { useEffect, useLayoutEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useViews } from 'hooks/useView';
import viewsApp from 'views';
import { Stack } from '@mui/material';
import { IRoles } from 'interfaces/roles.interface';
import { defaultConfig } from 'config/config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectUser } from 'store/user/userSlice';
import { StatusProgress } from 'interfaces/user.interface';

const RouterViews: React.FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { details } = useAppSelector(selectUser);

  const { views } = useViews(viewsApp);
  const routeElements = useRoutes(views);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === '/auth' && location.search.indexOf('token') > -1) {
      localStorage.removeItem(defaultConfig.KEY_TOKEN);
      localStorage.setItem(defaultConfig.KEY_TOKEN, location.search.replace('?token=', ''));
    }
    if (Object.keys(details.extraDetails).length) {
      if (location.pathname === '/' && details.roles?.find((role) => role.id === IRoles.NEWEMPLOYEE)) {
        navigate('/zadania');
      }
    }

    if (
      location.pathname === '/' &&
      details.roles?.find((role) => role.id === IRoles.HR || role.id === IRoles.ADMINISTRATOR)
    ) {
      navigate('/pracownicy');
    }
  }, [location.pathname, details]);
  useLayoutEffect(() => {
    if (Object.keys(details.extraDetails).length) {
      if (location.pathname === '/' && details.roles?.find((role) => role.id === IRoles.NEWEMPLOYEE)) {
        navigate('/zadania');
      }
    }

    if (
      location.pathname === '/' &&
      details.roles?.find((role) => role.id === IRoles.HR || role.id === IRoles.ADMINISTRATOR)
    ) {
      navigate('/pracownicy');
    }
  }, [auth, details]);

  return <Stack sx={{ minHeight: 'calc(100vh - 542px)' }}>{routeElements}</Stack>;
};

export default RouterViews;
