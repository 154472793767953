import React, { useEffect, useState } from 'react';
import { Chip, SxProps, Theme } from '@mui/material';
import { ITags } from 'interfaces/knowledge-page.interface';
interface IFilterChipProps {
  id: string;
  name: string;
  sx?: SxProps<Theme>;
  active?: boolean;
  requiredChoice?: boolean;
  onRemove?: (event: any) => void;
  setTags?: (value: ITags, name: string) => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const FilterChips = ({
  id,
  name,
  requiredChoice = false,
  active = false,
  setTags,
  onRemove,
  sx = [],
}: IFilterChipProps) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  useEffect(() => {
    setIsActive(active);
  }, [active]);
  const handleChipClick = () => {
    if (active && requiredChoice) {
      return;
    }
    if (setTags) {
      setTags(
        {
          id,
          name,
        },
        'tags'
      );
    }
    setIsActive(active && requiredChoice ? active : !isActive);
  };
  return (
    <Chip
      label={name}
      onDelete={onRemove}
      clickable={isActive && requiredChoice ? false : true}
      sx={[
        (theme: Theme) => ({
          bgcolor: isActive ? theme.palette.primary.main : theme.palette.grey[200],
          color: isActive ? 'white' : 'inherit',
          fontSize: '12px',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleChipClick}
    />
  );
};
