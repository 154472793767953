import React from 'react';
// import { MyTablePaginationType } from './interfaces';
import TablePagination from '@mui/material/TablePagination';

export interface ICustomTablePaginationProps {
  size: number;
  page: number;
  recordsCount: number;
  onPageChange: (param: number) => void;
  onRowsPerPageChange: (param: number) => void;
}

export const CustomTablePagination = (props: ICustomTablePaginationProps) => {
  const { recordsCount, page, size, onPageChange, onRowsPerPageChange } = props;

  return (
    <TablePagination
      component="div"
      count={recordsCount}
      page={(page ?? 1) - 1}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onPageChange={(event: any, newPage: number) => onPageChange(newPage)}
      rowsPerPage={size}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onRowsPerPageChange={(event: any) => onRowsPerPageChange(parseInt(event.target.value, 10))}
      labelRowsPerPage={'Wierszy na stronę'}
      labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
    />
  );
};
