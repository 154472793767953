import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './currentPageInitial';

export interface CurrentPageState {
  name: string;
}

export const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    change: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
});

export const { change } = currentPageSlice.actions;

export const selectCount = (state: RootState) => state.currentPage.name;

export default currentPageSlice.reducer;
