import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewTasksManage: IView = {
  key: 'viewTasksPreview',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./task-preview')),
  path: '/tasks-manage/preview/:id',
};

export default ViewTasksManage;
