import { IKnowledgePagePlain } from 'interfaces/knowledge-page.interface';
import appService from 'services/common';

export const getSingleKnowledgePage = async (id: string) => {
  try {
    const response = await appService.get(`/knowledge-pages-manage/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteSingleKnowledgePage = async (id: string) => {
  try {
    await appService.delete(`/knowledge-pages-manage/${id}`);
  } catch (error) {
    return error;
  }
};
export const postKnowledgePage = async (knowledgePage: IKnowledgePagePlain) => {
  try {
    await appService.post('/knowledge-pages-manage', {
      ...knowledgePage,
    });
  } catch (error) {
    return error;
  }
};
export const editKnowledgePage = async (id: string, knowledgePage: IKnowledgePagePlain) => {
  try {
    await appService.put(`/knowledge-pages-manage/${id}`, {
      ...knowledgePage,
    });
  } catch (error) {
    return error;
  }
};

export const getSingleKnowledgePageById = async (id: string) => {
  try {
    const response = await appService.get(`/knowledge-pages/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
