import { onboardingResourcesState } from './onboardingResourcesSlice';

export const initialState: onboardingResourcesState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 100,
  },
};
