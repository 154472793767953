import { styled } from '@mui/material/styles';
import { Grid, Typography, Theme } from '@mui/material';
import * as React from 'react';
import parse from 'html-react-parser';

interface ContactWidgetProps {
  widget: any;
}

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Header = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const LeftColumn = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  color: 'white',
}));

export const ContactWidget: React.FC<ContactWidgetProps> = ({ widget }) => {
  return (
    <Root>
      <Header variant="h4">{widget.header}</Header>
      <Grid container spacing={10}>
        <LeftColumn item xs={12} sm={6}>
          {Object.values(widget.column_1).map((row: string | unknown) => {
            if (typeof row === 'string') {
              return (
                <Typography
                  sx={(theme: Theme) => ({ fontSize: { xs: '0.8em', md: '0.6em' }, '>a': { color: 'white', textDecoration: 'none' },
                  [theme.breakpoints.down('md')]: {
                    my: '10px',
                  },
                  })}
                  variant="body1"
                >
                  {parse(row ?? '')}
                </Typography>
              );
            }
          })}
        </LeftColumn>
        <Grid item xs={12} sm={6}>
          {Object.values(widget.column_2).map((row: string | unknown) => {
            if (typeof row === 'string') {
              return (
                <Typography
                  sx={(theme: Theme) => ({ fontSize: { xs: '0.8em', md: '0.6em' }, '>a': { color: 'white', textDecoration: 'none' },
                  [theme.breakpoints.down('md')]: {
                    my: '10px',
                  },
                  })}
                  variant="body1"
                >
                  {parse(row ?? '')}
                </Typography>
              );
            }
          })}
        </Grid>
      </Grid>
    </Root>
  );
};
