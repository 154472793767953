import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RedDotIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 10 10">
      <circle cx="5" cy="5" r="5" fill="#FF0E4F" />
    </SvgIcon>
  );
};
