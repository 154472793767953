import React from 'react';
import { CardMedia, CardActions, Button, Theme, Typography } from '@mui/material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import RedFailIcon from 'assets/icons/RedFailIcon.svg';
interface IProps {
  onUpload?: any;
}
export const FileFailedCard: React.FC<IProps> = ({ onUpload }) => {
  return (
    <>
      <CardMedia
        component="img"
        image={RedFailIcon}
        alt="random icon"
        sx={(theme: Theme) => ({
          mb: theme.spacing(4),
          width: 92,
          height: 92,
        })}
      />
      <Typography variant="subtitle1">Błąd ładowania dokumentów</Typography>
      <CardActions
        sx={{
          p: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Button
          fullWidth
          sx={{ borderRadius: 0, height: 74, fontSize: 16 }}
          variant="contained"
          color="error"
          onClick={() => onUpload()}
          startIcon={<AddSharpIcon />}
        >
          Popraw dokument
        </Button>
      </CardActions>
    </>
  );
};
