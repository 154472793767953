import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Theme, Typography } from '@mui/material';
import { UserProfileIcon } from 'assets/icons';
import { centerFlex } from 'common-styles/common-styles';
import { appService } from 'services';

interface IProps {
  defaultValue?: string;
  value?: string;
  onChange?: (file: File) => void;
}

export const ImageUploader: React.FC<IProps> = ({ defaultValue, value, onChange }) => {
  const inputUploader = useRef<HTMLInputElement>(null);
  const [media, setMedia] = useState<string | null>(defaultValue ?? null);

  const showImage = (event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (onChange) {
      onChange(event.target.files[0]);
    }
    reader.addEventListener('load', (e) => {
      setMedia(reader.result as string);
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setMedia(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box sx={{ ...centerFlex, flexDirection: 'column' }}>
      <Button
        sx={(theme: Theme) => ({
          width: '100%',
          margin: `0 auto ${theme.spacing(2)}`,
          background: theme.palette.primary.main,
          textAlign: 'center',
          padding: theme.spacing(5),
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 0,
        })}
        variant="contained"
        onClick={() => {
          if (inputUploader.current) {
            inputUploader.current.click();
          }
        }}
      >
        <Box
          sx={(theme) => ({
            borderRadius: '100%',
            overflow: 'hidden',
            mb: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
          })}
        >
          {media === null ? (
            <UserProfileIcon
              viewBox="0 0 20 20"
              sx={(theme) => ({
                width: '90px',
                height: '90px',
                m: theme.spacing(2),
                fill: 'white',
                display: 'block',
                '& path': {},
              })}
            />
          ) : (
            <Box
              component={'img'}
              src={media}
              sx={(theme) => ({ maxWidth: '100%', display: 'block' })}
              alt="Twój awatar"
            />
          )}
        </Box>
        <Typography color={'white'} mb={(theme) => theme.spacing(5)} variant="body2">
          Dodaj swoje zdjęcie
          <br /> profilowe (max 2 MB)
        </Typography>
        <Typography
          sx={(theme) => ({
            borderRadius: theme.spacing(3),
            color: theme.palette.secondary.dark,
            padding: theme.spacing(1.5, 3),
            background: theme.palette.common.white,
          })}
        >
          Wgraj zdjęcie z dysku
        </Typography>
        <input accept="image/*" id="file-uploader" hidden ref={inputUploader} onChange={showImage} type="file" />
      </Button>
    </Box>
  );
};
