import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IPagination, IResponseWithPagination } from 'interfaces/common';
import { ITask } from 'interfaces/tasks.interface';
import { RootState } from 'store';
import { getUserTasks } from './userTasksAPI';
import { initialState } from './userTasksInitail';
export interface usersTasksState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<ITask[]>;
  currentRequestId?: string;
}

export const fetchUserTasks = createAsyncThunk('tasks/fetchUserTasks', async (pagination?: IPagination) => {
  try {
    const response = await getUserTasks(pagination);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const userTasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTasks.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUserTasks.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUserTasks.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectUserTasks = (state: RootState) => state.userTasks;
export default userTasksSlice.reducer;
