import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pl from './lang/defaults/pl.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources: {
      pl,
    },
    fallbackLng: 'pl',
  });

export default i18n;
