import { onboardingTasksState } from './onboardingTasksSlice';

export const initialState: onboardingTasksState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
};
