import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8116 16.7625C14.4141 17.8503 12.6573 18.4982 10.7491 18.4982C6.19325 18.4982 2.5 14.805 2.5 10.2491C2.5 5.69325 6.19325 2 10.7491 2C15.305 2 18.9982 5.69325 18.9982 10.2491C18.9982 12.1554 18.3516 13.9107 17.2657 15.3076L22.5036 20.5455L21.0491 22L15.8116 16.7625ZM17.4515 10.2491C17.4515 13.9507 14.4507 16.9515 10.7491 16.9515C7.04747 16.9515 4.04671 13.9507 4.04671 10.2491C4.04671 6.54747 7.04747 3.54671 10.7491 3.54671C14.4507 3.54671 17.4515 6.54747 17.4515 10.2491Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
