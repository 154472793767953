import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowChevronUp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.9844 15.5358L12.4994 7.05078L4.01437 15.5358L5.42837 16.9508L12.4994 9.87878L19.5704 16.9508L20.9844 15.5358Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
