import { useAppDispatch } from 'hooks';
import { SchemaField } from 'interfaces/schema-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSchema,
  fetchSchemaForm,
  fetchUserSchemaForm,
} from 'store/onboardingUserDetails/onboardingUserDetailsSlice';
interface IProps {
  isManage: boolean;
  category?: string;
}
export const useUserSchemaFields = ({ isManage = true, category = undefined }: IProps) => {
  const dispatch = useAppDispatch();
  const userSchemaManage = useSelector(selectSchema);
  // TODO: get userSchema for plain user and replace selector
  const userSchemaPlain = useSelector(selectSchema);
  const [selectedSchema, setSelectedSchema] = useState<SchemaField[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const fetchSchemaManage = async () => {
    try {
      dispatch(fetchSchemaForm());
    } catch (e) {
      console.log(e);
    }
  };
  const fetchSchemaPlain = async () => {
    try {
      dispatch(fetchUserSchemaForm());
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (isManage && !userSchemaManage.length) {
      fetchSchemaManage();
    }
    if (!isManage && !userSchemaPlain.length) {
      fetchSchemaPlain();
    }
  }, []);
  const filterSchemaFields = (userSchema: SchemaField[], category?: string) => {
    if (userSchema.length) {
      const filteredSchema = category ? userSchema.filter((schema) => schema.category === category) : userSchema;
      setSelectedSchema(filteredSchema);
      return filteredSchema;
    }
  };
  const getSchemaCategoryItems = (category: string) => {
    if (userSchemaManage.length) {
      return userSchemaManage.filter((schema) => schema.category === category);
    }
  };
  const filterCategories = (userSchema: SchemaField[]) => {
    const categories: string[] = [];
    for (const itemSchema of userSchema) {
      if (itemSchema.category && !categories.includes(itemSchema.category)) {
        categories.push(itemSchema.category);
      }
    }
    setCategories(
      categories.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })
    );
  };
  useEffect(() => {
    if (category) {
      isManage ? filterSchemaFields(userSchemaManage, category) : filterSchemaFields(userSchemaPlain, category);
    } else {
      isManage ? filterSchemaFields(userSchemaManage) : filterSchemaFields(userSchemaPlain);
    }
  }, [userSchemaManage, userSchemaPlain]);

  useEffect(() => {
    filterCategories(isManage ? userSchemaManage : userSchemaPlain);
  }, [selectedSchema]);

  return {
    schemaFormFields: selectedSchema.filter((item) => !item.isReadonly),
    getSchemaCategoryItems,
    categories,
  };
};
