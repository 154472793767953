import { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Container, Stack, Theme } from '@mui/material';
import { PageTitle } from 'components/layout';
import { CustomButton, Loader, SearchField } from 'components/common';
import { ButtonSize, ButtonType, IconName } from 'interfaces/button.interface';
import { reset } from 'store/onboardingUserTemplates/onboardingUserTemplatesSlice';
import { reset as detailsReset } from 'store/onboardingUserDetails/onboardingUserDetailsSlice';
import { useAppSelector } from 'hooks';
import { useCreateUserTableData } from 'hooks';
// import { RedExclamationIcon } from 'assets/icons';
import { selectUser } from 'store/user/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { IOnboardingUserListData, StatusProgress } from 'interfaces/user.interface';
import { useSnackbar } from 'notistack';
import { activateUser, getOnboardingUserList } from 'services';
import { useTranslation } from 'react-i18next';
import { TemplateState, TemplateViewState } from 'interfaces/templates.interface';
import { clearOnboardingUser } from 'store/onboardingUserDetails/onboardingUserDetailsSlice';
import { TabContext, TabPanel } from '@mui/lab';
import { CustomTable } from 'components';
import { IRoles } from 'interfaces/roles.interface';
import { Order } from 'interfaces/table.interface';
import { CustomTabs } from 'components/common/custom-tabs';
import { IPagination } from 'interfaces/common';
import { useDispatch } from 'react-redux';

export enum TableUserActions {
  ACTIVATE = 'activate',
  EDIT_ROLE = 'editrole',
  VIEW_TEMPLATE = 'viewtemplate',
  EDIT_TEMPLATE = 'edittemplate',
  FILTER = 'filter',
  VIEW_ASSIGNED_TASKS = 'assignedtasks',
}
export const userTabs = [
  {
    id: '4',
    name: 'All',
  },
  {
    id: '1',
    name: StatusProgress.PREONBOARDING,
  },
  {
    id: '2',
    name: StatusProgress.ONBOARDING,
  },
  {
    id: '3',
    name: StatusProgress.BOARDED,
  },
];
const UsersPageView = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sort, setSort] = useState<string>('fullName');
  const [asc, setAsc] = useState<Order>('asc');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<IOnboardingUserListData[]>([]);
  const profile = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<string>(userTabs[0].name);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    size: 10,
    recordCount: 0,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchValue('');
    setSearchTerm('');
    setNewPage(0);
    setTabValue(newValue);
  };

  const handleTableAction = (data: IOnboardingUserListData, name: TableUserActions) => {
    switch (name) {
      case TableUserActions.ACTIVATE:
        return toggleUserActivate(data);
      case TableUserActions.VIEW_TEMPLATE:
        dispatch(reset());
        dispatch(detailsReset());
        return navigate('/szablony/podglad/uzytkownik/' + data.userId, {
          state: {
            mode: TemplateViewState.VIEW_USER_TEMPLATE,
            name: data.fullName,
          },
        });
      case TableUserActions.EDIT_TEMPLATE:
        dispatch(reset());
        dispatch(detailsReset());
        return navigate('/szablony/edycja/uzytkownik/' + data.userId, {
          state: {
            mode: TemplateState.EDIT_USER,
            name: data.fullName,
          },
        });
      case TableUserActions.VIEW_ASSIGNED_TASKS:
        dispatch(reset());
        dispatch(detailsReset());
        return navigate('/onboarding/assigned-tasks/' + data.userId, {
          state: {
            mode: TemplateState.EDIT_USER,
          },
        });
      default:
        return null;
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = sort === property && asc === 'asc';
    setAsc(isAsc ? 'desc' : 'asc');
    setSort(String(property));
  };
  const { rows, columns } = useCreateUserTableData({
    data: users,
    tableAction: handleTableAction,
    roles: profile.details.roles ?? [],
    claims: profile.details.claims ?? [],
  });

  const toggleUserActivate = async (item: any) => {
    try {
      setIsLoading(true);
      const { isBlocked, userId } = item;
      const response: any = await activateUser(userId, !isBlocked);
      if (response.data.success) {
        fetchUsers();
      }
    } catch (error) {
      enqueueSnackbar(t('users.statusChangeError'), { variant: 'error' });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // pagination

  const setNewPage = (newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage + 1,
    });
  };
  const setNewPageSize = (pageSize: number) =>
    setPagination({
      ...pagination,
      size: pageSize,
    });

  const handleCreateUser = () => {
    if (profile.details) {
      dispatch(reset());
      dispatch(clearOnboardingUser());
      navigate(`/dodaj-uzytkownika/`, { state: null });
    }
  };

  useEffect(() => {
    reset();
  }, []);

  const debounceSearch = useCallback(
    _debounce((searchValue) => setSearchTerm(searchValue), 500),
    []
  );

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceSearch(event.target.value);
    setNewPage(0);
  };

  const {
    details: { roles, claims },
  } = profile;
  const isSuperior = useMemo(() => {
    if (roles) {
      return roles.some((role) => role.id === IRoles.SUPERIOR);
    }
  }, [roles]);
  useEffect(() => {
    fetchUsers();
  }, [sort, asc, searchTerm, pagination.page, pagination.size, tabValue]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      let response;
      if (tabValue === 'All') {
        response = await getOnboardingUserList({
          page: pagination.page,
          size: pagination.size,
          sort,
          term: searchTerm,
          asc: asc === 'asc' ? true : false,
          onboardingStatus: tabValue === 'All' ? undefined : tabValue,
        });
      } else {
        response = await getOnboardingUserList({
          page: pagination.page,
          size: pagination.size,
          sort,
          term: searchTerm,
          asc: asc === 'asc' ? true : false,
          onboardingStatus: tabValue === 'All' ? undefined : tabValue,
        });
      }
      if (response.data.success) {
        const { value } = response.data;
        setUsers(value.records);
        setPagination({
          page: value.page,
          size: value.size,
          recordCount: value.recordsCount,
        });
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container
      maxWidth="xl"
      sx={{
        mb: 15,
      }}
    >
      <Loader open={isLoading} />
      <PageTitle title={t('users.pageTitle')} />
      <Stack direction={{ md: 'row', xs: 'column' }} spacing={5} mb={3}>
        <SearchField placeholder={t('users.search')} value={searchValue} onChange={onChangeSearch} />
        {/* <CustomButton
          text="Filtruj"
          startIcon={IconName.FILTER}
          buttonType={ButtonType.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={handleFilters}
          sx={{
            height: 56,
            fontSize: '20px',
          }}
        /> */}
        {!isSuperior && (
          <CustomButton
            text={t('users.buttons.add')}
            startIcon={IconName.ADD_PLAIN}
            buttonType={ButtonType.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleCreateUser}
            sx={{
              height: 56,
              fontSize: '20px',
            }}
          />
        )}
      </Stack>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 5, borderColor: 'divider', mt: 10 }}>
          <CustomTabs
            value={tabValue}
            onChange={handleChange}
            tabs={userTabs.map(({ name, id }) => ({
              label: t(`users.table.tabs.${name}`),
              value: name,
            }))}
          />
        </Box>
        {userTabs.map(({ name }) => (
          <TabPanel value={name} key={name}>
            <CustomTable
              rows={rows}
              columns={columns}
              rowColspan={8}
              pagination={{
                page: pagination.page,
                size: pagination.size,
                recordsCount: pagination.recordCount ?? 0,
                onPageChange: setNewPage,
                onRowsPerPageChange: setNewPageSize,
              }}
              orderBy={sort}
              order={asc}
              onRequestSort={handleRequestSort}
            />
          </TabPanel>
        ))}
      </TabContext>
      <Box
        sx={(theme: Theme) => ({
          mt: theme.spacing(10),
        })}
      >
        {!isSuperior && (
          <CustomButton
            text={t('users.buttons.add')}
            startIcon={IconName.ADD_PLAIN}
            buttonType={ButtonType.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleCreateUser}
            sx={{
              mt: 5,
              height: 56,
              fontSize: '20px',
            }}
          />
        )}
      </Box>
    </Container>
  );
};

export default UsersPageView;
