import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, IconButton, Chip, Tooltip } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';

import { handleWierdDates } from 'utils';
import { StarIcon, TrashIcon } from 'assets/icons';
import { IUserTask, TaskStatusType } from 'interfaces/tasks.interface';
import { useDictionaries } from 'hooks/useDictionaries';
import { PlainUserInfo } from 'components/user-profile';
import { TaskStatusChip } from 'components/tables/task-table/components/task-status-chip';
import { isAfter } from 'date-fns';
interface Props {
  data: IUserTask[];
  tableAction: (data: IUserTask, name: 'delete') => void;
}

export const useCreateUserTasksTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const { getDictionaryValue } = useDictionaries();

  const columns: ITableColumn[] = [
    {
      id: 'name',
      width: 20,
      label: t('commonTable.name'),
    },
    {
      width: 10,
      label: t('commonTable.type'),
    },
    {
      id: 'assignee',
      width: 10,
      label: t('commonTable.employee'),
    },
    {
      width: 10,
      label: t('commonTable.whoVerifies'),
    },
    {
      width: 10,
      label: t('commonTable.fieldStatus'),
    },
    {
      id: 'points',
      width: 10,
      label: t('commonTable.points'),
    },
    {
      id: 'assignedOn',
      width: 10,
      label: t('commonTable.assignDate'),
    },
    {
      id: 'to',
      width: 10,
      label: t('commonTable.expiryDate'),
    },
    {
      width: 20,
      label: 'Akcje',
    },
    {},
  ];

  const convertDataToRows = (data: IUserTask[]) => {
    const tempRows: any = [];
    data.forEach((task: IUserTask) => {
      tempRows.push({
        id: task.id,
        elements: [
          <Typography variant="subtitle1">{task.name}</Typography>, // nazwa
          <Typography variant="subtitle1">{t(`tasks.taskCreation.category.${task.type}`)}</Typography>, // typ
          task.assignee ? (
            <PlainUserInfo
              tableCell
              name={`${task.assignee.firstName} ${task.assignee.lastName}`}
              avatar={task.assignee.avatarId ?? ''}
              profession={`${getDictionaryValue('PositionId', task.assignee?.positionId) ?? ''}`}
            />
          ) : (
            <Typography variant="subtitle1">{t('commonTable.noData')}</Typography>
          ), // pracownik

          task.acceptorInfo ? (
            task.acceptorInfo.acceptorFirstName ? (
              <PlainUserInfo
                tableCell
                name={`${task.acceptorInfo.acceptorFirstName} ${task.acceptorInfo.acceptorLastName}`}
                avatar={task.acceptorInfo.acceptorAvatarId ?? ''}
                profession={`${getDictionaryValue('PositionId', task.acceptorInfo?.acceptorPosition) ?? ''}`}
              />
            ) : (
              <Typography variant="subtitle1">{t('tasks.taskTable.noVerification')}</Typography>
            )
          ) : (
            <Typography variant="subtitle1">{t('commonTable.noData')}</Typography>
          ), //zatwierdzający
          <TaskStatusChip task={task} />,
          <Typography
            variant="subtitle1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            <StarIcon
              sx={(theme) => ({
                '& path': {
                  fill: theme.palette.grey[600],
                },
              })}
            />
            <Stack sx={{ position: 'relative', top: '2px' }}>{task.points}</Stack>
          </Typography>, // punkty
          <Typography variant="subtitle1">
            {task.assignedOn ? handleWierdDates(task.assignedOn, true) : '----'}
          </Typography>,
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) =>
                task.status !== TaskStatusType.NEW || isAfter(new Date(task.to), new Date())
                  ? theme.palette.grey[800]
                  : theme.palette.error.main,
            }}
          >
            {task.to ? handleWierdDates(task.to, true) : '----'}
          </Typography>,
          <Stack direction="row" spacing={2}>
            {' '}
            {/* akcje */}
            {(task.status === TaskStatusType.NEW ||
              task.status === TaskStatusType.CANCELLED ||
              task.status === TaskStatusType.SOON) && (
              <Tooltip title={`${t('taskStatus.table.deleteTooltip')}`}>
                <IconButton onClick={() => tableAction(task, 'delete')}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
