import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserQuiz: IView = {
  key: 'viewTasksUserQuiz',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.BUDDY, IRoles.HR, IRoles.SUPERIOR, IRoles.EMPLOYEE, IRoles.NEWEMPLOYEE],
  view: React.lazy(() => import('./quiz-view')),
  path: '/zadania/quiz/:id',
};

export default ViewUserQuiz;
