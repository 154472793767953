import { Menu, MenuItem, Typography, Theme } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAppSelector } from 'hooks';
import { selectUser } from 'store/user/userSlice';
import { GUAvatar } from 'components/common/gu-avatar';

import React, { useState } from 'react';
import { defaultConfig } from 'config/config';
import { API_URL } from 'config/const';

export const NavbarUserAvatar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const profile = useAppSelector(selectUser);

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAvatarMenu = () => {
    setAnchorEl(null);
  };
  const onSignout = () => {
    localStorage.removeItem(defaultConfig.KEY_TOKEN);
    window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
  };
  return (
    <>
      <GUAvatar
        alt="user"
        avatarId={profile.details.avatar}
        onClick={handleAvatarClick}
        sx={{
          cursor: 'pointer',
          display: { xs: 'none', md: 'block' },
        }}
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseAvatarMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            bgcolor: (theme: Theme) => theme.palette.grey[200],
            borderRadius: 0,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: (theme: Theme) => theme.palette.grey[200],
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <LockOutlinedIcon />
          <Typography variant="subtitle1" onClick={onSignout} sx={{ ml: 1 }}>
            Wyloguj
          </Typography>
        </MenuItem>
        {/* <MenuItem>
          <UserProfileIcon />
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Profil
          </Typography>
        </MenuItem> */}
      </Menu>
    </>
  );
};
