import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DocumentHatIcon, EditIcon, EyeIcon, TrashIcon } from 'assets/icons';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import { IKnowledgePage } from 'interfaces/knowledge-page.interface';
import { useTranslation } from 'react-i18next';
import { IResponseWithPagination } from 'interfaces/common';

interface Props {
  data: IResponseWithPagination<IKnowledgePage[]>;
  tableAction: (data: IKnowledgePage, name: 'edit' | 'preview' | 'delete') => void;
}

export const useCreateKnowledgePageTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const columns: ITableColumn[] = [
    {
      width: 80,
      label: t('knowledgePages.table.name'),
    },
    {
      width: 10,
      label: t('knowledgePages.table.status'),
      hasFilter: true,
      filterAction: () => console.log('filter status'),
    },
    {
      width: 10,
    },
  ];
  const handleChipColor = (status: string) => {
    switch (status) {
      case 'draft':
        return (
          <Chip
            label={t(`knowledgePages.table.${status}`)}
            sx={{
              fontSize: '12px',
              bgcolor: 'rgba(255, 190, 0, 0.15)',
            }}
          />
        );
      case 'ready':
        return (
          <Chip
            label={t(`knowledgePages.table.${status}`)}
            sx={{
              fontSize: '12px',
              bgcolor: 'rgba(67, 191, 136, 0.15)',
            }}
          />
        );
      case 'hidden':
        return (
          <Chip
            label={t(`knowledgePages.table.${status}`)}
            sx={{
              fontSize: '12px',
              bgcolor: 'rgba(0, 74, 181, 0.1)',
            }}
          />
        );
      default:
        return (
          <Chip
            label={t(`knowledgePages.table.${status}`)}
            sx={{
              fontSize: '12px',
              bgcolor: 'rgba(255, 190, 0, 0.15)',
            }}
          />
        );
    }
  };

  const convertDataToRows = (data: IKnowledgePage[]) => {
    const tempRows: ITableRow[] = [];
    data.forEach((item: IKnowledgePage) => {
      tempRows.push({
        id: item.id ?? '',
        elements: [
          <Stack direction="row" spacing={2} alignItems="center">
            <DocumentHatIcon />
            <Typography variant="subtitle1">{item.name}</Typography>
          </Stack>,
          handleChipColor(item.editionStatus),
          <Stack direction="row" spacing={2}>
            <Tooltip title={'Edytuj bazę wiedzy'}>
              <IconButton onClick={() => tableAction(item, 'edit')}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Podgląd bazy wiedzy'}>
              <IconButton onClick={() => tableAction(item, 'preview')}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Usuń bazę wiedzy'}>
              <IconButton onClick={() => tableAction(item, 'delete')}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    if (Array.isArray(data.records)) {
      convertDataToRows(data.records);
    }
  }, [data]);
  return {
    rows,
    columns,
    pagination: {
      page: data.page,
      recordsCount: data.recordsCount,
      size: data.size,
    },
  };
};
