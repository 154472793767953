import { Box, Typography } from '@mui/material';
import { IFileType } from './data-uploader/media-uploader';
import { YouTubeFrame } from './youtube-frame';

interface IProps {
  type: IFileType;
  src: string;
}

export const MediaCustom: React.FC<IProps> = ({ type, src }) => {
  return (
    <Box width={'100%'} mb={(theme) => theme.spacing(2)}>
      {type === IFileType.IMAGE ? (
        <Box component="img" maxWidth={'100%'} src={src} />
      ) : type === IFileType.VIDEO ? (
        <Box component="video" controls width={'100%'} src={src} />
      ) : type === IFileType.YOUTUBE ? (
        <YouTubeFrame url={src} />
      ) : (
        <Typography sx={(theme) => ({ padding: theme.spacing(10, 0, 5), textAlign: 'center' })}>
          Plik nie jest obsługiwany
        </Typography>
      )}
    </Box>
  );
};
