import { Box, Badge, Typography } from '@mui/material';

export interface ITabFilterProps {
  itemCount: number;
  title: string;
  activeItem: string;
  itemName: string;
  handleClick: (name: string) => void;
}
export const TabFilterItem = ({ itemCount, title, activeItem, itemName, handleClick }: ITabFilterProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: 'fit-content',
        cursor: 'pointer',
      }}
      onClick={() => handleClick(itemName)}
    >
      <Badge
        badgeContent={itemCount}
        sx={{
          '& .MuiBadge-badge': {
            right: '-5px',
            color: 'red',
          },
        }}
      >
        <Typography variant="body1" sx={{ mb: '10px' }}>
          {title}
        </Typography>
      </Badge>
      {activeItem === itemName && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: 1,
            height: 4,
            bgcolor: 'secondary.main',
          }}
        />
      )}
    </Box>
  );
};
