import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const SchemaAddView: IView = {
  key: 'viewSchemaAdd',
  title: 'Dodawanie pola',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./edit-schema')),
  path: ['/dodaj-pole', '/edytuj-pole/:id'],
};

export default SchemaAddView;
