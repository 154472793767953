import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewTasksAddManage: IView = {
  key: 'viewAddTasksManage',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./create-task-view')),
  navigation: true,
  path: ['/tasks-manage/:id', '/tasks-manage/new-task'],
};

export default ViewTasksAddManage;
