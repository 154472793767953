import appService from 'services/common';

const getKnowledgePagesAPI = (params?: object) => {
  return appService.get('/knowledge-pages-manage', { params });
};
const getKnowledgePagesTagsAPI = (params?: object) => {
  return appService.get('/knowledge-pages/tags', { params });
};
const postKnowledgePagesTagAPI = (name: string) => {
  return appService.post('/knowledge-pages-manage/tags', {
    name,
  });
};
const deleteKnowledgePagesTagAPI = (id: string) => {
  return appService.delete('/knowledge-pages-manage/tags/' + id);
};

export { getKnowledgePagesAPI, getKnowledgePagesTagsAPI, postKnowledgePagesTagAPI, deleteKnowledgePagesTagAPI };
