import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const KnowledgeViewCreatePage: IView = {
  key: 'viewKnowledgeCreatePage',
  title: 'knowledgePages.createPage.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./create-page-view')),
  path: ['/knowledge-base-manage/new-page', '/knowledge-base-manage/:id'],
};

export default KnowledgeViewCreatePage;
