import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const UserProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 3.125C8.50293 3.125 7.29036 4.33756 7.29036 5.83333C7.29036 7.3291 8.50293 8.54167 9.9987 8.54167C11.4945 8.54167 12.707 7.3291 12.707 5.83333C12.707 4.33756 11.4945 3.125 9.9987 3.125ZM6.04036 5.83333C6.04036 3.64721 7.81257 1.875 9.9987 1.875C12.1848 1.875 13.957 3.64721 13.957 5.83333C13.957 8.01946 12.1848 9.79167 9.9987 9.79167C7.81257 9.79167 6.04036 8.01946 6.04036 5.83333ZM2.70703 15.8333C2.70703 13.6472 4.47924 11.875 6.66536 11.875H13.332C15.5182 11.875 17.2904 13.6472 17.2904 15.8333V17.5C17.2904 17.8452 17.0105 18.125 16.6654 18.125C16.3202 18.125 16.0404 17.8452 16.0404 17.5V15.8333C16.0404 14.3376 14.8278 13.125 13.332 13.125H6.66536C5.16959 13.125 3.95703 14.3376 3.95703 15.8333V17.5C3.95703 17.8452 3.67721 18.125 3.33203 18.125C2.98685 18.125 2.70703 17.8452 2.70703 17.5V15.8333Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
