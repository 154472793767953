import { createAsyncThunk, createSlice, SerializedError, createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './knowledgePagesInitial';
import {
  deleteKnowledgePagesTagAPI,
  getKnowledgePagesAPI,
  getKnowledgePagesTagsAPI,
  postKnowledgePagesTagAPI,
} from './knowledgePagesAPI';
import { IKnowledgePage, ITags } from 'interfaces/knowledge-page.interface';
import { IResponseWithPagination } from 'interfaces/common';

export interface knowledgePagesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: {
    knowledgePages: IResponseWithPagination<IKnowledgePage[]>;
    tags: ITags[];
  };
  currentRequestId?: string;
}

export const fetchKnowledgePagesList = createAsyncThunk('knowledgePages/fetch', async (params?: object) => {
  try {
    const pagesResponse = await getKnowledgePagesAPI(params);
    const tagsResponse = await getKnowledgePagesTagsAPI();
    return {
      knowledgePages: pagesResponse.data.value,
      tags: tagsResponse.data.value,
    };
  } catch (err) {
    return err;
  }
});

export const fetchTags = createAsyncThunk('tags/fetch', async () => {
  try {
    const tagsResponse = await getKnowledgePagesTagsAPI();
    return tagsResponse.data.value;
  } catch (err) {
    return err;
  }
});
export const postKnowledgePageTag = createAsyncThunk(
  'knowledgePages/addTag',
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await postKnowledgePagesTagAPI(name);
      return response.data.value;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteKnowledgePageTag = createAsyncThunk(
  'knowledgePages/deleteTag',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteKnowledgePagesTagAPI(id);
      return id;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const knowledgePagesSlice = createSlice({
  name: 'knowledgePages',
  initialState,
  reducers: {
    removeArticle: (state, action) => {
      state.data.knowledgePages.records = state.data.knowledgePages.records.filter(
        (page) => page.id !== action.payload
      );
    },
    newPage: (state, action) => {
      state.data.knowledgePages.page = action.payload + 1;
    },
    prevPage: (state, action) => {
      state.data.knowledgePages.page = action.payload - 1;
    },
    newPageSize: (state, action) => {
      state.data.knowledgePages.size = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKnowledgePagesList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchKnowledgePagesList.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchKnowledgePagesList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchTags.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchTags.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.tags = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchTags.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postKnowledgePageTag.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postKnowledgePageTag.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.tags = [...state.data.tags, { ...action.payload }];
          state.currentRequestId = undefined;
        }
      })
      .addCase(postKnowledgePageTag.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteKnowledgePageTag.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteKnowledgePageTag.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.tags = state.data.tags.filter((tag) => tag.id !== action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteKnowledgePageTag.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectKnowledgePagesList = (state: RootState) => state.knowledgePagesList;
export const selectTagList = (state: RootState) => state.knowledgePagesList.data.tags;

export default knowledgePagesSlice.reducer;
