import { IChangeUserEmail, IMainUserData } from 'interfaces/user.interface';
import appService from 'services/common';

export const getUserOnboardingData = async (userId: string) => {
  return await appService.get(`/onboarding-user-details-manage/${userId}`);
};
export const getUserOnboardingAdditionalData = async (userId: string) => {
  return await appService.get(`/onboarding-user-details-manage/${userId}/additional-info`);
};
export const getOnboardingUserList = async (params?: object) => {
  return await appService.get('/onboarding-user-details-manage/employees-list', { params });
};

export const fetchAvatar = async (id?: string) => {
  try {
    if (!id || id === '00000000-0000-0000-0000-000000000000') throw new Error('Missing avatar id');
    const response = await appService.get<Blob>(`/users/${id}/avatar`, { responseType: 'blob' });
    return URL.createObjectURL(response.data);
  } catch (e) {
    return '/static/img/empty_avatar.png';
  }
};

export const activateUser = async (userId: string, activate: boolean) => {
  try {
    return appService.post('/users-manage/activate', {
      activateAll: false,
      userIds: [userId],
      groupIds: [],
      isBlocked: activate,
    });
  } catch (error) {
    return error;
  }
};

export const assignBuddyToUserById = async (userId: string, buddyId: string) => {
  return await appService.post(`/onboarding-user-details-manage/${userId}/assign/buddy/${buddyId}`);
};
export const assignSuperiorToUserById = async (userId: string, superiorId: string) => {
  return await appService.post(`/onboarding-user-details-manage/${userId}/assign/superior/${superiorId}`);
};

// TODO: Jak zostaną dodane odznaki w tym endpoincie to należy dodać do requesta
export const finishOnboardingByUserId = async (userId: string, comment?: string, badgeIds?: string[]) => {
  return await appService.post('/onboarding-finish-manage', {
    userId,
    opinionFromSuperior: comment,
    badgesIds: [],
  });
};

export const giveOpinionToUserById = async (userId: string, comment?: string, badgeIds?: string[]) => {
  return await appService.post('/onboarding-opinion/add', {
    opinionText: comment,
    userIdForOpinion: userId,
    // badgeIds
  });
};

export const archiveUser = async (userId: string) => {
  return await appService.put(`/users-manage/${userId}/archive`);
};

export const getArchivedUsers = async (params?: object) => {
  return await appService.get('users-manage/archive', { params });
};
export const changeUserEmail = async (userId: string, data: IChangeUserEmail) => {
  return await appService.put(`/users-manage/${userId}/change-email`, data);
};
export const changeUserName = async (userId: string, data: IMainUserData) => {
  return await appService.put(`/users-manage/${userId}/changeUserName`, data);
};
