import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, IconButton, Chip, Tooltip } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { EditIcon, EyeIcon, SmallArrowPoint, StarIcon, TrashIcon } from 'assets/icons';
import { ITasks, PriorityType } from 'interfaces/tasks.interface';
import { useDictionaries } from 'hooks/useDictionaries';
import { useAppSelector } from 'hooks/useStore';
import { selectUser } from 'store/user/userSlice';
import { IRoles } from 'interfaces/roles.interface';
import { PlainUserInfo } from 'components/user-profile';
interface Props {
  data: ITasks[];
  tableAction: (data: ITasks, name: 'edit' | 'preview' | 'delete' | 'assign') => void;
}
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
export const useCreateTasksTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const { getDictionaryValue } = useDictionaries();
  const {
    details: { roles, id },
  } = useAppSelector(selectUser);
  const columns: ITableColumn[] = [
    {
      width: 20,
      label: t('tasks.taskTable.task'),
      hasFilter: false,
      filterAction: () => console.log('filter name'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.forWho'),
      hasFilter: false,
      // filterAction: () => console.log('filter'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.position'),
      hasFilter: false,
      // filterAction: () => console.log('filter supervisor'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.taskType'),
      hasFilter: false,
      filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.type'),
      hasFilter: false,
      filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.points'),
      hasFilter: false,
      // filterAction: () => console.log('filter buddy'),
    },
    {
      width: 10,
      label: t('tasks.taskTable.lastModified'),
    },
    {
      width: 20,
      // label: 'Akcja',
    },
    {},
  ];

  const handlePriorityType = (type: PriorityType) => {
    switch (type) {
      case PriorityType.OBLIGATORY:
        return <Chip label={t('tasks.taskCreation.priorityType.obligatory')} sx={{ bgcolor: '#FFBE0026' }} />;
      case PriorityType.PRIORITY:
        return <Chip label={t('tasks.taskCreation.priorityType.priority')} sx={{ bgcolor: '#FF0E4F33' }} />;
      case PriorityType.VOLUNTEERS:
        return <Chip label={t('tasks.taskCreation.priorityType.volunteers')} sx={{ bgcolor: '#004AB51A' }} />;
      case PriorityType.STANDARD:
        return <Chip label={t('tasks.taskCreation.priorityType.standard')} sx={{ bgcolor: '#43BF8826' }} />;
    }
  };
  const handleMeetingPerson = (item: ITasks) => {
    if (item.predefinedMeetingUserId) {
      return (
        <PlainUserInfo
          setForExpansion
          tableCell
          name={`${item.predefinedMeetingUserName} ${item.predefinedMeetingUserLastName}`}
          profession={item.predefinedMeetingUserJobTitle ?? ''}
        />
      );
    } else {
      return <Typography variant="subtitle1">{t(`tasks.taskTable.meetingType.${item.meetingType}`)}</Typography>;
    }
  };
  const convertDataToRows = (data: ITasks[]) => {
    const tempRows: any = [];
    data.forEach((item: ITasks) => {
      const isSuperiorEditable =
        roles?.find((role) => role.id === IRoles.ADMINISTRATOR || role.id === IRoles.HR) || id === item.creatorId;
      tempRows.push({
        id: item.id,
        elements: [
          <Typography variant="subtitle1">{item.name}</Typography>, // nazwa
          handleMeetingPerson(item), // z kim spotkanie
          <Typography variant="subtitle1">
            {item?.positionId ? getDictionaryValue('PositionId', item?.positionId) : '----'}
          </Typography>, // stanowisko
          <Typography variant="subtitle1">{t(`tasks.taskCreation.category.${item.category}`)}</Typography>, // rodzaj
          handlePriorityType(item.priorityType), // typ
          <Typography
            variant="subtitle1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            <StarIcon
              sx={(theme) => ({
                '& path': {
                  fill: theme.palette.grey[600],
                },
              })}
            />
            <Stack sx={{ position: 'relative', top: '2px' }}>{item.tokens}</Stack>
          </Typography>, // punkty
          <Typography variant="subtitle1">
            {item.updatedDate ? format(new Date(item.updatedDate), 'dd.MM.yyyy') : '----'}
          </Typography>,
          <Stack direction="row" spacing={2}>
            {' '}
            {/* akcje */}
            {isSuperiorEditable && (
              <Tooltip title={'Edytuj zadanie'}>
                <IconButton onClick={() => tableAction(item, 'edit')}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={'Zobacz zadanie'}>
              <IconButton onClick={() => tableAction(item, 'preview')}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Przypisz użytkownika do zadania'}>
              <IconButton onClick={() => tableAction(item, 'assign')}>
                <SmallArrowPoint />
              </IconButton>
            </Tooltip>
            {isSuperiorEditable && (
              <Tooltip title={'Usuń zadanie'}>
                <IconButton onClick={() => tableAction(item, 'delete')}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
