import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './faqInitial';
import { getFaqAPI, getFaqTagAPI } from './faqAPI';
import { IFaq } from 'interfaces/faq.interface';
import { IResponseWithPagination } from 'interfaces/common';
import { ITags } from 'interfaces/knowledge-page.interface';

export interface faqListState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: {
    faq: IResponseWithPagination<IFaq[]>;
    faqTags: ITags[];
  };
  currentRequestId?: string;
}

export const fetchFaqList = createAsyncThunk('users/fetchFaq', async (params?: object) => {
  try {
    const response = await getFaqAPI(params);
    const tagResponse = await getFaqTagAPI();
    return {
      faq: response.data.value,
      faqTags: tagResponse.data.value.records,
    };
  } catch (err) {
    return err;
  }
});

export const faqSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaqList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFaqList.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFaqList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectFaqList = (state: RootState) => state.faqList;
export const selectUserFaqTagList = (state: RootState) => state.faqList.data.faqTags;

export default faqSlice.reducer;
