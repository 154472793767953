import { StatusProgress } from 'interfaces/user.interface';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import { isValid } from 'date-fns';

export const prepareDaysLeftMessage = (
  status: string | undefined,
  onboardingEndDate: string | undefined,
  preonboardingEndDate: string | undefined
) => {
  if (onboardingEndDate && preonboardingEndDate) {
    if (!status) {
      return { title: '', content: '', daysLeft: '', dateString: '', dueDate: '' };
    }
    if (status === StatusProgress.PREONBOARDING && !preonboardingEndDate) {
      return { title: '', content: '', daysLeft: '', dateString: '', dueDate: '' };
    } else if (status === StatusProgress.ONBOARDING && !onboardingEndDate) {
      return { title: '', content: '', daysLeft: '', dateString: '', dueDate: '' };
    }
    const dueDate = status == StatusProgress.PREONBOARDING ? preonboardingEndDate ?? '' : onboardingEndDate ?? '';
    const daysLeft = differenceInCalendarDays(new Date(dueDate ?? ''), new Date());
    const daysPluralForm = daysLeft === 1 ? 'dzień' : 'dni';
    const dateString = format(new Date(dueDate ?? ''), 'dd.MM.yyyy');

    return {
      title: `${
        status == StatusProgress.PREONBOARDING
          ? 'Do I dnia pracy zostało:'
          : status == StatusProgress.BOARDED
          ? 'Onboarding został zakończony'
          : 'Do końca onboardingu zostało:'
      }`,
      content:
        status == StatusProgress.BOARDED ? '' : `${daysPluralForm} (${format(new Date(dueDate ?? ''), 'dd.MM.yyyy')})`,
      daysLeft,
      dateString,
      dueDate,
    };
  }
  return {
    title: '',
    content: '',
    dateString: '',
    dueDate: '',
  };
};

export const startWorkDate = (start: string | undefined) => {
  if (!start) {
    return '';
  } else {
    return format(new Date(start), 'dd.MM.yyyy');
  }
};

export const getDaysTillNow = (date: string) =>
  isValid(new Date(date)) ? differenceInCalendarDays(new Date(date ?? ''), new Date()) : 0;

export const calculateOnboardingProgress = (startDate?: string | number, endDate?: string | number) => {
  const daysPassedFromStart = differenceInDays(new Date(), new Date(startDate ?? ''));
  const daysToEnd = differenceInDays(new Date(endDate ?? ''), new Date(startDate ?? ''));
  const daysTillStartDate = differenceInDays(new Date(startDate ?? ''), new Date());
  const progress = (daysPassedFromStart * 100) / daysToEnd;
  const difInWeeks = differenceInWeeks(new Date(), new Date(startDate ?? ''));
  return {
    daysPassed: Math.abs(daysPassedFromStart) % 7,
    weeksPassed: difInWeeks,
    progress,
    daysToEnd,
    daysTillStartDate,
  };
};
