import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingDetailsUserView: IView = {
  key: 'viewOnboardingTemplateUser',
  title: 'preonboardingTemplateUser.templateView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR, IRoles.SUPERIOR],
  view: React.lazy(() => import('./edit-template')),
  path: ['/szablony/edycja/:templateId', '/szablony/dodaj'],
};

export default OnboardingDetailsUserView;
