import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const StarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        d="M12 3.5L14.0102 9.73316L20.5595 9.71885L15.2526 13.5568L17.2901 19.7812L12 15.92L6.70993 19.7812L8.74739 13.5568L3.44049 9.71885L9.98977 9.73316L12 3.5Z"
        fill="#323B4C"
      />
    </SvgIcon>
  );
};
