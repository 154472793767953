import { createTheme } from '@mui/material/styles';

const fontFamily = '"niveau-grotesk", sans-serif';

const defaultTheme = createTheme({
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: 80,
      letterSpacing: '-0.01em',
      lineHeight: '80px',
      marginBottom: 24,
      fontWeight: 300,
    },
    h2: {
      fontSize: 60,

      letterSpacing: '-0.01em',
      lineHeight: '66px',
      marginBottom: 24,
      fontWeight: 300,
    },
    h3: {
      fontSize: 40,

      letterSpacing: '-0.01em',
      lineHeight: '44px',
      marginBottom: 16,
      fontWeight: 300,
      '@media (max-width:900px)': {
        fontSize: 40,
      },
    },
    h4: {
      fontSize: 40,

      letterSpacing: '-0.01em',
      lineHeight: '44px',
      marginBottom: 16,
      fontWeight: 500,
    },
    h5: {
      fontSize: 24,

      letterSpacing: '-0.01em',
      lineHeight: '28px',
      marginBottom: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 20,

      letterSpacing: '-0.01em',
      lineHeight: '23px',
      fontWeight: 400,
    },
    body2: {
      //Body L32 from figma
      fontSize: 20,

      letterSpacing: '-0.01em',
      lineHeight: '32px',
      fontWeight: 400,
    },
    subtitle1: {
      //Body M from figma
      fontSize: 16,
      letterSpacing: '-0.01em',
      lineHeight: '19px',
      fontWeight: 400,
    },
    subtitle2: {
      //Body S from figma
      fontSize: 12,

      letterSpacing: '-0.01em',
      lineHeight: '14px',
      fontWeight: 400,
    },
  },
  palette: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#004AB5',
      dark: '#717885',
      light: '#003889',
    },
    secondary: {
      main: '#323B4C',
      dark: '#111826',
    },
    error: {
      main: '#FF0E4F',
      dark: '#111826',
      light: '#FF0E4F33',
    },
    success: {
      main: '#43BF88',
      light: '#43BF8826',
    },
    warning: {
      main: '#FFBE00',
    },
    grey: {
      100: '#F0F2F6',
      200: '#DBDEE3',
      300: '#C6C9D0',
      400: '#B1B5BD',
      500: '#9CA1AA',
      600: '#868C98',
      700: '#717885',
      800: '#5C6472',
      900: '#474F5F',
      A100: '#F8F9FB',
    },
  },
});

defaultTheme.components = {
  ...defaultTheme.components,
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      style: {
        textTransform: 'none',
        fontWeight: 400,
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          borderRadius: 50,
          padding: '12px 24px',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          borderRadius: 50,
          padding: '16px 32px',
        },
      },
      {
        props: { size: 'large' },
        style: {
          borderRadius: 50,
          padding: '12px 24px',
        },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '16px',
        padding: '8px',
        // backgroundColor: '#323B4C',
      },
      // arrow: {
      //   color: '#323B4C',
      // },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontSize: '12px',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontFamily: fontFamily,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '20px',
        textTransform: 'none',
        color: defaultTheme.palette.secondary.dark,
        lineHeight: '23px',
        padding: '10px 0 16px',
        position: 'relative',
        margin: '0 45px -6px 0',
        '&.Mui-selected': {
          color: defaultTheme.palette.secondary.dark,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        margin: '0 0 -6px',
      },
      indicator: {
        background: defaultTheme.palette.secondary.dark,
        height: '6px',
      },
    },
  },
};
export default defaultTheme;
