import React from 'react';
import { Box, Typography, Stack, Theme, Divider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomButton, PointValue } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { prepareDaysLeftMessage } from 'utils/user';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'store/user/userSlice';
import { useAppSelector } from 'hooks';
import { IRoles } from 'interfaces/roles.interface';

export interface IUserContactInfo {
  id: string;
  telephoneNumber: string;
  email: string;
  office: string;
  contractType: string;
  points: number;
  onboardingEndDate: string;
  employmentStartDate: string;
  status: string;
}

export const UserContactInfo = (props: IUserContactInfo) => {
  const { id, telephoneNumber, email, office, contractType, points, employmentStartDate, onboardingEndDate, status } =
    props;
  const {
    title: daysLeftTitle,
    content: daysLeftContent,
    daysLeft,
  } = prepareDaysLeftMessage(status, onboardingEndDate, employmentStartDate);
  const { details } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showUserProfilePage = () => {
    navigate(`/pracownicy/${id}`);
  };
  return (
    <Box minWidth="271px">
      <Stack spacing={3}>
        <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
          {daysLeftTitle}
        </Typography>
        <Stack direction="row" spacing="5px" alignItems="baseline">
          {Number(daysLeft) > -1 && (
            <Typography variant="h5" sx={{ fontWeight: 400, m: 0 }}>
              {daysLeft}
            </Typography>
          )}
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {daysLeftContent}
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack spacing={2} mt={5}>
        <Typography variant="subtitle1">{t('userContactInfo.additionalInfo')}</Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {t('userContactInfo.phoneNumber')}
          </Typography>
          <Typography variant="subtitle1">{telephoneNumber}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {t('userContactInfo.email')}
          </Typography>
          <Typography variant="subtitle1" sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {t('userContactInfo.office')}
          </Typography>
          <Typography variant="subtitle1">{office}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {t('userContactInfo.contractType')}
          </Typography>
          <Typography variant="subtitle1">{contractType}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1" color={(theme: Theme) => theme.palette.grey[700]}>
            {t('userContactInfo.points')}
          </Typography>
          <PointValue value={`${points}`} />
        </Stack>
      </Stack>
      {!!details.roles?.find((role) => role.id === IRoles.HR || role.id === IRoles.ADMINISTRATOR) && (
        <CustomButton
          buttonType={ButtonType.SECONDARY_ACCENT}
          text="Zobacz profil pracownika"
          size={ButtonSize.SMALL}
          onClick={showUserProfilePage}
          sx={(theme: Theme) => ({
            fontSize: '16px',
            p: theme.spacing(1, 2),
            mt: 5,
            height: 36,
          })}
        />
      )}
    </Box>
  );
};
