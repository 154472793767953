import React from 'react';
import { Box, Container, Divider, Grid, Link, Stack, Typography, Theme } from '@mui/material';
import GUWhiteLogo from 'assets/images/GUWhiteLogo.svg';
import { ContactItem, FAQItem, KnowledgePageItem, ContactWidget } from '.';
import { useAppSelector } from 'hooks';
import { selectNavSettings } from 'store/settings/settingsSlice';

export const Footer = () => {
  const settings = useAppSelector(selectNavSettings);

  const displayWidget = (widget: any) => {
    switch (widget.name) {
      case 'Baza wiedzy':
        return <KnowledgePageItem />;
      case 'FAQ':
        return <FAQItem />;
      case 'Kontakt':
        return <ContactItem />;
      case 'header+two_cols':
        return <ContactWidget widget={widget} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.secondary.main,
        p: { md: theme.spacing(15, 4, 5, 4), xs: theme.spacing(5, 2) },
        color: 'white',
        // mt: 25,
      })}
      id="gu-footer"
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item md={1} xs={12} id="footer-logo">
            <Box component="img" src={GUWhiteLogo} id="footer-logo-img" />
          </Grid>

          {settings?.footerWidgets?.map((widget, index) => {
            const widgetComponent = displayWidget(widget);
            if (widgetComponent) {
              return (
                <Grid
                  item
                  md={settings?.footerWidgets?.length === 2 ? 5 : 3}
                  xs={12}
                  key={widget.name + '-' + index}
                  id="widget-grid"
                  sx={(theme: Theme) => ({
                    p: '20px',
                    mx: '20px',
                    [theme.breakpoints.down('md')]: {
                      my: '20px',
                    },
                  })}
                >
                  {widgetComponent}
                </Grid>
              );
            }
          })}
        </Grid>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem color="white" sx={{ mr: 2 }} />}
          flexWrap="wrap"
          sx={{
            mt: '70px',
            '& > *': {
              color: 'white',
              fontWeight: 300,
              marginRight: '16px !important',
            },
          }}
        >
          {settings?.footerCopyrights && <Typography variant="subtitle1">{settings.footerCopyrights}</Typography>}
          {settings?.footerNav?.map((value) => (
            <Link
              sx={(theme) => ({ color: theme.palette.common.white })}
              href={'/static/' + value.id}
              key={value.id}
              variant="subtitle1"
            >
              {value.name}
            </Link>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
