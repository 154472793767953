import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { StarIcon } from 'assets/icons/StarIcon';

interface IPointsLabelProps {
  sx?: SxProps<Theme>;
  text: string;
  points?: string;
}
export const PointsLabel = ({ text, points, sx = [] }: IPointsLabelProps) => {
  return (
    <Box
      sx={[
        (theme: Theme) => ({
          display: 'flex',
          mb: theme.spacing(2),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        variant="subtitle1"
        sx={(theme: Theme) => ({
          lineHeight: '26px',
          color: theme.palette.grey[800],
          textTransform: 'uppercase',
        })}
      >
        {text}
      </Typography>
      {points && (
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            ml: theme.spacing(3),
            borderRadius: '12px',
            padding: theme.spacing(0.5, 1),
            background: theme.palette.grey[100],
          })}
        >
          <StarIcon
            sx={(theme: Theme) => ({
              width: '19px',
              height: '19px',
              mr: theme.spacing(1),
              mb: '-2px',
              '& path': {
                fill: theme.palette.warning.main,
              },
            })}
          />
          <Typography
            sx={{
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            {points} punktów{' '}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
