import { Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog, CustomInput } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IChangeUserEmail, IUserOnboardingData } from 'interfaces/user.interface';
import { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';

interface IChangeEmailDialogProps {
  open: boolean;
  editUser: IUserOnboardingData | undefined;
  onClose: () => void;
  onSubmit: (userId: string, data: IChangeUserEmail) => void;
}

export const ChangeEmailDialog = ({ open, onClose, onSubmit, editUser }: IChangeEmailDialogProps) => {
  const { t } = useTranslation();
  const [changedEmail, setChangedEmail] = useState<IChangeUserEmail | undefined>(undefined);
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<IChangeUserEmail>({
    defaultValues: {
      newEmail: '',
    },
  });
  const localSubmit = (data: IChangeUserEmail) => {
    if (data) {
      setChangedEmail(data);
    }
  };
  const handleClose = () => {
    onClose();
    resetField('newEmail');
  };

  const submitChanges = () => {
    if (changedEmail && editUser) {
      setChangedEmail(undefined);
      onSubmit(editUser?.userId ?? '', changedEmail);
      handleClose();
    }
  };

  return (
    <>
      <CustomDialog open={open} onClose={handleClose}>
        <Stack component={'form'} onSubmit={handleSubmit(localSubmit)} spacing={3}>
          <Typography variant="h3">{t('registerView.emailChangeTitle')}</Typography>
          <Typography variant="body1">{t('registerView.emailChangeDescription')}</Typography>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('users.userRegister.errors.email'),
              },
            }}
            name={`newEmail`}
            defaultValue={''}
            render={({ field }) => (
              <CustomInput error={!!errors?.newEmail} {...field} label={t('users.userRegister.email')} type={'text'} />
            )}
          />
          <ErrorMessage
            errors={errors}
            as={<Typography sx={{ mt: '16px !important' }} color="error" variant="subtitle1"></Typography>}
            name={'newEmail'}
          />
          <CustomButton
            text={t('button.confirm')}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            onClick={() => null}
            type={'submit'}
            sx={{
              width: 1,
              mb: 2,
            }}
          />
          <CustomButton
            text={t('button.cancel')}
            buttonType={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={handleClose}
            sx={{
              width: 1,
            }}
          />
        </Stack>
      </CustomDialog>
      <CustomDialog open={!!changedEmail} onClose={() => setChangedEmail(undefined)}>
        <Stack spacing={3}>
          <Typography variant="body1">
            {t('registerView.emailChangePopupMessage', {
              name: `${editUser?.userFirstName} ${editUser?.userLastName}`,
            })}
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <CustomButton
              text={t('common.yesIDo')}
              onClick={submitChanges}
              buttonType={ButtonType.PRIMARY}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
            <CustomButton
              text={t('button.cancel')}
              onClick={() => setChangedEmail(undefined)}
              buttonType={ButtonType.SECONDARY_ACCENT}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
          </Stack>
        </Stack>
      </CustomDialog>
    </>
  );
};
