import { Chip } from '@mui/material';
import { t } from 'i18next';
import { PriorityType } from 'interfaces/tasks.interface';

export const handlePriorityType = (type: PriorityType) => {
  switch (type) {
    case PriorityType.OBLIGATORY:
      return <Chip label={t('tasks.taskCreation.priorityType.obligatory')} sx={{ bgcolor: '#FFBE0026' }} />;
    case PriorityType.PRIORITY:
      return <Chip label={t('tasks.taskCreation.priorityType.priority')} sx={{ bgcolor: '#FF0E4F33' }} />;
    case PriorityType.VOLUNTEERS:
      return <Chip label={t('tasks.taskCreation.priorityType.volunteers')} sx={{ bgcolor: '#004AB51A' }} />;
    case PriorityType.STANDARD:
      return <Chip label={t('tasks.taskCreation.priorityType.standard')} sx={{ bgcolor: '#43BF8826' }} />;
  }
};
