import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const QuizHatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="32" fill="#F0F2F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3707 21.7385C32.1323 21.6432 31.8664 21.6432 31.628 21.7385L18.2946 27.0718C17.915 27.2237 17.666 27.5914 17.666 28.0003C17.666 28.4092 17.915 28.7769 18.2946 28.9288L22.9993 30.8107V37.3331C22.9993 38.9646 24.2711 40.2439 25.8953 41.056C27.5663 41.8915 29.7624 42.3331 31.9993 42.3331C34.2363 42.3331 36.4324 41.8915 38.1034 41.056C39.7276 40.2439 40.9993 38.9646 40.9993 37.3331V30.8107L44.3327 29.4774V36.0003C44.3327 36.5526 44.7804 37.0003 45.3327 37.0003C45.885 37.0003 46.3327 36.5526 46.3327 36.0003V28.0003C46.3327 27.5914 46.0837 27.2237 45.7041 27.0718L32.3707 21.7385ZM39.6643 29.1907C39.6399 29.1993 39.616 29.2089 39.5926 29.2193L31.9994 32.2566L24.4061 29.2193C24.3827 29.2089 24.3588 29.1993 24.3345 29.1907L21.3586 28.0003L31.9994 23.744L42.6401 28.0003L39.6643 29.1907ZM24.9993 31.6107L31.628 34.2621C31.8664 34.3575 32.1323 34.3575 32.3707 34.2621L38.9993 31.6107V37.3331C38.9993 37.8234 38.5854 38.579 37.209 39.2671C35.8794 39.9319 34.0059 40.3331 31.9993 40.3331C29.9928 40.3331 28.1193 39.9319 26.7897 39.2671C25.4133 38.5789 24.9993 37.8234 24.9993 37.3331V31.6107Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
