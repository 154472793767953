import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PhoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.9987 18.0846C15.9154 18.0846 15.9154 18.0846 15.832 18.0846C12.082 18.0846 8.58203 16.668 5.9987 14.0846C3.33203 11.5013 1.91536 7.91797 1.91536 4.16797C1.83203 3.58464 1.9987 3.0013 2.41536 2.58464C2.83203 2.16797 3.41536 1.91797 3.9987 1.91797H6.2487C6.7487 1.91797 7.2487 2.08464 7.66536 2.41797C8.66536 3.33464 9.16536 5.83464 8.9987 6.91797C8.83203 7.66797 8.2487 8.16797 7.83203 8.58464C8.7487 10.0846 9.9987 11.2513 11.4154 12.168C11.832 11.668 12.332 11.168 13.082 11.0013C14.1654 10.7513 16.7487 11.2513 17.582 12.3346C17.9154 12.7513 18.082 13.2513 18.082 13.7513V16.0013C18.082 16.5846 17.832 17.168 17.4154 17.5846C16.9987 17.918 16.4987 18.0846 15.9987 18.0846ZM15.832 16.8346C16.1654 16.8346 16.332 16.7513 16.582 16.5846C16.7487 16.418 16.9154 16.168 16.9154 15.918V13.668C16.9154 13.418 16.832 13.2513 16.7487 13.0846C16.2487 12.5013 14.2487 12.0013 13.4154 12.168C13.082 12.2513 12.6654 12.668 12.332 13.0013C12.2487 13.0846 12.082 13.2513 11.9987 13.3346C11.832 13.5013 11.4987 13.5846 11.2487 13.418C9.2487 12.2513 7.58203 10.668 6.4987 8.66797C6.33203 8.41797 6.41536 8.08464 6.58203 7.91797C6.66536 7.91797 6.83203 7.7513 6.91536 7.66797C7.2487 7.33463 7.66536 7.0013 7.7487 6.58464C7.91536 5.83464 7.41536 3.83464 6.83203 3.33464C6.66536 3.16797 6.4987 3.08464 6.2487 3.08464H3.9987C3.7487 3.16797 3.4987 3.2513 3.33203 3.41797C3.16536 3.58464 3.08203 3.83464 3.16536 4.08464C3.16536 7.58464 4.4987 10.8346 6.83203 13.168C9.16536 15.5846 12.4154 16.8346 15.832 16.8346Z"
        fill="white"
      />
    </SvgIcon>
  );
};
