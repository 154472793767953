import { Box, Card, CardActions, CardContent, CardMedia, Chip, Stack, Theme, Typography } from '@mui/material';
import MandatoryTaskIcon from 'assets/icons/MandatoryTaskIcon.svg';
import HandIcon from 'assets/icons/HandIcon.svg';
import YellowClockIcon from 'assets/icons/YellowClockIcon.svg';
import GreenCheckIcon from 'assets/icons/GreenCheckIcon.svg';
import RedFailIcon from 'assets/icons/RedFailIcon.svg';
import GreyFailIcon from 'assets/icons/GreyFailIcon.svg';
import GoldStarIcon from 'assets/icons/GoldStarIcon.svg';
import { AlertIcon, AlertType } from 'components/common/alert-icon';
import { ITask, TaskStatusType, TaskType, PriorityType } from 'interfaces/tasks.interface';
// import format from 'date-fns/format';
import { useNavigate, useLocation } from 'react-router-dom';
import _truncate from 'lodash/truncate';
import isAfter from 'date-fns/isAfter';
import { handleWierdDates } from 'utils';
import { useTranslation } from 'react-i18next';
interface ITaskCardProps {
  task: ITask;
}

export const TaskCard = ({ task }: ITaskCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const getIconType = (status: string) => {
    switch (status) {
      case TaskStatusType.NEW:
        return task.priorityType === PriorityType.OBLIGATORY ? MandatoryTaskIcon : HandIcon;
      case TaskStatusType.ACTIVE:
        return task.priorityType === PriorityType.OBLIGATORY ? MandatoryTaskIcon : HandIcon;

      case TaskStatusType.APPROVE:
        return YellowClockIcon;
      case TaskStatusType.INREVIEW:
        return YellowClockIcon;
      case TaskStatusType.FINISHED:
        return GreenCheckIcon;
      case TaskStatusType.APPROVED:
        return GreenCheckIcon;
      case TaskStatusType.REJECTED:
        if (task.type === TaskType.MISSION) {
          return task.priorityType === PriorityType.OBLIGATORY ? MandatoryTaskIcon : HandIcon;
        } else {
          return RedFailIcon;
        }
      case TaskStatusType.CANCELLED:
        return RedFailIcon;
      case TaskStatusType.EXPIRED:
        return GreyFailIcon;

      default:
        return;
    }
  };

  const handleTaskNavigate = (taskType: TaskType) => {
    switch (taskType) {
      case TaskType.MISSION:
        return navigate(`/zadania/misja/${task.id}`, {
          state: {
            asReviewer:
              location.pathname === '/' && task.status === TaskStatusType.NEW ? false : task.asReviewer ? true : false,
            forPreview:
              location.pathname === '/' && task.status === TaskStatusType.NEW ? false : task.forPreview ? true : false,
          },
        });
      case TaskType.QUEST:
        return navigate(`/zadania/quest/${task.id}`);
      case TaskType.QUIZ:
        return navigate(`/zadania/quiz/${task.objectId}`, {
          state: {
            asReviewer: task.asReviewer ? true : false,
            forPreview: task.forPreview ? true : false,
            userId: task.userId ? task.userId : '',
            status: task.status,
            finalAttempt: task.currentAttempt + 1 >= task.attempts ? true : false,
          },
        });
      case TaskType.SURVEY:
        return navigate(`/zadania/ankieta/${location.pathname === '/zadania' ? task.objectId : task.id}`, {
          state: {
            asReviewer: task.asReviewer ? true : false,
            forPreview: task.forPreview ? true : false,
            userId: task.userId ? task.userId : '',
          },
        });
      case TaskType.NONE:
        return;
      default:
        return;
    }
  };
  return (
    <Card
      onClick={() => handleTaskNavigate(task.type)}
      sx={(theme: Theme) => ({
        p: theme.spacing(5),
        borderRadius: 0,
        width: 240,
        height: 440,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        position: 'relative',
        border: `1px solid ${theme.palette.grey[200]}`,
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 3,
          cursor: 'pointer',
        },
      })}
    >
      <CardMedia
        component="img"
        image={getIconType(task.status)}
        alt="random icon"
        sx={{
          width: 96,
          height: 96,
          mb: 5,
        }}
      />
      {task.type === TaskType.MISSION && task.status === TaskStatusType.REJECTED && (
        <Box position="absolute" top={40} left={130}>
          <AlertIcon type={AlertType.WARNING} label="Do poprawy" />
        </Box>
      )}
      <CardContent sx={{ p: 0 }}>
        {task.type === TaskType.QUIZ || !task.to ? (
          <Box
            sx={{
              mb: 1,
              height: 16,
            }}
          />
        ) : (
          <Box
            display="flex"
            sx={{
              mb: 1,
            }}
          >
            <Typography variant="subtitle1">Do kiedy:</Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                pl: 1,
                color: (theme) =>
                  isAfter(new Date(task.to), new Date()) || task.status !== TaskStatusType.NEW
                    ? theme.palette.grey[800]
                    : theme.palette.error.main,
              }}
            >
              {task.to ? handleWierdDates(task.to, false) : '---'}
            </Typography>
          </Box>
        )}

        <Typography variant="h5" sx={{ mb: 1, fontWeight: 500, minHeight: '84px', wordBreak: 'break-word' }}>
          {_truncate(task.name)}
        </Typography>
        <Typography variant="subtitle1" sx={{ minHeight: '58px' }}>
          {_truncate(task.description ?? task.objectDescription, {
            length: 40,
          })}
        </Typography>
      </CardContent>
      <CardActions sx={{ p: 0 }}>
        <Stack direction="row" spacing={1} mt={2} justifyContent="space-between" alignItems="center" sx={{ width: 1 }}>
          <Chip icon={<Box component="img" src={GoldStarIcon} />} label={task.tokens} size="small" />
          {task.tagsMission && task.tagsMission.map((tag) => <Chip label={tag.name} size="small" key={tag.id} />)}
          {task.type === TaskType.QUIZ && task.attempts > 0 && task.status === TaskStatusType.NEW && (
            <Typography variant="subtitle2">
              {t('common.attempt', { currentAttempt: task.currentAttempt + 1, attempts: task.attempts })}
            </Typography>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
};
