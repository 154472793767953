import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34402 3.63642C3.47619 3.39796 3.72736 3.25 4 3.25H20C20.2726 3.25 20.5238 3.39796 20.656 3.63642C20.7882 3.87489 20.7805 4.1663 20.636 4.3975L15.75 12.2151V21C15.75 21.2599 15.6154 21.5013 15.3943 21.638C15.1732 21.7746 14.8971 21.7871 14.6646 21.6708L8.66459 18.6708C8.4105 18.5438 8.25 18.2841 8.25 18V12.2151L3.364 4.3975C3.2195 4.1663 3.21185 3.87489 3.34402 3.63642ZM5.35319 4.75L9.636 11.6025C9.7105 11.7217 9.75 11.8594 9.75 12V17.5365L14.25 19.7865V12C14.25 11.8594 14.2895 11.7217 14.364 11.6025L18.6468 4.75H5.35319Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
