import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const KnowledgeView: IView = {
  key: 'viewKnowledge',
  title: 'knowledgePages.manage.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./knowledge-page')),
  path: '/knowledge-base-manage',
  // navigation: true,
};

export default KnowledgeView;
