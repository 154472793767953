import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlainRoundCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width={32} height={32} viewBox="0 0 32 32">
      <rect width="32" height="32" fill="white" />
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#9CA1AA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3908 11.536C24.6252 11.7703 24.6252 12.1502 24.3908 12.3845L14.7108 22.0645C14.5983 22.177 14.4457 22.2402 14.2866 22.2402C14.1275 22.2402 13.9748 22.177 13.8623 22.0645L7.60152 15.8037C7.3672 15.5694 7.3672 15.1895 7.60152 14.9552C7.83583 14.7209 8.21573 14.7209 8.45005 14.9552L14.2866 20.7917L23.5423 11.536C23.7766 11.3017 24.1565 11.3017 24.3908 11.536Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
