import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BlankFile = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.541748 1.375C0.541748 1.04708 0.82157 0.78125 1.16675 0.78125H8.66675C8.83251 0.78125 8.99148 0.843806 9.10869 0.955155L13.2754 4.91349C13.3926 5.02484 13.4584 5.17586 13.4584 5.33333V15.625C13.4584 15.9529 13.1786 16.2188 12.8334 16.2188H1.16675C0.82157 16.2188 0.541748 15.9529 0.541748 15.625V1.375ZM1.79175 1.96875V15.0312H12.2084V6.71875H9.50008C8.23443 6.71875 7.20842 5.74404 7.20842 4.54167V1.96875H1.79175ZM8.45842 2.01677V4.54167C8.45842 5.0882 8.92479 5.53125 9.50008 5.53125H12.1579L8.45842 2.01677Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
