import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UpArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.96967 12.5303C3.67678 12.2374 3.67678 11.7626 3.96967 11.4697L11.9697 3.46967C12.1161 3.32322 12.3081 3.25 12.5 3.25C12.6017 3.25 12.6987 3.27024 12.7871 3.30691C12.8755 3.34351 12.9584 3.39776 13.0303 3.46967L21.0303 11.4697C21.3232 11.7626 21.3232 12.2374 21.0303 12.5303C20.7374 12.8232 20.2626 12.8232 19.9697 12.5303L13.25 5.81066L13.25 20C13.25 20.4142 12.9142 20.75 12.5 20.75C12.0858 20.75 11.75 20.4142 11.75 20L11.75 5.81066L5.03033 12.5303C4.73744 12.8232 4.26256 12.8232 3.96967 12.5303Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
