import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IDefaultInteface } from 'interfaces/common';
import { RootState } from 'store';
import { getFileTags } from './fileTagsAPI';
import { initialState } from './fileTagsInitial';

export interface fileTagsState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IDefaultInteface[];
  currentRequestId?: string;
}

export const fetchFileTags = createAsyncThunk('fileTags/fetch', async (params?: object) => {
  try {
    const response = await getFileTags(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const fileTagsSlice = createSlice({
  name: 'fileTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileTags.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFileTags.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFileTags.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectFileTags = (state: RootState) => state.fileTags;

export default fileTagsSlice.reducer;
