import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewPreviewQuizManage: IView = {
  key: 'viewPreviewQuizManage',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./preview-quiz-view')),
  path: '/tasks-manage/quiz-preview/:id',
};

export default ViewPreviewQuizManage;
