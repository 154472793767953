import { knowledgePagesState } from './knowledgePagesSlice';

// export const initialState: knowledgePagesState = {
//   loading: 'idle',
//   data: [],
// };
export const initialState: knowledgePagesState = {
  loading: 'idle',
  data: {
    knowledgePages: {
      records: [],
      recordsCount: 0,
      page: 1,
      size: 10,
    },
    tags: [],
  },
};
