import { IDictionariesValue } from 'interfaces/dictionaries';
import appService from 'services/common';

const fetchAllDictionariesAPI = () => {
  return appService.get('/dictionaries');
};
const fetchDictionaryAPI = (dictionariesId: string) => {
  return appService.get(`/dictionaries/${dictionariesId}`);
};
const postDictionaryAPI = (dictionary: IDictionariesValue) => {
  return appService.post(`/dictionaries-manage/`, dictionary);
};
const deleteDictionaryAPI = (dictionariesId: string) => {
  return appService.delete(`/dictionaries-manage/${dictionariesId}`);
};
const putDictionaryAPI = (id: string, dictionaries: any) => {
  return appService.put(`/dictionaries-manage/${id}`, dictionaries);
};

export { fetchAllDictionariesAPI, fetchDictionaryAPI, postDictionaryAPI, deleteDictionaryAPI, putDictionaryAPI };
