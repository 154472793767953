import { useState, useEffect, useCallback } from 'react';
import { Order } from 'interfaces/table.interface';
import { fetchUsersList, selectUsersList, usersListSlice } from 'store/usersList/usersListSlice';
import { useAppDispatch, useAppSelector, useCreateRegisterUserTableData } from 'hooks';
import { useSnackbar } from 'notistack';
import { IChangeUserEmail, IChangeUserRoles, IMainUserData, IUserOnboardingData } from 'interfaces/user.interface';
import { CustomTable } from 'components';
import { archiveUser, changeUserEmail, changeUserName } from 'services';
import { ArchivisationConfirmationDialog } from '../archivization-confirmation-dialog/archivization-confirmation-dialog';
import { SearchField } from 'components/common';
import { t } from 'i18next';
import _debounce from 'lodash/debounce';
import { ChangeRoleDialog } from '../change-role-dialog';
import { assignUserToRole } from 'services';
import { ChangeEmailDialog } from '../change-email-dialog';
import { ChangeNameDialog } from '../change-name-dialog';

export enum TableArchiveUserActions {
  ARCHIVE = 'archive',
  EDIT_ROLE = 'editrole',
  CHANGE_EMAIL = 'changeEmail',
  CHANGE_NAME = 'changeName',
}
interface Props {
  setIsLoading: (value: boolean) => void;
}

export const UsersTableView = ({ setIsLoading }: Props) => {
  const [sort, setSort] = useState<string>('userFirstName');
  const [asc, setAsc] = useState<Order>('asc');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersList);
  const { enqueueSnackbar } = useSnackbar();
  const [editRoleUser, setEditRoleUser] = useState<IUserOnboardingData | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<IUserOnboardingData | undefined>(undefined);
  const [editUserEmail, setEditUserEmail] = useState<IUserOnboardingData | undefined>(undefined);
  const [editUserName, setEditUserName] = useState<IUserOnboardingData | undefined>(undefined);

  const handleAchiveUser = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await archiveUser(userId);
      if (response.data.success) {
        setSelectedUser(undefined);
        fetchUsers();
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleUser = async (data: IChangeUserRoles) => {
    try {
      setIsLoading(true);
      await assignUserToRole(editRoleUser?.userId ?? '', data.roleIds);
      setEditRoleUser(undefined);
      fetchUsers();
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  const handleUserEmailChange = async (userId: string, data: IChangeUserEmail) => {
    try {
      setIsLoading(true);
      const response = await changeUserEmail(userId, data);
      if (response.data.success) {
        setEditUserEmail(undefined);
        fetchUsers();
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameChange = async (userId: string, data: IMainUserData) => {
    try {
      setIsLoading(true);
      const response = await changeUserName(userId, data);
      if (response.data.success) {
        setEditUserName(undefined);
        fetchUsers();
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  const handleTableAction = (data: IUserOnboardingData, name: TableArchiveUserActions) => {
    switch (name) {
      case TableArchiveUserActions.ARCHIVE:
        return setSelectedUser(data);
      case TableArchiveUserActions.EDIT_ROLE:
        return setEditRoleUser(data);
      case TableArchiveUserActions.CHANGE_EMAIL:
        return setEditUserEmail(data);
      case TableArchiveUserActions.CHANGE_NAME:
        return setEditUserName(data);
      default:
        return null;
    }
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = sort === property && asc === 'asc';
    setAsc(isAsc ? 'desc' : 'asc');
    setSort(String(property));
  };
  const { rows, columns } = useCreateRegisterUserTableData({
    data: users.data.records as IUserOnboardingData[],
    tableAction: handleTableAction,
  });

  const debounceSearch = useCallback(
    _debounce((searchValue) => setSearchTerm(searchValue), 500),
    []
  );
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceSearch(event.target.value);
    setNewPage(0);
  };
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        fetchUsersList({
          page,
          size,
          sort,
          term: searchTerm,
          asc: asc === 'asc' ? true : false,
        })
      );
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const { page, recordsCount, size } = users.data;
  const { newPage, newPageSize } = usersListSlice.actions;
  const setNewPage = (page: number) => dispatch(newPage(page));
  const setNewPageSize = (pageSize: number) => dispatch(newPageSize(pageSize));
  useEffect(() => {
    fetchUsers();
  }, [page, sort, asc, size, searchTerm]);

  return (
    <>
      <SearchField
        placeholder={t('users.search')}
        value={searchValue}
        onChange={onChangeSearch}
        sx={{
          my: 3,
        }}
      />
      <CustomTable
        rows={rows}
        columns={columns}
        pagination={{
          page,
          size,
          recordsCount,
          onPageChange: setNewPage,
          onRowsPerPageChange: setNewPageSize,
        }}
        orderBy={sort}
        order={asc}
        onRequestSort={handleRequestSort}
      />
      <ArchivisationConfirmationDialog
        open={!!selectedUser}
        name={`${selectedUser?.userFirstName} ${selectedUser?.userLastName}`}
        onClose={() => setSelectedUser(undefined)}
        id={selectedUser?.userId ?? ''}
        deleteFunc={(id) => handleAchiveUser(id)}
      />
      <ChangeRoleDialog
        open={editRoleUser !== undefined}
        onClose={() => setEditRoleUser(undefined)}
        editUser={editRoleUser}
        onSubmit={handleRoleUser}
      />
      <ChangeEmailDialog
        open={!!editUserEmail}
        onClose={() => setEditUserEmail(undefined)}
        editUser={editUserEmail}
        onSubmit={handleUserEmailChange}
      />
      <ChangeNameDialog
        open={!!editUserName}
        onClose={() => setEditUserName(undefined)}
        editUser={editUserName}
        onSubmit={handleNameChange}
      />
    </>
  );
};
