import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useToggle } from 'hooks/useToggle';
import { IFiles } from 'interfaces/files.interface';
import { StatusProgress, UserAssignItem } from 'interfaces/user.interface';
import { IFaq } from 'interfaces/faq.interface';
import { IDefaultInteface, IPagination, KeyValues } from 'interfaces/common';
import { IResponseWithPagination } from 'interfaces/common';
import { CustomButton, CustomDialog, SearchField } from '..';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { Loader } from '../loader';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { FilterChips } from 'views/Knowledge-page/components/filter-chips';

interface IUploaderProps {
  fetch: any;
  buttonAddText: string;
  buttonCenter?: boolean;
  hideButton?: boolean;
  searchRoles?: string[];
  oneChoice?: boolean;
  paramsSort?: { sort: string; asc: boolean };
  extraParams?: string;
  defaultValue?: KeyValues[];
  itemSelected: (item: any, index: number) => JSX.Element;
  itemListLabel: (item: any) => JSX.Element;
  setValues?: (item: any[]) => void;
  requireTags?: boolean;
  searchTags?: IDefaultInteface[];
  onboardingStatus: StatusProgress;
  noResultsCustomMessage?: string;
}

interface MissionPagination extends IPagination {
  filesFilter?: 'withfiles' | 'all' | 'nofiles';
  tag?: string;
  sort?: string;
  asc?: boolean;
  OnboardingStatus?: StatusProgress;
  RoleIds?: any;
}

// eslint-disable-next-line react/prop-types
export const PlainCustomDataPicker: React.FC<IUploaderProps> = ({
  buttonAddText,
  oneChoice,
  buttonCenter,
  hideButton,
  extraParams,
  defaultValue,
  itemSelected,
  paramsSort,
  fetch,
  itemListLabel,
  setValues,
  searchTags = [],
  requireTags = false,
  searchRoles,
  onboardingStatus,
  noResultsCustomMessage,
}) => {
  const [open, setOpen] = useToggle();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checked, setChecked] = useState<any[]>(defaultValue ?? []);
  const [selected, setSelected] = useState<any[]>(defaultValue ?? []);
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<IResponseWithPagination<any[]>>();
  const [currentTag, setCurrentTag] = useState<IDefaultInteface | undefined>();

  const { t } = useTranslation();
  const [pagination, setPagination] = useState<MissionPagination>(
    paramsSort
      ? {
          page: 1,
          size: 10,
          filesFilter: 'withfiles',
          OnboardingStatus: onboardingStatus,
          ...paramsSort,
        }
      : {
          page: 1,
          size: 10,
          filesFilter: 'withfiles',
          OnboardingStatus: onboardingStatus,
        }
  );
  useEffect(() => {
    if (searchRoles) {
      setPagination({
        ...pagination,
        RoleIds: searchRoles,
      });
    }
  }, []);

  React.useEffect(() => {
    if (defaultValue && loading) {
      setChecked([...defaultValue]);
      setSelected([...defaultValue]);
    }
  }, [defaultValue]);

  const getValueId = (value: any) => (extraParams ? value[extraParams] : value.id);

  const handleToggle = (valueItem: string) => () => {
    const currentIndex = checked.findIndex((value) => getValueId(value) === valueItem);
    const currentItem = items?.records.find((value) => getValueId(value) === valueItem);
    const newChecked = oneChoice ? [] : [...checked];
    if (currentIndex === -1) {
      newChecked.push(currentItem);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (searchTags && searchTags.length) {
      setCurrentTag(searchTags[0]);
    }
  }, [searchTags]);

  const debounceSearch = useCallback(
    _debounce((searchValue) => setSearchTerm(searchValue), 500),
    []
  );

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceSearch(event.target.value);
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  useEffect(() => {
    if (open) fetchItems();
  }, [open, pagination.size, pagination.term, pagination.page, searchTerm, currentTag]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      if (requireTags && searchTags.length) {
        const response = await fetch({
          ...pagination,
          term: searchTerm,
          TagIds: [currentTag?.id],
        });
        if (response.data.success) {
          setItems(response.data.value);
          setPagination({
            ...pagination,
            recordCount: response.data.value.recordsCount,
          });
        } else {
          setItems(undefined);
        }
      } else {
        const {
          data: { value },
        } = await fetch({
          ...pagination,
          term: searchTerm,
        });
        setPagination({
          ...pagination,
          recordCount: value.recordsCount,
        });
        setItems(value);
      }
    } catch (e) {
      console.error(e);
      setItems(undefined);
    } finally {
      setLoading(false);
    }
  };

  const onSelect = () => {
    if (setValues) {
      setValues(checked);
    }
    setSelected(checked);
    setOpen();
  };

  const goNext = () =>
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

  const goPrev = () =>
    setPagination({
      ...pagination,
      page: pagination.page - 1,
    });
  return (
    <>
      <CustomDialog onClose={setOpen} sx={{ minWidth: '400px' }} maxWidth={'lg'} open={open}>
        <DialogContent>
          <DialogTitle sx={{ m: 0, p: 0, fontSize: '30px' }}>{t('common.choice')}</DialogTitle>
          <Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" spacing={5} sx={{ mb: 2 }}>
            <Box>
              <Typography variant="body1" sx={{ mb: '16px' }}>
                {noResultsCustomMessage && pagination.recordCount === 0
                  ? noResultsCustomMessage
                  : t('common.resultsNo', { count: pagination.recordCount ?? 0 })}
              </Typography>
            </Box>
            {requireTags && searchTags.length > 1 && (
              <Stack direction="row" flexWrap="wrap" spacing="15px">
                <Typography variant="body2">{t('button.filter')}:</Typography>
                {searchTags.length &&
                  searchTags.map((item) => (
                    <FilterChips
                      {...item}
                      active={currentTag?.name === item.name}
                      key={item.id}
                      setTags={setCurrentTag}
                      requiredChoice
                    />
                  ))}
              </Stack>
            )}
          </Stack>
          <SearchField
            sx={(theme) => ({ mb: theme.spacing(4) })}
            onChange={onChangeSearch}
            placeholder={'Wyszukaj...'}
            value={searchValue}
          />
          {loading ? (
            <Loader open={loading} />
          ) : items && items.records.length === 0 ? (
            <Box>{t('commonTable.noData')}</Box>
          ) : (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {items &&
                items.records.map((item: any) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggle(getValueId(item))} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.findIndex((value) => getValueId(value) === getValueId(item)) > -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': getValueId(item) }}
                        />
                      </ListItemIcon>
                      <ListItemText id={item.id} primary={itemListLabel(item)} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {items?.records.length ? (
            <Pagination
              sx={(theme) => ({
                mr: theme.spacing(5),
              })}
              page={pagination.page}
              count={pagination.recordCount ? Math.ceil(pagination.recordCount / pagination.size) : 0}
              onChange={(event, page) =>
                setPagination({
                  ...pagination,
                  page,
                })
              }
            />
          ) : null}
          <Box>
            <CustomButton
              text={t('button.cancel')}
              buttonType={ButtonType.SECONDARY}
              onClick={setOpen}
              sx={{ fontSize: '14px', mr: '16px' }}
              size={ButtonSize.SMALL}
            />
            {items?.records.length ? (
              <CustomButton
                text={t('commonTable.select')}
                buttonType={ButtonType.PRIMARY}
                onClick={onSelect}
                sx={{ fontSize: '14px' }}
                size={ButtonSize.SMALL}
              />
            ) : null}
          </Box>
        </DialogActions>
      </CustomDialog>
      {selected.length > 0 && (
        <Grid container spacing={4} columns={12}>
          {selected.map((item: IFiles | UserAssignItem | IFaq, index) => {
            return itemSelected(item, index);
          })}
        </Grid>
      )}
      {!hideButton ? (
        <Box display={'flex'} mb={(theme) => theme.spacing(2)} justifyContent={buttonCenter ? 'center' : 'left'}>
          <Button variant="contained" onClick={setOpen}>
            + {buttonAddText}
          </Button>
        </Box>
      ) : null}
    </>
  );
};
