import { useCallback, useEffect, useState } from 'react';
import { fetchUserDetails, selectUser, fetchCurrentUserOnboardingDetails, clear } from 'store/user/userSlice';
import { fetchAllDictionaries } from 'store/dictionaries/dictionariesSlice';
import { defaultConfig } from 'config/config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchFeSettings } from 'store/settings/settingsSlice';
import { fetchTags } from 'store/knowledgePages/knowledgePagesSlice';
import { fetchFileTags } from 'store/file-tags/fileTagsSlice';
import { API_URL } from 'config/const';

export const useAuth = () => {
  const storageToken = localStorage.getItem(defaultConfig.KEY_TOKEN);
  const { loading, isAuth, details } = useAppSelector(selectUser);
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const hasDetails = details.name.length !== 0;
  const fetchAuth = useCallback(async () => {
    try {
      await dispatch(fetchUserDetails());
      await dispatch(fetchCurrentUserOnboardingDetails());
      await dispatch(fetchFeSettings());
      await dispatch(fetchTags());
      await dispatch(fetchFileTags());
      await dispatch(fetchAllDictionaries());
    } catch (err) {
      console.error(err);
    }
  }, [storageToken]);

  const setToken = (token: string) => localStorage.setItem(defaultConfig.KEY_TOKEN, token);

  useEffect(() => {
    if (storageToken) {
      if (isAuth === undefined && !hasDetails) {
        fetchAuth();
      }
    } else {
      if (!search.includes('token')) {
        localStorage.removeItem(defaultConfig.KEY_TOKEN);
        window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
      }
    }
  }, [storageToken]);
  useEffect(() => {
    if (isAuth === false) {
      localStorage.removeItem(defaultConfig.KEY_TOKEN);
      window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
    }
  }, [isAuth]);
  return {
    loading,
    auth: hasDetails,
    setToken,
    roles: details.roles,
  };
};
