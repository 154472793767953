import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingAssignTasksView: IView = {
  key: 'viewOnboardingAssignTasks',
  title: 'preonboardingTemplateUser.assignTasksView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./tasks')),
  path: ['/przypisz-zadania/:userId'],
};

export default OnboardingAssignTasksView;
