import { IResponse } from 'interfaces/common';
import { IMissionFiles, IUserMission } from 'interfaces/missions.interface';
import appService from 'services/common';

const getMissionFiles = async (idUser?: string) => {
  return await appService.get<IResponse<IUserMission[]>>(
    idUser ? `/missions/userfiles/${idUser}` : '/missions/userfiles'
  );
};
const postFileToMission = async (
  selectFileMission: Pick<IMissionFiles, 'fileId'>,
  selectMissionAssign: Pick<IUserMission, 'id'>,
  formData: any
) => {
  return await appService.post(
    `/users/mission/assignments/${selectMissionAssign?.id}/file/${selectFileMission?.fileId}/upload/complete`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export { getMissionFiles, postFileToMission };
