import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './badgesInitial';
import { deleteBadgetManage, fetchBadgets } from './badgesAPI';
import { IDictionariesValue } from 'interfaces/dictionaries';
import { IBadges } from 'interfaces/badges';
import { IResponseWithPagination } from 'interfaces/common';

export interface dictionariesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<IBadges[]>;
  currentRequestId?: string;
}

export const fetchBadges = createAsyncThunk('badgesUser/fetchAll', async () => {
  try {
    const response = await fetchBadgets({ size: 99 });
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const deleteBadge = createAsyncThunk('badgesUser/deleteItem', async (badgeId: string) => {
  try {
    const response = await deleteBadgetManage(badgeId);
    return response.data;
  } catch (err) {
    return err;
  }
});

export const badgesSlice = createSlice({
  name: 'badgesUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBadges.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchBadges.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchBadges.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteBadge.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteBadge.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteBadge.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectBadgesUser = (state: RootState) => state.badgesUser;

export default badgesSlice.reducer;
