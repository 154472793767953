import React from 'react';
import { Dialog, DialogProps, Box, IconButton, SxProps, Theme } from '@mui/material';
import { CloseDialogButtonIcon } from 'assets/icons/CloseDialogButtonIcon';
import { ThemeContext } from '@emotion/react';

interface ICustomDialogProps extends DialogProps {
  closeButton?: boolean;
  open: boolean;
  sx?: SxProps<Theme>;
  onClose: () => void;
}
export const CustomDialog: React.FC<ICustomDialogProps> = (props) => {
  const { closeButton = true, open, onClose, children, sx = [], ...rest } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...rest}
      sx={[
        {
          '& .MuiPaper-root': {
            overflowY: 'auto',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          position: 'relative',
          p: { md: 7, xs: 3 },
        }}
      >
        {children}
        {closeButton && (
          <CloseDialogButtonIcon
            sx={{
              position: 'absolute',
              top: '0px',
              right: 0,
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        )}
      </Box>
    </Dialog>
  );
};
