import React from 'react';
import { IView } from 'interfaces/common';

const ContentView: IView = {
  key: 'viewContent',
  title: 'contentView.title',
  view: React.lazy(() => import('./content-page')),
  path: '/static/:idPage',
};

export default ContentView;
