import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IDefaultInteface, IPagination, IResponseWithPagination } from 'interfaces/common';
import { RootState } from 'store';
import { getRoles } from './rolesAPI';
import { initialState } from './rolesInitial';

export interface rolesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: {
    roles: IResponseWithPagination<IDefaultInteface[]>;
  };
  currentRequestId?: string;
}

export const fetchRoles = createAsyncThunk('roles/fetch', async (params: IPagination) => {
  try {
    const response = await getRoles(params);
    if (response.success) {
      return response.value;
    } else {
      return response.errors;
    }
  } catch (err) {
    return err;
  }
});

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.roles = action.payload as IResponseWithPagination<IDefaultInteface[]>;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectRoles = (state: RootState) => state.roles.data.roles.records;

export default rolesSlice.reducer;
