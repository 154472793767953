import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const UsersPage: IView = {
  key: 'viewUsersPage',
  title: 'users.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./users-page')),
  navigation: true,
  path: '/pracownicy',
};

export default UsersPage;
