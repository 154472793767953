import { IResponse } from 'interfaces/common';
import appService from './common';

export const TOKEN_KEY = 'AUTH_JWT';

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const getImageUrl = async (id: string) => {
  try {
    const {
      data: { value },
    } = await appService.get<IResponse<string>>('/files/url/' + id);
    return value;
  } catch (e) {
    console.error(e);
  }
};

const handleError = (error: any) => {
  return error;
};

export { getToken, getImageUrl };
