import { Chip } from '@mui/material';
import { TaskStatusType, TaskType } from 'interfaces/tasks.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface ITaskStatusChip {
  task: any;
  userId?: string;
  clickableNavigation?: boolean;
}

export const TaskStatusChip = ({ task, userId, clickableNavigation = false }: ITaskStatusChip) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleTaskNavigate = (taskType: TaskType, forReview: boolean, forPreview: boolean) => {
    switch (taskType) {
      case TaskType.MISSION:
        return navigate(`/zadania/misja/${task.id}`, {
          state: { asReviewer: forReview ? true : false, forPreview: forPreview ? true : false },
        });
      case TaskType.QUEST:
        return navigate(`/zadania/quest/${task.id}`);
      case TaskType.QUIZ:
        return navigate(`/zadania/quiz/${task.objectId}`, {
          state: {
            asReviewer: forReview ? true : false,
            forPreview: forPreview ? true : false,
            userId: userId ? userId : '',
          },
        });
      case TaskType.SURVEY:
        return navigate(`/zadania/ankieta/${task.id}`, {
          state: {
            asReviewer: forReview ? true : false,
            forPreview: forPreview ? true : false,
            userId: userId ? userId : '',
          },
        });
      case TaskType.NONE:
        return;
      default:
        return;
    }
  };
  const handleTaskStatus = (type: TaskStatusType) => {
    switch (type) {
      case TaskStatusType.NEW:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.new')}
            color="primary"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.new')} color="primary" />
        );
      case TaskStatusType.APPROVE:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.approve')}
            color="warning"
            onClick={() => handleTaskNavigate(task.type, true, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.approve')} color="warning" />
        );
      case TaskStatusType.REJECTED:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.rejected')}
            color="error"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.rejected')} color="error" />
        );
      case TaskStatusType.FINISHED:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.finished')}
            color="success"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.finished')} color="success" />
        );
      case TaskStatusType.INREVIEW:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.inReview')}
            color="warning"
            onClick={() => handleTaskNavigate(task.type, true, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.inReview')} color="warning" />
        );
      case TaskStatusType.CANCELLED:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.cancelled')}
            color="error"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.cancelled')} color="error" />
        );
      case TaskStatusType.EXPIRED:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.expired')}
            color="error"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.expired')} color="error" />
        );
      case TaskStatusType.SOON:
        return clickableNavigation ? (
          <Chip
            label={t('tasks.userTskStatus.soon')}
            color="warning"
            onClick={() => handleTaskNavigate(task.type, false, true)}
          />
        ) : (
          <Chip label={t('tasks.userTskStatus.soon')} color="warning" />
        );
      case TaskStatusType.NONE:
        return <Chip label={t('tasks.userTskStatus.none')} color="error" />;
      default:
        return <Chip label={t('tasks.userTskStatus.none')} color="error" />;
    }
  };

  return <>{handleTaskStatus(task.status)}</>;
};
