import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingDetailsUserView: IView = {
  key: 'viewOnboardingDetailsUser',
  title: 'preonboardingTemplateUser.verificationUserView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./verfication-user')),
  path: '/weryfikacja-danych/:userId',
};

export default OnboardingDetailsUserView;
