import React from 'react';
import { Box, Typography, Theme, Avatar } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import randomFace from 'assets/images/randomFace.jpg';

export const NotificationItem = () => {
  return (
    <Box
      sx={(theme: Theme) => ({
        p: theme.spacing(3),
        position: 'relative',
        width: 1,
        maxWidth: '488px',
        bgcolor: 'white',
      })}
    >
      <Typography variant="subtitle2" color="#5C6472">
        12:34 / 11.10.2021
      </Typography>
      <Typography
        variant="body1"
        sx={(theme: Theme) => ({
          mt: theme.spacing(1 / 2),
          mb: theme.spacing(2),
        })}
      >
        Przypisano Nowowego Pracownika
      </Typography>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'row',
        })}
      >
        <Avatar
          alt="user"
          src={randomFace}
          sx={(theme: Theme) => ({
            width: 32,
            height: 32,
            mr: theme.spacing(1),
          })}
        />
        <Box>
          <Typography variant="subtitle1">User Userowicz</Typography>
          <Typography variant="subtitle2" color="#717885">
            User profession
          </Typography>
        </Box>
      </Box>
      <Box
        sx={(theme: Theme) => ({
          position: 'absolute',
          top: theme.spacing(3),
          right: theme.spacing(3),
          cursor: 'pointer',
        })}
      >
        <CloseOutlinedIcon />
      </Box>
    </Box>
  );
};
