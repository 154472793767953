import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const UserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 2C9.60693 2 7.66683 3.9401 7.66683 6.33333C7.66683 8.72657 9.60693 10.6667 12.0002 10.6667C14.3934 10.6667 16.3335 8.72657 16.3335 6.33333C16.3335 3.9401 14.3934 2 12.0002 2ZM5.66683 6.33333C5.66683 2.83553 8.50236 0 12.0002 0C15.498 0 18.3335 2.83553 18.3335 6.33333C18.3335 9.83114 15.498 12.6667 12.0002 12.6667C8.50236 12.6667 5.66683 9.83114 5.66683 6.33333ZM0.333496 22.3333C0.333496 18.8355 3.16903 16 6.66683 16H17.3335C20.8313 16 23.6668 18.8355 23.6668 22.3333V25C23.6668 25.5523 23.2191 26 22.6668 26C22.1145 26 21.6668 25.5523 21.6668 25V22.3333C21.6668 19.9401 19.7267 18 17.3335 18H6.66683C4.2736 18 2.3335 19.9401 2.3335 22.3333V25C2.3335 25.5523 1.88578 26 1.3335 26C0.781211 26 0.333496 25.5523 0.333496 25V22.3333Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
