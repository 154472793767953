import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import React from 'react';
import { selectNavSettings } from 'store/settings/settingsSlice';

export const FAQItem = () => {
  const settingsFooterNav = useAppSelector(selectNavSettings);
  const widget =
    settingsFooterNav?.footerWidgets && settingsFooterNav?.footerWidgets.find((value) => value.name === 'FAQ');
  const text = widget?.items.find((item) => item.name === 'text')?.value as string;
  return (
    <Box maxWidth={{ md: 257, xs: 1 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 300,
          color: 'white',
          textAlign: { xs: 'left', md: 'center' },
        }}
      >
        {widget?.name}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 300, mt: 4 }}>
        {text}
      </Typography>
    </Box>
  );
};
