import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';
import FileUploadView from './views/add';

const FileManageView: IView = {
  key: 'viewFileManage',
  title: 'filesView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./files-manage')),
  navigation: true,
  path: '/zarzadzanie-plikami',
  childrens: [FileUploadView],
};

export default FileManageView;
