import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './dictionariesInitial';
import { deleteDictionaryAPI, fetchAllDictionariesAPI, fetchDictionaryAPI, postDictionaryAPI } from './dictionariesAPI';
import { IDictionariesValue } from 'interfaces/dictionaries';

export interface dictionariesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IDictionariesValue[];
  currentRequestId?: string;
}

export const fetchAllDictionaries = createAsyncThunk('dictionaries/fetchAll', async () => {
  try {
    const response = await fetchAllDictionariesAPI();
    const arrayItems: IDictionariesValue[] = [];
    const items = response.data.value;
    for await (const item of items) {
      const {
        data: { value: itemValue },
      } = await fetchDictionaryAPI(item.id);
      arrayItems.push({
        id: item.id,
        ...itemValue,
      });
    }
    return arrayItems;
  } catch (err) {
    return err;
  }
});

export const postDictionary = createAsyncThunk('dictionaries/post', async (dictionary: IDictionariesValue) => {
  try {
    const response = await postDictionaryAPI(dictionary);
    return {
      name: dictionary.name,
      id: response.data.value,
    };
  } catch (err) {
    return err;
  }
});

export const deleteDictionary = createAsyncThunk('dictionaries/delete', async (dictionaryId: string) => {
  try {
    await deleteDictionaryAPI(dictionaryId);
    return dictionaryId;
  } catch (err) {
    return err;
  }
});

export const dictionarySlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDictionaries.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchAllDictionaries.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchAllDictionaries.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postDictionary.pending, (state, action: any) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postDictionary.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = [...state.data, action.payload];
          state.currentRequestId = undefined;
        }
      })
      .addCase(postDictionary.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteDictionary.pending, (state, action: any) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteDictionary.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = [...state.data.filter((value) => value.id !== action.payload)];
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteDictionary.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectDictionairesList = (state: RootState) => state.dictionaries;

export default dictionarySlice.reducer;
