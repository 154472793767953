import { Card, CardContent, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { appService } from 'services';
import { saveAs } from 'file-saver';

import { DownloadFileCard, FileFailedCard, FileLoadedCard, UploadFileCard, UploadProgressCard } from './components';
import { useSnackbar } from 'notistack';

interface IDocumentCardProps {
  status?: DocumentStatus;
  orginalFileName?: string;
  orginalLink?: string;
  uploadedLink?: string | null;
  onUpload?: any;
  title?: string;
  fileId?: string;
}
export enum DocumentStatus {
  TEMPLATE = 'template',
  DOWNLOAD = 'download',
  PROGRESS = 'progress',
  UPLOAD = 'uploaded',
  FAILED = 'failed',
}
export const DocumentCard = ({
  onUpload,
  status,
  fileId,
  title,
  orginalLink,
  orginalFileName = 'Brak nazwy pliku',
}: IDocumentCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cardType, setCardType] = useState<DocumentStatus>(DocumentStatus.TEMPLATE);
  useEffect(() => {
    if (status) setCardType(status);
  }, [status]);
  const getCardType = (status: string) => {
    switch (status) {
      case DocumentStatus.TEMPLATE:
        return <DownloadFileCard onDownload={onDownload} />;
      case DocumentStatus.DOWNLOAD:
        return <UploadFileCard onDownload={onDownload} onUpload={onUpload} />;
      case DocumentStatus.PROGRESS:
        return <UploadProgressCard />;
      case DocumentStatus.UPLOAD:
        return <FileLoadedCard onDownload={onDownloadUploaded} />;
      case DocumentStatus.FAILED:
        return <FileFailedCard onUpload={onUpload} />;
      default:
        return;
    }
  };

  const onDownload = () => {
    setCardType(DocumentStatus.DOWNLOAD);
    if (orginalLink !== null) {
      window.open(orginalLink, '_blank');
    } else {
      if (fileId) {
        generateFile(fileId);
      }
    }
  };
  const fetchDoc = async () => {
    try {
      const response = await appService.get(`/users/mission/file/${fileId}/download`, {
        responseType: 'json',
      });
      saveAs(
        `data:${response.data.value.contentType};base64,${response.data.value.stream}`,
        response.data.value.fileDownloadName
      );
    } catch (e) {
      enqueueSnackbar('Nie może pobrać pliku', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const generateFile = async (id: string) => {
    try {
      const response = await appService.get<Blob>('generate-file/' + id, {
        responseType: 'blob',
      });
      saveAs(response.data, orginalFileName);
    } catch (e) {
      console.error(e);
    }
  };

  const onDownloadUploaded = () => {
    fetchDoc();
    // if (uploadedLink) {
    //   window.open(uploadedLink, '_blank');
    // }
  };

  return (
    <Card
      sx={(theme: Theme) => ({
        borderRadius: 0,
        p: theme.spacing(5),
        ml: 2,
        width: 240,
        height: 400,
        boxShadow: 'none',
        position: 'relative',
        border: `1px solid ${theme.palette.grey[200]}`,
      })}
    >
      <CardContent sx={{ p: 0 }}>
        {title && (
          <Typography variant="h5" sx={(theme: Theme) => ({ mb: theme.spacing(4), wordBreak: 'break-word' })}>
            {title}
          </Typography>
        )}
        {cardType && getCardType(cardType)}
      </CardContent>
    </Card>
  );
};
