import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewEditUserData: IView = {
  key: 'viewEditUserData',
  title: 'singleUserPage.employeeEdition',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./edit-user-data')),
  path: '/pracownicy/edycja/:id',
};

export default ViewEditUserData;
