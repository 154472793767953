import React from 'react';
import { Box, Grid } from '@mui/material';
import { UserContactCard } from 'components/cards';
import Slider from 'react-slick';
import { IDefaultInteface } from 'interfaces/common';

export interface IUsersSliderItem extends IDefaultInteface {
  name: string;
  surname: string;
  position: string;
  isBuddy: boolean;
  avatarId?: string;
  phoneNumber?: string;
  email?: string;
}
interface IProps {
  users: IUsersSliderItem[];
}

export const UserSlider: React.FC<IProps> = ({ users }) => {
  const settingsUserSlider = {
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{
        '& .slick-slider .slick-list': {
          width: '100vw',
        },
        '& .slick-slider .slick-track': {
          padding: '20px, 0',
        },
      }}
    >
      {users.length === 0 ? (
        <Box>Brak przypisanych osób</Box>
      // ) : users.length > 3 ? (
      //   <Slider {...settingsUserSlider}>
      //     {users.map((user) => (
      //       <UserContactCard key={user.id} {...user} />
      //     ))}
      //   </Slider>
      ) : (
        // <Grid container>
        //   {users.map((user) => (
        //     <Grid item xs={4}>
        //       <UserContactCard key={user.id} {...user} />
        //     </Grid>
        //   ))}
        // </Grid>
        <Slider {...settingsUserSlider}>
          {users.map((user) => (
            <UserContactCard key={user.id} {...user} />
          ))}
        </Slider>
      )}
    </Box>
  );
};
