import { IResponse, IResponseWithPagination } from 'interfaces/common';
import { IOnboadingUserTemplates, ITemplateRoot } from 'interfaces/onboarding-templates.interface';
import appService from 'services/common';

const fetchOUserTemplatesManage = (params?: any) => {
  return appService.get(
    params ? '/onboarding-user-templates-manage/search?Ids=' + params.id : '/onboarding-user-templates-manage/search'
  );
};
const fetchUserTemplate = (params?: any) => {
  return appService.get<IResponse<IResponseWithPagination<IOnboadingUserTemplates[]>>>(
    params ? '/onboarding-user-templates?UserId=' + params : '/onboarding-user-templates',
    {
      params: {
        size: 99,
      },
    }
  );
};

export interface IPostTemplate {
  idTemplate: string;
  idTemplateUser: string;
  id: string;
  name: string;
  value: string;
}

const postUserTemplateManage = (payload: IPostTemplate) => {
  return appService.post('/onboarding-user-templates-manage', payload);
};

const putUserTemplateManage = (payload: IPostTemplate) => {
  return appService.put(`/onboarding-user-templates-manage/${payload.id}`, payload);
};

export { fetchUserTemplate, fetchOUserTemplatesManage, postUserTemplateManage, putUserTemplateManage };
