import React from 'react';
import { SearchIcon } from 'assets/icons/SearchIcon';
import { SxProps, TextField, Theme } from '@mui/material';

interface ISearchFieldProps {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
}
export const SearchField = ({ placeholder, value, onChange, sx = [] }: ISearchFieldProps) => {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
      sx={[
        (theme: Theme) => ({
          width: 1,
          maxWidth: 625,
          '& .MuiOutlinedInput-root': {
            borderRadius: 50,
            bgcolor: theme.palette.grey[100],
            borderColor: theme.palette.grey[100],
            color: theme.palette.grey[700],
            height: 56,
            '& fieldset, &:hover fieldset': {
              borderColor: theme.palette.grey[100],
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.grey[100],
            },
          },
          '& .MuiOutlinedInput-input': {
            ml: '26px',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};
