// import { IKnowledgePagePlain } from 'interfaces/knowledge-page.interface';
import appService from 'services/common';

export const getUserTasksList = async (userId: string, params?: object) => {
  try {
    const response = await appService.get(`/tasks/user/${userId}`, {
      params,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTasksList = async (params?: object) => {
  return await appService.get('onboarding-users-tasks', { params });
};

export const getAcceptorTaskList = async () => {
  try {
    const response = await appService.get('/tasks/acceptor');
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getUsersBuddyList = async (params?: string) => {
  try {
    const response = await appService.get(`/tasks/assigns/buddy${params}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUsersSuperiorList = async (params?: string) => {
  try {
    const response = await appService.get(`/tasks/assigns/superior${params}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendEmailNotification = async (body: any) => {
  try {
    const response = await appService.post('/tasks-manage/sendemail', {
      ...body,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
