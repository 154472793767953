import { rolesState } from './rolesSlice';

export const initialState: rolesState = {
  loading: 'idle',
  data: {
    roles: {
      page: 0,
      size: 0,
      records: [],
      recordsCount: 0,
    },
  },
};
