import appService from 'services/common';

export const getSingleFileById = async (id: string) => {
  try {
    const response = await appService.get('files-manage/ids', {
      params: {
        FileIds: id,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const getFileUrlById = async (fileId: string) => {
  try {
    const response = await appService.get(`/files/url/${fileId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
