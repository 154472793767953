import React from 'react';
import { Chip, Typography } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableArchivedUserActions } from 'views/Settings/views/Register/components/archived-users-view/archived-users-view';
import { IArchivedUser } from 'interfaces/user.interface';
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
interface Props {
  data: IArchivedUser[];
  tableAction: (data: IArchivedUser, name: TableArchivedUserActions) => void;
}
export const useCreateArchivedUserTableData = ({ data, tableAction }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const columns: ITableColumn[] = [
    {
      id: 'name',
      width: 30,
      label: t('users.table.fullName'),
      hasFilter: true,
    },
    {
      id: 'email',
      width: 30,
      label: t('users.table.email'),
      hasFilter: true,
    },
    {
      // id: 'status',
      width: 30,
      label: t('users.table.status'),
      hasFilter: false,
    },
    // {
    //   width: 10,
    //   label: t('users.table.actions'),
    // },
    {},
  ];

  const convertDataToRows = (data: IArchivedUser[]) => {
    const tempRows: any = [];
    data.forEach((item: IArchivedUser) => {
      tempRows.push({
        id: item.id,
        elements: [
          item.name && item.surname && <Typography>{`${item.name} ${item.surname}`}</Typography>,
          <Typography>{item.email}</Typography>,
          <Chip label={t('users.table.archived')} color="warning" />,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
