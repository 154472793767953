import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ErrorView: IView = {
  key: 'viewError',
  title: 'errorView.title',
  view: React.lazy(() => import('./error')),
  path: '/blad',
};

export default ErrorView;
