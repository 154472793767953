import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const FaqCreateView: IView = {
  key: 'ViewFaqCreate',
  title: 'faqView.title',
  view: React.lazy(() => import('./create')),
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  path: ['/faq-edytuj/:id', 'faq-dodaj'],
};

export default FaqCreateView;
