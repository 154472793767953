import appService from 'services/common';

const fetchBadgets = (params?: object) => {
  return appService.get('/badges', {
    params,
  });
};

const fetchBadgetManage = (params?: object) => {
  return appService.get('/badges-manage', {
    params,
  });
};

const fetchItemBadgetManage = (id: string) => {
  return appService.get('/badges-manage/' + id);
};

const postBadgetManage = (data: FormData) => {
  return appService.post('/badges-manage', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteBadgetManage = (id: string) => {
  return appService.delete('/badges-manage/' + id);
};

const putBadgetManage = (params?: any) => {
  return appService.put('/badges-manage/' + params.id, {
    params,
  });
};

const getBadgetFile = (id: string) => {
  return appService.get('/badges-manage/' + id + '/file');
};

const getBadgetThumbnail = (id: string) => {
  return appService.get('/badges-manage/' + id + '/thumbnail');
};

export {
  fetchBadgets,
  fetchItemBadgetManage,
  fetchBadgetManage,
  postBadgetManage,
  deleteBadgetManage,
  putBadgetManage,
  getBadgetFile,
  getBadgetThumbnail,
};
