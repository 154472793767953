import { IPagination } from 'interfaces/common';
import { IAnswersReviewSurvey, ISurvey, ISurveyAnswer, ISurveyQuestionAnswer } from 'interfaces/surveys.interface';
import appService from './common';

export const getSurveys = async (params?: IPagination) => {
  return await appService.get('/surveys-manage', {
    params,
  });
};
export const getSurveyById = async (surveyId: string) => {
  return await appService.get(`/surveys-manage/${surveyId}`);
};

export const addSurvey = async (data: Omit<ISurvey, 'id'>) => {
  return await appService.post('/surveys-manage', {
    ...data,
  });
};
export const deleteSurvey = async (id: string) => {
  return await appService.delete(`/surveys-manage/${id}`);
};
export const editSurveyByID = async (surveyId: string, data: ISurvey) => {
  return await appService.put(`/surveys-manage/${surveyId}`, {
    ...data,
  });
};
export const reviewUserAnswersSurvey = async (data: IAnswersReviewSurvey) => {
  return await appService.post('/surveys-manage/user-answers/review', {
    ...data,
  });
};

export const getUserSolvedSurveyById = async (surveyId: string, userId: string) => {
  return await appService.get(`/surveys-manage/${surveyId}/user/${userId}`);
};

export const solveSurvey = async (surveyId: string, data: ISurveyAnswer) => {
  return await appService.post(`/surveys/${surveyId}/user-answers`, {
    answers: data.answers,
  });
};
export const getSurveyUserById = async (surveyId: string) => {
  return await appService.get(`/surveys/${surveyId}`);
};
export const postUsersAssignToSurvey = async (
  surveyId: string,
  userIds: { userId: string }[],
  customRelativeDate?: string
) => {
  return await appService.post('/surveys-manage/' + surveyId + '/assign', {
    assignments: userIds,
    customRelativeDate,
  });
};
export const getUsersAssignToSurvey = async (userId: string) => {
  return await appService.get('/surveys-manage/' + userId + '/users');
};
export const getUserSurvey = async (surveyId: string) => {
  return await appService.get(`/surveys-manage/user-surveys/${surveyId}`);
};
export const unassignSurveyFromUser = async (surveyId: string) => {
  return await appService.delete(`/surveys-manage/user-surveys/${surveyId}`);
};
