import { IDictionariesItem } from 'interfaces/dictionaries';
import { selectDictionairesList } from 'store/dictionaries/dictionariesSlice';
import { useAppSelector } from './useStore';

export const useDictionaries = () => {
  const { data } = useAppSelector(selectDictionairesList);

  const getNameDictinaryById = (id: string) => {
    const currentDictionary = data.find((dictionary) => dictionary.id === id);
    return currentDictionary?.name;
  };
  const getDictionaryItems = (dictionaryName: string) => {
    const currentDictionary = data.find((dictionary) => dictionary.name === dictionaryName);
    return currentDictionary ? currentDictionary.items : [];
  };

  const getDictionaryItemsById = (id: string) => {
    const currentDictionary = data.find((dictionary) => dictionary.id === id);
    return currentDictionary ? currentDictionary.items : [];
  };

  const getDictionaryValueById = (id: string, dictionaryItemId: string) => {
    let currentItem: string | undefined;
    if (data.length) {
      for (const dictionary of data) {
        if (dictionaryItemId && dictionary.items && dictionary.id === id) {
          const item = dictionary.items.find((dictionaryItem) => dictionaryItem.id === dictionaryItemId);
          if (item) {
            currentItem = item.value;
          }
        }
      }
    }
    return currentItem ?? '';
  };

  const getDictionaryValue = (dictionaryName: string, dictionaryItemId: string) => {
    let currentItem: string | undefined;
    if (data.length) {
      for (const dictionary of data) {
        if (dictionaryItemId && dictionary.items && dictionary.name === dictionaryName) {
          const item = dictionary.items.find((dictionaryItem) => dictionaryItem.id === dictionaryItemId);
          if (item) {
            currentItem = item.value;
          }
        }
      }
    }
    return currentItem ?? '';
  };

  return {
    dictionaries: data,
    getDictionaryItems,
    getDictionaryValue,
    getDictionaryItemsById,
    getDictionaryValueById,
    getNameDictinaryById,
    dictionariesLoading: !data.length,
  };
};
