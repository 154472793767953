import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IPagination, IResponseWithPagination } from 'interfaces/common';
import { IResourcesItem } from 'interfaces/resources.interface';
import { RootState } from 'store';
import { initialState } from './onboardingResourcesInitail';
import { getOnboardingResources } from './onboardingResourcesAPI';

export interface onboardingResourcesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<IResourcesItem[]>;
  currentRequestId?: string;
}
export const fetchOnboardingResources = createAsyncThunk(
  'resources/fetchOnboardingRecources',
  async (params: IPagination) => {
    try {
      const response = await getOnboardingResources(params);
      return response.data.value;
    } catch (error) {
      return error;
    }
  }
);
export const onboardingResourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingResources.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchOnboardingResources.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload as IResponseWithPagination<IResourcesItem[]>;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingResources.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectOnboardingResources = (state: RootState) => state.onboardingResources;
export default onboardingResourcesSlice.reducer;
