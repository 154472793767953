import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Link, useScrollTrigger, Slide, AppBar, Toolbar } from '@mui/material';
import GULogo from 'assets/images/GULogo.svg';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { NavbarUserAvatar } from 'components/user-profile';
import { LinkItem } from './components/link-item';
import { LanguageSwitch, MobileNavList } from '.';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks';
import { change } from 'store/currentPage/currentPageSlice';
import { selectUser } from 'store/user/userSlice';
import viewsApp from 'views';
import { useViews } from 'hooks/useView';
import { useTranslation } from 'react-i18next';
import { IRoles } from 'interfaces/roles.interface';
import { appService } from 'services';

export interface INavigation {
  title: string;
  url: string;
}
export const Navbar = () => {
  const activePageName = useAppSelector((state) => state.currentPage.name);
  const { navigation } = useViews(viewsApp);
  const { t } = useTranslation();
  const location = useLocation();
  const { details } = useAppSelector(selectUser);
  const [hideNav, setHideNav] = useState(false);
  const [logoUrl, setLogoUrl] = useState(GULogo);
  const dispatch = useDispatch();

  const HideOnScroll = ({ children }: any) => {
    const trigger = useScrollTrigger();
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };

  const fetchLogo = async () => {
    try {
      const response = await appService.get('/tenants/logo');
      if (response.data.value) {
        setLogoUrl(response.data.value);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (details.roles?.find((role) => role.id === IRoles.CANDIDATE)) {
      setHideNav(true);
    }
  }, []);
  useEffect(() => {
    fetchLogo();
  }, []);

  useEffect(() => {
    dispatch(change(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          sx={{
            bgcolor: (theme) => theme.palette.common.white,
            color: (theme) => theme.palette.common.black,
          }}
        >
          <Toolbar>
            <Grid container alignItems="center" height={{ xs: 56, md: 112 }}>
              <Grid item md={1} xs={6}>
                <Link component={RouterLink} to="/">
                  <Box
                    alt="Logo"
                    component="img"
                    src={logoUrl}
                    width={{ xs: 80, md: 117 }}
                  />
                </Link>
              </Grid>
              <Grid
                item
                md={10}
                sx={{
                  display: { md: 'block', xs: 'none' },
                }}
              >
                {!hideNav && (
                  <Stack alignItems="center" justifyContent="center" spacing={{ md: 5 }} direction="row">
                    {navigation.map((link) => (
                      <LinkItem
                        key={link.title}
                        {...link}
                        title={t(link.title)}
                        active={link.url === activePageName}
                        onClick={() => dispatch(change(link.title))}
                      />
                    ))}
                    {/* <LanguageSwitch /> */}
                  </Stack>
                )}
              </Grid>
              <Grid item md={1} xs={6}>
                <Stack direction="row" alignItems="center" spacing={3} justifyContent="flex-end">
                  {/* <NotificationNavComponent /> */}
                  <NavbarUserAvatar />
                  <MobileNavList navigation={navigation} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar
        sx={{
          height: { sx: 56, md: 112 },
        }}
      />
    </>
  );
};
