import React from 'react';
import { Typography, Stack } from '@mui/material';
import { StarIcon } from 'assets/icons';

interface IPointValueProps {
  value: string;
}
export const PointValue = ({ value }: IPointValueProps) => (
  <Stack direction="row" alignItems="center">
    <StarIcon />
    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
      {value}
    </Typography>
  </Stack>
);
