import {
  ComponentsVariants,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';

interface ICustomSelectProps {
  label?: string;
  accent?: boolean;
  error?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  defaultValue?: any;
  selectSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  formControlSx?: SxProps<Theme>;
  displayNoValue?: boolean;
  value?: string | undefined;
  options: { label: string; value: string }[];
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
}
export const CustomSelect: React.FC<ICustomSelectProps> = ({
  label,
  accent = false,
  onChange,
  value,
  options,
  defaultValue,
  error,
  displayNoValue,
  variant,
  selectSx = [],
  labelSx = [],
  formControlSx = [],
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  return (
    <FormControl
      variant="filled"
      color="primary"
      sx={[
        {
          position: 'relative',
          width: '100%',
          height: 56,
          mb: 3,
          borderRadius: 'none',

          label: (theme: Theme) => ({
            '&.Mui-focused, &.MuiFormLabel-filled': {
              color: theme.palette.grey[600],
            },
            color: error ? theme.palette.error.main : theme.palette.grey[600],
          }),
        },
        ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
      ]}
    >
      {label && <InputLabel sx={labelSx}>{label}</InputLabel>}
      <Select
        onChange={onChange}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        fullWidth
        variant={variant}
        defaultValue={defaultValue}
        value={value}
        IconComponent={() => (
          <ArrowBackIosNewSharpIcon
            sx={{
              transform: menuOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
              pointerEvents: 'none',
              position: 'absolute',
              right: 10,
            }}
          />
        )}
        sx={[
          (theme) => ({
            borderRadius: 'none',
            backgroundColor: 'transparent',
            height: 56,
            border: (theme: Theme) => `1px solid ${theme.palette.grey[400]}`,
            '&:hover, &:focus': {
              backgroundColor: 'inherit',
              border: (theme: Theme) => `1px solid ${theme.palette.grey[600]}`,
            },
            '& .MuiInputBase-input:focus': {
              background: 'inherit',
            },
            '&.MuiFilledInput-root': {
              borderRadius: 0,
            },
            '&.MuiFilledInput-root:before, &.MuiFilledInput-root:hover:before, &.MuiFilledInput-root:after': {
              borderBottomColor: 'transparent',
              backgroundColor: 'transparent',
            },
          }),
          ...(Array.isArray(selectSx) ? selectSx : [selectSx]),
        ]}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: 0,
            },
          },
        }}
      >
        {!displayNoValue && (
          <MenuItem key={'none'} value={''}>
            Brak wyboru
          </MenuItem>
        )}
        {options?.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label ?? option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
