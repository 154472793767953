import * as React from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { FormControl, FormHelperText, IconButton } from '@mui/material';
import { useState } from 'react';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

interface ICustomInputProps {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  readOnly?: boolean;
  error?: boolean;
  defaultValue?: string;
  rows?: number;
  inputSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  id?: string;
}

export const CustomTextField = styled((props: TextFieldProps) => (
  <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  '& .MuiInputLabel-root': {
    top: -5,
    '&.Mui-focused, &.MuiFormLabel-filled': {
      color: theme.palette.grey[600],
    },
    '&.MuiFormLabel-filled + .MuiFilledInput-root': {
      border: '1px solid',
      borderColor: theme.palette.success.main,
    },
  },
  '& .MuiFilledInput-root': {
    border: `1px solid ${theme.palette.grey[400]}`,
    overflow: 'hidden',
    borderRadius: 0,
    backgroundColor: 'white',
    height: 56,
    width: '100%',

    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&.Mui-disabled, &.Mui-disabled:hover': {
      border: `1px solid ${theme.palette.grey[400]}`,
      background: theme.palette.grey[200],
    },
    '&:before, &:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: theme.palette.grey[500],
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: theme.palette.grey[500],
      '&:before': {
        borderBottom: 'none',
      },
    },
  },
}));

const CustomInput = React.forwardRef<HTMLInputElement, ICustomInputProps>(
  (
    {
      sx = [],
      inputSx = [],
      value,
      onChange,
      readOnly = false,
      error = false,
      label,
      helperText,
      type,
      rows,
      defaultValue,
      disabled = false,
      multiline = false,
      minRows,
      maxRows,
      id,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value ?? '');
    const [showPassword, setShowPassword] = useState(false);
    let scroll = false;

    //prevent change numeric value on scroll helper
    const handleOnwhell = () => {
      scroll = true;
    };

    //prevent change numeric value on scroll
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(onChange && !scroll) {
        onChange(event);
      } else {
        scroll = false;
      }
    }
    //prevent change numeric value on ArrowUp or ArrowDown
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.code === 'ArrowUp' || event.code === 'ArrowDown'){
        event.preventDefault();
      } 
    }

    const renderTextField = () => {
      switch (type) {
        case 'password':
          return (
            <TextField
              label={label}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={inputValue}
              error={error}
              maxRows={maxRows}
              sx={[
                {
                  width: '100%',
                  marginBottom: (theme) => theme.spacing(2),
                },
                ...(Array.isArray(inputSx) ? inputSx : [inputSx]),
              ]}
              rows={rows}
              minRows={minRows}
              defaultValue={defaultValue}
              disabled={disabled}
              ref={ref}
              color="secondary"
              onChange={(event) => setInputValue(event.target.value)}
              InputProps={{
                readOnly,
                endAdornment: (
                  <IconButton disabled={disabled} onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                ),
              }}
            />
          );
        case 'number':
          return (
            <TextField
              id={id}
              label={label}
              variant="outlined"
              multiline={multiline}
              ref={ref}
              type={type}
              InputProps={{
                readOnly,
              }}
              value={value}
              error={error}
              disabled={disabled}
              color="secondary"
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              onWheel={handleOnwhell}
              sx={[
                {
                  width: '100%',
                  marginBottom: (theme) => theme.spacing(2),
                },
                ...(Array.isArray(inputSx) ? inputSx : [inputSx]),
              ]}
            />
          );
        default:
          return (
            <TextField
              label={label}
              variant="outlined"
              multiline={multiline}
              ref={ref}
              type={type}
              InputProps={{
                readOnly,
              }}
              value={value}
              error={error}
              disabled={disabled}
              color="secondary"
              onChange={onChange}
              sx={[
                {
                  width: '100%',
                  marginBottom: (theme) => theme.spacing(2),
                },
                ...(Array.isArray(inputSx) ? inputSx : [inputSx]),
              ]}
            />
          );
      }
    }

    return (
      <FormControl
        sx={[
          {
            width: '100%',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {renderTextField()}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

CustomInput.displayName = 'Search';
export default CustomInput;
