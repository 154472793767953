import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserAssignResources: IView = {
  key: 'viewUserAssignResources',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./assign-resources-page')),
  path: '/pracownicy/sprzet/:id',
};

export default ViewUserAssignResources;
