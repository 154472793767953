import React from 'react';
import { IView } from 'interfaces/common';

const ViewAuth: IView = {
  key: 'viewAuth',
  title: 'authView.title',
  view: React.lazy(() => import('views/Auth/auth-view')),
  path: '/auth',
};

export default ViewAuth;
