import { useEffect, useState } from 'react';
import { Avatar, Typography, Box, Theme, SxProps } from '@mui/material';
import { RedDotIcon } from 'assets/icons';
import { fetchAvatar } from 'services/usersService';

interface IPlainUserInfo {
  active?: boolean;
  tableCell?: boolean;
  name?: string;
  profession?: string;
  hasNotification?: boolean;
  avatar?: string;
  sx?: SxProps<Theme>;
  avatarSx?: SxProps<Theme>;
  nameSx?: SxProps<Theme>;
  setForExpansion?: boolean;
  professionSx?: SxProps<Theme>;
  handleOnClick?: () => void;
}

export const PlainUserInfo = ({
  active = false,
  tableCell = false,
  name = '',
  profession = '',
  hasNotification = false,
  avatar = '',
  sx = [],
  avatarSx = [],
  nameSx = [],
  professionSx = [],
  setForExpansion = false,
  handleOnClick,
}: IPlainUserInfo) => {
  const [userAvatar, setUserAvatar] = useState('');
  const getAvatar = async () => {
    if (avatar) {
      const imageUrl = await fetchAvatar(avatar);
      setUserAvatar(imageUrl);
    }
  };
  useEffect(() => {
    getAvatar();
  }, [avatar]);

  return (
    <Box
      onClick={() => (handleOnClick ? handleOnClick() : null)}
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
        },
        (theme: Theme) =>
          !tableCell
            ? {
                p: theme.spacing(1, 2),
                borderRadius: 50,
                bgcolor: active ? theme.palette.success.main : 'none',
                width: 'fit-content',
                height: 'fit-content',
                position: 'relative',
                '&.MuiBox-root:hover': {
                  bgcolor: active ? theme.palette.success.main : theme.palette.grey[100],
                },
              }
            : {},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {avatar && (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Avatar
            alt="user"
            src={userAvatar}
            sx={[
              (theme: Theme) => ({
                width: 32,
                height: 32,
                mr: theme.spacing(1),
                position: 'relative',
              }),
              ...(Array.isArray(avatarSx) ? avatarSx : [avatarSx]),
            ]}
          />
          {hasNotification && (
            <RedDotIcon
              sx={{
                position: 'absolute',
                top: 0,
                right: 4,
                width: 10,
                height: 10,
              }}
            />
          )}
        </Box>
      )}

      <Box>
        <Typography variant="subtitle1" sx={[...(Array.isArray(nameSx) ? nameSx : [nameSx])]}>
          {name}
        </Typography>
        <Typography
          variant="subtitle2"
          color={active ? 'white' : '#717885'}
          sx={[...(Array.isArray(professionSx) ? professionSx : [professionSx])]}
        >
          {profession}
        </Typography>
      </Box>
    </Box>
  );
};
