import React from 'react';
import { Typography, Stack, IconButton, Tooltip } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { EditIcon, EyeIcon, TrashIcon, TemplatesIcon } from 'assets/icons';
import { ITemplates } from 'interfaces/templates.interface';
interface Props {
  data: ITemplates[];
  tableAction: (data: ITemplates, name: 'edit' | 'preview' | 'delete') => void;
  isSuperior?: boolean;
}
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
export const useCreateTemplatesTableData = ({ data, tableAction, isSuperior }: Props) => {
  // export const useCreateTemplatesTableData = ( data: any) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const columns: ITableColumn[] = [
    {
      width: 25,
      label: 'Nazwa',
      hasFilter: false,
      filterAction: () => console.log('filter name'),
    },
    {
      width: 15,
      label: 'Data utworzenia',
      hasFilter: false,
    },
    {
      width: 15,
      label: 'Data ostatniej modyfikacji',
      hasFilter: false,
    },
    {
      // label: 'Akcja',
    },
    {},
  ];

  const convertDataToRows = (data: any) => {
    const tempRows: any = [];
    data.forEach((item: any) => {
      tempRows.push({
        id: item.userId,
        elements: [
          <Stack direction="row" spacing={2} alignItems="center">
            <TemplatesIcon />
            <Typography variant="subtitle1">{item.name}</Typography>
          </Stack>,
          <Typography variant="subtitle1">{format(new Date(item.createdDate), 'dd.MM.yyyy')}</Typography>,
          <Typography variant="subtitle1">{format(new Date(item.modifiedDate), 'dd.MM.yyyy')}</Typography>,
          <Stack direction="row" spacing={2}>
            {!isSuperior && (
              <Tooltip title={'Edycja szablonu'}>
                <IconButton onClick={() => tableAction(item, 'edit')}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={'Podgląd szablonu'}>
              <IconButton onClick={() => tableAction(item, 'preview')}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Usuń szablon'}>
              <IconButton onClick={() => tableAction(item, 'delete')}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </Stack>,
        ],
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
