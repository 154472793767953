import { useEffect, useState } from 'react';
import { Box, Theme, Stack, Divider } from '@mui/material';
import { TaskTable } from 'components';
import { UserContactInfo } from './user-contact-info';
import { StatusProgress, IUserOnboardingAdditionalData, IUserProgress } from 'interfaces/user.interface';
import { useSnackbar } from 'notistack';
import { Loader } from 'components/common';
import { getUserOnboardingAdditionalData } from 'services';
import { setProgressValueBasedOnStatus } from 'utils';

interface IUserExpansionTable {
  id: string;
  office: string;
  status: StatusProgress;
  progress: IUserProgress;
}

export const UserExpansionTable = ({ id, office, status, progress }: IUserExpansionTable) => {
  const [userData, setUserData] = useState<IUserOnboardingAdditionalData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserDetails = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await getUserOnboardingAdditionalData(userId);
      if (response.data.success) {
        setUserData(response.data.value);
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.errors[0].message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUserDetails(id);
  }, []);
  return (
    <>
      <Loader open={isLoading} />
      <Box
        sx={(theme: Theme) => ({
          p: '20px',
          width: 1,
          backgroundColor: '#F8F9FB',
        })}
      >
        <Stack divider={<Divider orientation="vertical" flexItem />} direction="row" spacing="30px">
          <Box width={1}>
            <Box>
              <TaskTable userId={id} />
              {/* <CustomButton
              buttonType={ButtonType.PRIMARY}
              text="Wyślij zbiorcze powiadomienie pracownikowi"
              size={ButtonSize.SMALL}
              startIcon={IconName.NOTIFICATION_WHITE}
              onClick={() => {
                console.log('Wysłano powiadomienie zbiorcze');
              }}
              sx={{
                mt: 3,
                bgcolor: '#111826',
                color: 'white',
                fontSize: '12px',
                height: 36,
                p: '8px 16px 8px 16px',
                textAlign: 'left',
                lineHeight: '18px',
              }}
            /> */}
            </Box>
          </Box>
          <UserContactInfo
            id={id}
            telephoneNumber={userData?.phoneNumber ?? ''}
            email={userData?.email ?? ''}
            office={office}
            contractType={userData?.contractType ?? ''}
            points={setProgressValueBasedOnStatus(
              status ?? StatusProgress.BOARDED,
              progress?.pointsPreonboarding ?? 0,
              progress?.pointsOnboarding ?? 0
            )}
            onboardingEndDate={userData?.onboardingEndDate ?? ''}
            employmentStartDate={userData?.employmentStartDate ?? ''}
            status={status ?? ''}
          />
        </Stack>
      </Box>
    </>
  );
};
