import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { ButtonSize, IconName, ButtonType } from 'interfaces/button.interface';
import { Button, ButtonTypeMap } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RoundedDownArrow from 'assets/icons/RoundedDownArrow.svg';
import RoundCheckIcon from 'assets/icons/RoundCheckIcon.svg';
import { Box } from '@mui/system';
import { BellIcon, BellWhiteIcon, DownloadIcon, EditIcon, EyeIcon, FilterBarsIcon } from 'assets/icons';
import { HelpIcon } from 'assets/icons/HelpIcon';
import { AgreeIcon } from 'assets/icons/AgreeIcon';

interface IButtonProps {
  text: string;
  onClick: () => void;
  buttonType: ButtonType;
  disabled?: boolean;
  size: ButtonSize;
  endIcon?: IconName;
  startIcon?: IconName;
  sx?: SxProps<Theme>;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const CustomButton: React.FC<IButtonProps> = ({
  children,
  sx = [],
  disabled = false,
  text,
  onClick,
  buttonType,
  size,
  endIcon,
  startIcon,
  type = 'button',
}) => {
  const handleIconName = (iconName: IconName) => {
    switch (iconName) {
      case IconName.CHECK:
        return <Box component="img" src={RoundCheckIcon} sx={{ width: '100%', height: '100%' }} />;
      case IconName.ADD:
        return <AddCircleRoundedIcon sx={{ width: 40, height: 40 }} />;
      case IconName.ADD_PLAIN:
        return <AddRoundedIcon sx={{ width: 25, height: 25 }} />;
      case IconName.ARROW_DOWN:
        return <Box component="img" src={RoundedDownArrow} sx={{ width: 40, height: 40 }} />;
      case IconName.ARROW_UP:
        return <ArrowUpwardIcon />;
      case IconName.ARROW_LEFT:
        return <ArrowBackIcon sx={{ width: 24, height: 24 }} />;
      case IconName.ARROW_RIGHT:
        return <ArrowForwardIcon />;
      case IconName.VIEW:
        return <EyeIcon />;
      case IconName.NOTIFICATION:
        return <BellIcon />;
      case IconName.NOTIFICATION_WHITE:
        return <BellWhiteIcon />;
      case IconName.FILTER:
        return <FilterBarsIcon />;
      case IconName.EDIT:
        return <EditIcon />;
      case IconName.DOWNLOAD:
        return <DownloadIcon />;
      case IconName.ALERT:
        return <HelpIcon />;
      case IconName.AGREE_CIRCLE:
        return (
          <Box
            sx={(theme) => ({
              background: '#FFF',
              borderRadius: theme.spacing(2.5),
              width: theme.spacing(5),
              height: theme.spacing(5),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            <AgreeIcon />
          </Box>
        );
      default:
        return () => undefined;
    }
  };

  const handleButtonType = (buttonType: ButtonType) => {
    switch (buttonType) {
      case ButtonType.PRIMARY:
        return [
          {
            color: 'white',
            fontSize: 22,
            fontWeight: 400,
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
            '&:active': {
              backgroundColor: '#111826',
            },
            lineHeight: 1,
          },
        ];
      case ButtonType.SECONDARY:
        return [
          {
            fontSize: 20,
            color: 'black',
            backgroundColor: '#DBDEE3',
            '&:hover': {
              backgroundColor: '#DBDEE3',
            },
            '&:active': {
              backgroundColor: '#C6C9D0',
            },
            lineHeight: 1,
          },
        ];
      case ButtonType.SECONDARY_ACCENT:
        return [
          {
            fontSize: 20,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
            '&:active': {
              backgroundColor: '#003889',
            },
            lineHeight: 1,
          },
        ];
      case ButtonType.THIRDARY:
        return [
          {
            fontSize: 20,
            color: 'black',
            '&:hover': {
              backgroundColor: '#DBDEE3',
            },
            '&:active': {
              backgroundColor: '#C6C9D0',
            },
            lineHeight: 1,
          },
        ];
      case ButtonType.SUCCESS:
        return [
          {
            fontSize: 22,
            color: 'black',
            backgroundColor: 'success.main',
            '&:hover': {
              backgroundColor: 'success.main',
            },
            '&:active': {
              backgroundColor: '#31AF77',
            },
            lineHeight: 1,
          },
        ];
      case ButtonType.WHITE:
        return [
          {
            fontSize: 22,
            color: 'secondary.dark',
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: '#DBDEE3',
            },
            '&:active': {
              backgroundColor: 'common.white',
            },
            lineHeight: 1,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Button
      endIcon={endIcon ? handleIconName(endIcon) : null}
      startIcon={startIcon ? handleIconName(startIcon) : null}
      size={size}
      type={type}
      disabled={disabled}
      onClick={onClick}
      sx={[
        {
          pr: endIcon ? '20px' : '',
          pl: startIcon ? '20px' : '',
          '&:hover': {
            boxShadow: '4px 10px 30px 0px rgb(0 0 0 /10%)',
          },
          '&:active': {
            boxShadow: '4px 10px 30px 0px rgb(0 0 0 /10%)',
          },
        },
        ...handleButtonType(buttonType),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {text}
      {children}
    </Button>
  );
};
