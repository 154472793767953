import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useState } from 'react';
import { DownloadIcon } from 'assets/icons';
import { CompletionStatus, IUserMission } from 'interfaces/missions.interface';
import { useTranslation } from 'react-i18next';
import { PlainUserInfo } from 'components/user-profile';
import { useDictionaries } from 'hooks/useDictionaries';
import { getShortenedFileName } from 'utils';

interface IProps {
  data: IUserMission[];
  tableAction: (fileId: string, name: 'download') => void;
}
export const useCreateUserFilesTableData = ({ data, tableAction }: IProps) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();
  const { getDictionaryValue } = useDictionaries();

  const columns: ITableColumn[] = [
    {
      width: 30,
      label: t('missionFiles.table.documentName'),
    },
    {
      width: 40,
      label: t('missionFiles.table.userFileName'),
    },
    {
      width: 15,
      label: t('missionFiles.table.whoVerifies'),
    },
    {
      label: t('missionFiles.table.status'),
    },
    {
      label: 'Akcje',
    },
  ];

  const handleTaskStatus = (type: CompletionStatus) => {
    switch (type) {
      case CompletionStatus.APPROVE:
        return <Chip label={t('tasks.userTskStatus.approve')} color="warning" />;
      case CompletionStatus.REJECTED:
        return <Chip label={t('tasks.userTskStatus.rejected')} color="error" />;
      case CompletionStatus.APPROVED:
        return <Chip label={t('tasks.userTskStatus.finished')} color="success" />;
    }
  };
  const convertDataToRows = (data: IUserMission[]) => {
    const tempRows: any = [];
    if (data.length) {
      data.forEach((item: IUserMission) => {
        const { mission } = item;
        tempRows.push({
          id: item.id,
          elements: [
            mission.name && <Typography variant="subtitle1">{mission.name}</Typography>,
            mission?.missionFiles[0]?.uploadedFileName ? (
              <Typography variant="subtitle1">
                {getShortenedFileName(mission?.missionFiles[0]?.uploadedFileName)}
              </Typography>
            ) : (
              <Typography variant="subtitle1">{t('missionFiles.table.noName')}</Typography>
            ),
            item.acceptorInfo && item.acceptorInfo.acceptorFirstName ? (
              <PlainUserInfo
                tableCell
                name={`${item.acceptorInfo.acceptorFirstName} ${item.acceptorInfo.acceptorLastName}`}
                avatar={item.acceptorInfo.acceptorAvatarId}
                profession={`${getDictionaryValue('PositionId', item.acceptorInfo?.acceptorPosition ?? '')}`}
              />
            ) : (
              <Typography variant="subtitle1">{t('tasks.taskTable.noVerification')}</Typography>
            ),
            <Typography variant="subtitle1">{handleTaskStatus(item.completionStatus)}</Typography>,
            <Tooltip title={`${t('missionFiles.table.download')}`}>
              <IconButton onClick={() => tableAction(mission.missionFiles[0].fileId, 'download')}>
                <DownloadIcon
                  sx={{
                    '& path': {
                      fill: '#000',
                    },
                  }}
                />
              </IconButton>
            </Tooltip>,
          ],
        });
      });
    }
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
