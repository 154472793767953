import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewAuth: IView = {
  key: 'viewTaskStatus',
  title: 'taskStatus.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./task-status-page')),
  navigation: true,
  path: '/status-zadan',
};

export default ViewAuth;
