import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewUserAssignBuddy: IView = {
  key: 'viewUserAssignBuddy',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./assign-buddy-page')),
  path: '/pracownicy/buddy/:id',
};

export default ViewUserAssignBuddy;
