import React from 'react';
import { CardActions, Button } from '@mui/material';
import { ProgressWheel } from 'components/common/progress-wheel/progress-wheel';
interface IProps {
  onUpload?: any;
}
export const UploadProgressCard: React.FC<IProps> = () => {
  return (
    <>
      <ProgressWheel size={140} progress={45} strokeWidth={15} circleOneStroke="#DBDEE3" circleTwoStroke="#004AB5" />
      <CardActions
        sx={{
          p: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Button fullWidth sx={{ borderRadius: 0, height: 74, fontSize: 16 }} variant="contained" color="primary">
          Dodawanie pliku...
        </Button>
      </CardActions>
    </>
  );
};
