import { onboardingTemplateState } from './onboardingTemplatesSlice';

export const initialState: onboardingTemplateState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
  pagination: {
    page: 1,
    size: 10,
  },
};
