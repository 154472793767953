import React, { useState } from 'react';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { ICustomTableRowProps } from 'interfaces/table.interface';
import { ArrowChevronUp } from 'assets/icons';
import { WithChildren } from 'interfaces/with-children';

export const CustomTableRow = ({ row, colSpan, children }: WithChildren<ICustomTableRowProps>) => {
  const [open, setOpen] = useState<boolean>(false);
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {});
    }
    return child;
  });
  return (
    <>
      <TableRow>
        {row.elements.map((item, index) => {
          return item.props?.setForExpansion ? (
            <TableCell
              onClick={() => setOpen(!open)}
              sx={{ pl: 0, fontSize: '16px', cursor: 'pointer' }}
              align="left"
              key={index}
            >
              {item}
            </TableCell>
          ) : (
            <TableCell sx={{ pl: 0, fontSize: '16px' }} align="left" key={index}>
              {item}
            </TableCell>
          );
        })}
        {row.expansion && (
          <TableCell align="right">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ArrowChevronUp /> : <ArrowChevronUp sx={{ transform: 'rotate(180deg)' }} />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {row.expansion && colSpan && children && (
        <TableRow>
          <TableCell
            colSpan={colSpan}
            sx={{
              p: 0,
              border: 'none',
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {childrenWithProps}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
