import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { IRounds } from 'interfaces/rounds.interface';
import { RootState } from 'store';
import { getRounds } from './roundsAPI';
import { initialState } from './roundsInitial';

export interface roundsState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IRounds[];
  currentRequestId?: string;
}

export const fetchRounds = createAsyncThunk('rounds/fetch', async () => {
  try {
    const response = await getRounds(true);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const roundsSlice = createSlice({
  name: 'rounds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRounds.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchRounds.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchRounds.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectRounds = (state: RootState) => state.rounds;

export default roundsSlice.reducer;
