import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const KnowledgeViewBaseSingle: IView = {
  key: 'viewKnowledgeBaseSingle',
  title: 'knowledgePages.basicView.knowledgePages',
  roles: [IRoles.ADMINISTRATOR, IRoles.NEWEMPLOYEE, IRoles.EMPLOYEE, IRoles.SUPERIOR, IRoles.HR, IRoles.BUDDY],
  view: React.lazy(() => import('./single-knowledge-page')),
  path: '/knowledge-base/:id',
};

export default KnowledgeViewBaseSingle;
