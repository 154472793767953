import { Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface IArchivizationsConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  id: string;
  name: string;
  deleteFunc: (id: string) => void;
}

export const ArchivisationConfirmationDialog = ({
  deleteFunc,
  open,
  onClose,
  id,
  name,
}: IArchivizationsConfirmationDialogProps) => {
  const { t } = useTranslation();

  const onDelete = async (id: string) => {
    onClose();
    deleteFunc(id);
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Stack spacing={3}>
        <Typography variant="body1">{t('registerView.popupMessage', { name: name })}</Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
          <CustomButton
            text={t('common.yesIDo')}
            onClick={() => onDelete(id)}
            buttonType={ButtonType.PRIMARY}
            size={ButtonSize.SMALL}
            sx={{
              width: 1,
            }}
          />
          <CustomButton
            text={t('button.cancel')}
            onClick={() => onClose()}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            sx={{
              width: 1,
            }}
          />
        </Stack>
      </Stack>
    </CustomDialog>
  );
};
