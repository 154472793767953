import React from 'react';
import { Table, TableBody, TableContainer, Theme, SxProps } from '@mui/material';
import { CustomTableHead, CustomTableRow, CustomTablePagination } from './components';

import { ITableColumn, ITableRow, ICustomTablePaginationProps, Order } from 'interfaces/table.interface';

interface ICustomTableProps {
  rows: ITableRow[];
  columns: ITableColumn[];
  sx?: SxProps<Theme>;
  rowColspan?: number;
  hidePagination?: boolean;
  pagination?: ICustomTablePaginationProps;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof any) => void;
  order?: Order;
  orderBy?: string;
}
export const CustomTable = ({
  rows,
  columns,
  rowColspan,
  hidePagination,
  pagination,
  sx = [],
  order,
  onRequestSort,
  orderBy,
}: ICustomTableProps) => {
  return (
    <TableContainer
      sx={[
        (theme: Theme) => ({
          mt: theme.spacing(3),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Table size="medium">
        {columns.length > 0 && (
          <CustomTableHead onRequestSort={onRequestSort} order={order} orderBy={orderBy} columns={columns} />
        )}
        <TableBody>
          {rows.map((row) => (
            <CustomTableRow row={row} colSpan={rowColspan ? rowColspan : 0} key={row.id}>
              {row.expansion && row.expansion}
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
      {/* pagination  */}
      {!hidePagination && pagination && <CustomTablePagination {...pagination} />}
    </TableContainer>
  );
};
