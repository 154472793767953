import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IFeSettings } from 'interfaces/settings.interface';
import { RootState } from 'store';
import { getStaticPageManageApi } from './staticPageAPI';
import { initialState } from './staticPageInitial';

export interface settingsState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: [];
  currentRequestId?: string;
}

export const fetchStaticPage = createAsyncThunk('staticPage/fetch', async () => {
  try {
    const response = await getStaticPageManageApi();
    return response.data.value;
  } catch (err) {
    return false;
  }
});

export const staticPageSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaticPage.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchStaticPage.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchStaticPage.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectStaticPage = (state: RootState) => state.staticPage;

export default staticPageSlice.reducer;
