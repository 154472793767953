import { Box, SxProps, Theme, Typography } from '@mui/material';
import { SmileIcon } from 'assets/icons/SmileIcon';
import { boxShadow, centerFlex } from 'common-styles/common-styles';

interface ICounterDaysProps {
  sx?: SxProps<Theme>;
  daysLeft: number;
  text?: string;
}
export const CounterDays = ({ text = 'Do pierwszego dnia pracy zostało Ci', daysLeft, sx = [] }: ICounterDaysProps) => {
  return daysLeft < 0 ? (
    <></>
  ) : (
    <Box
      sx={[
        (theme) => ({
          background: theme.palette.common.white,
          display: 'flex',
          position: 'absolute',
          // top: { md: 'calc(100vh - 68px)', xs: 'calc(100vh - 120px)' },
          top: { md: 'calc(100vh - 68px)', xs: '60px' },
          margin: {xs: '5px'},
          left: {md: theme.spacing(3), xs: '0px' },
          borderRadius: '20px',
          // boxShadow: boxShadow,
          boxShadow: '5px 5px 10px 5px #888888',
          // boxShadow: {xs: '5px 5px 10px 5px #888888', md: boxShadow},
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({
          width: { md: theme.spacing(50), xs: 'auto' },
          m: 0,
          p: theme.spacing(3, 0, 3, 3),
          fontWeight: 400,
          fontSize: { xs: '20px', md: '40px' },
        })}
      >
        {text}
      </Typography>
      <Box
        sx={(theme) => ({
          ...centerFlex,
          width: theme.spacing(18),
          flexDirection: 'column',
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: '0 20px 20px 0',
        })}
      >
        <SmileIcon />
        <Typography
          variant="h1"
          sx={(theme: Theme) => ({
            color: theme.palette.common.white,
            m: 0,
            fontWeight: 400,
            fontSize: { xs: '40px', md: '80px' },
            lineHeight: { xs: '44px', md: '80px' },
          })}
        >
          {daysLeft}
        </Typography>
        <Typography variant="subtitle2" sx={(theme: Theme) => ({ color: theme.palette.common.white, m: 0 })}>
          {daysLeft == 1 ? 'dzień' : 'dni'}
        </Typography>
      </Box>
    </Box>
  );
};
