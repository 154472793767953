import { useEffect, useState } from 'react';
import { Card, CardContent, Stack, Theme, Typography, Box, CardMedia, SxProps } from '@mui/material';
import { ProgressBar } from 'components/common';
import { prepareDaysLeftMessage } from 'utils/user';
import { fetchAvatar } from 'services/usersService';
import { StatusProgress } from 'interfaces/user.interface';
import { DefintionList } from './components/defination-list';
import { RedExclamationIcon, StarIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import YellowClockIcon from 'assets/icons/YellowClockIcon.svg';

export interface IProps {
  fullName: string;
  position: string;
  onboardingEndDate?: string;
  employmentStartDate?: string;
  status?: string;
  points?: string;
  phoneNumber?: string;
  sx?: SxProps<Theme>;
  email?: string;
  avatar?: string;
  progressValue?: number;
}

export const UserPageCard = (props: IProps) => {
  const { sx = [] } = props;
  const {
    title: daysLeftTitle,
    content: daysLeftContent,
    daysLeft,
  } = prepareDaysLeftMessage(props.status, props.onboardingEndDate, props.employmentStartDate);
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState('');

  const getAvatar = async () => {
    if (props.avatar) {
      const imageUrl = await fetchAvatar(props.avatar);
      setAvatar(imageUrl);
    }
  };

  useEffect(() => {
    getAvatar();
  }, [props.avatar]);

  return (
    <Card
      sx={[
        (theme: Theme) => ({
          width: '100%',
          bgcolor: theme.palette.grey[100],
          boxShadow: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <CardContent
        sx={{
          p: 3,
          height: 1,
        }}
      >
        {props.status === StatusProgress.BOARDED && (
          <Box sx={{ lineHeight: '44px', overflow: 'hidden', mb: (theme) => theme.spacing(2) }}>
            <Box
              sx={{
                background: (theme) => theme.palette.warning.main,
                borderRadius: '22px',
                float: 'left',
                padding: '5px 10px',
                mr: (theme) => theme.spacing(2),
                height: '44px',
                width: '44px',
              }}
            >
              <RedExclamationIcon sx={{ path: { fill: '#000' } }} />
            </Box>
            {t('users.end')}
          </Box>
        )}
        <CardMedia
          component="img"
          src={avatar}
          alt=""
          sx={{
            width: 120,
            height: 120,
            borderRadius: '50%',
          }}
        />
        <Typography variant="h3" sx={(theme: Theme) => ({ mt: theme.spacing(3), mb: 0, fontWeight: 300 })}>
          {props.fullName}
        </Typography>
        <Typography
          variant="h5"
          sx={(theme: Theme) => ({
            fontSize: '20px',
            mt: theme.spacing(1),
            fontWeight: 300,
            color: theme.palette.grey[700],
          })}
        >
          {props.position}
        </Typography>
        {props.points && (
          <Stack
            direction="row"
            alignItems="center"
            sx={(theme) => ({
              float: 'left',
              mb: theme.spacing(2),
              height: '28px',
              p: theme.spacing(0, 2, 0, 1),
              bgcolor: theme.palette.secondary.main,
              borderRadius: '12px',
              width: 'auto',
            })}
          >
            <StarIcon sx={(theme) => ({ '& path': { fill: theme.palette.warning.main } })} />
            <Typography
              variant="subtitle1"
              sx={(theme) => ({
                fontSize: '12px',
                position: 'relative',
                top: '2px',
                lineHeight: '14px',
                display: 'flex',
                justifyContent: 'center',
                color: theme.palette.common.white,
                fontWeight: 500,
              })}
            >
              {t('common.points', { count: +props.points })}
            </Typography>
          </Stack>
        )}
        <Box
          sx={{
            width: 151,
            clear: 'both',
          }}
        >
          {props.status && props.status === StatusProgress.ONBOARDING && (
            <ProgressBar title={`${props.status}`} value={props.progressValue ?? 0} />
          )}
          {props.email && props.phoneNumber && (
            <DefintionList
              list={[
                {
                  key: 'Numer telefonu',
                  value: props.phoneNumber,
                },
                {
                  key: 'e-mail',
                  value: props.email,
                },
              ]}
            />
          )}
        </Box>

        {daysLeftTitle && daysLeft && Number(daysLeft) > -1 && (
          <Box sx={(theme: Theme) => ({ mt: theme.spacing(5) })}>
            <Typography
              variant="subtitle1"
              sx={(theme: Theme) => ({ color: theme.palette.grey[700], mb: theme.spacing(1) })}
            >
              {daysLeftTitle}
            </Typography>
            <Stack direction="row" spacing="5px" alignItems="baseline">
              <Typography variant="h5" sx={{ fontWeight: 400, m: 0 }}>
                {daysLeft}
              </Typography>
              <Typography variant="subtitle1" sx={(theme: Theme) => ({ color: theme.palette.grey[700] })}>
                {daysLeftContent}
              </Typography>
            </Stack>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
