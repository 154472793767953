import { IDefaultInteface, IResponse, IResponseWithPagination } from 'interfaces/common';
import { IUserDetails } from 'interfaces/user.interface';
import appService from 'services/common';

const getUserDetailsAPI = () => {
  return appService.get('/users/profile');
};
const postUserPersonalDataAPI = (params: Pick<IUserDetails, 'name' | 'surname' | 'hidePersonalData' | 'gender'>) => {
  return appService.put('/users', params);
};

const getCurrentUserOnboardingDetailsAPI = () => {
  return appService.get('/onboarding-user-details');
};

const getCurrentUserRolesAPI = (idUser: string) => {
  return appService.get<IResponse<IResponseWithPagination<IDefaultInteface[]>>>(`/users/${idUser}/roles`);
};
const getCurrentUserOnboardingProgressAPI = () => {
  return appService.get('/onboarding-user-details/progress');
};
const putCurrentUserOnboardingDetailsAPI = (content: any, isSubmitUserForm?: boolean) => {
  return appService.put(`/onboarding-user-details`, {
    isSubmitUserForm,
    content: {
      ...content,
    },
  });
};
export {
  getUserDetailsAPI,
  getCurrentUserOnboardingDetailsAPI,
  putCurrentUserOnboardingDetailsAPI,
  getCurrentUserOnboardingProgressAPI,
  getCurrentUserRolesAPI,
  postUserPersonalDataAPI,
};
