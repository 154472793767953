import { filesState } from './filesSlice';

export const initialState: filesState = {
  loading: 'idle',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
};
