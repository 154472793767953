import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IResponseWithPagination } from 'interfaces/common';
import { IFiles } from 'interfaces/files.interface';
import { RootState } from 'store';
import { getFiles, postFile } from './filesAPI';
import { initialState } from './filesInitial';

export interface filesState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<IFiles[]>;
  currentRequestId?: string;
}

export const fetchFiles = createAsyncThunk('files/fetch', async (params?: object) => {
  try {
    const response = await getFiles(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const postFiles = createAsyncThunk('files/post', async (file: File, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await postFile(formData);
    return response.data.value;
  } catch (err) {
    rejectWithValue(err);
    return err;
  }
});

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFiles.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFiles.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postFiles.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data.records.push(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(postFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectFiles = (state: RootState) => state.files;

export default filesSlice.reducer;
