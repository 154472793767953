import { IFaq } from 'interfaces/faq.interface';
import appService from 'services/common';

const getFaqManageAPI = (params?: object) => {
  return appService.get('/faq-manage', { params });
};
const getFaqManageIdsAPI = (params?: object) => {
  return appService.get('/faq-manage/ids', { params });
};
const postFaqManageAPI = (data?: any) => {
  return appService.post('/faq-manage', data);
};
const getFaqTagAPI = (params?: object) => {
  return appService.get('/faq/tags', { params });
};

const postFaqTagAPI = (name: string) => {
  return appService.post('/faq/tags-manage', {
    name,
  });
};

const deleteFaqTagAPI = (id: string) => {
  return appService.delete(`/faq/tags-manage/${id}`);
};

export { getFaqManageAPI, postFaqManageAPI, getFaqManageIdsAPI, postFaqTagAPI, getFaqTagAPI, deleteFaqTagAPI };
