import { IDefaultInteface, IResponse, IResponseWithPagination } from 'interfaces/common';
import appService from './common';

export const getRoles = async (params?: object) => {
  try {
    const response = await appService.get<IResponse<IResponseWithPagination<IDefaultInteface[]>>>(
      '/roles-manage',
      params
    );
    return response.data.value;
  } catch (error) {
    console.log(error);
  }
};

export const assignUserToRole = async (userId: string, roleIds: string[]) => {
  return await appService.patch(`/roles-manage/user/${userId}`, {
    rolesIds: roleIds,
  });
};

// export const assignUsersToRole = async (roleId: string, userIds: string[]) => {
//   try {
//     const response = await appService.post('/roles-manage/' + roleId + '/users', {
//       roleId,
//       userIds: [...userIds],
//     });
//     return response.data.value;
//   } catch (error) {
//     console.log(error);
//   }
// };
// export const unassignUsersToRole = async (roleId: string, userIds: string[]) => {
//   try {
//     const response = await appService.delete<IResponse<IResponseWithPagination<IDefaultInteface[]>>>(
//       '/roles-manage/' + roleId + '/users',
//       {
//         data: {
//           roleId,
//           userIds: [...userIds],
//         },
//       }
//     );
//     return response.data.value;
//   } catch (error) {
//     console.log(error);
//   }
// };
