import React from 'react';
import { CustomTable } from 'components';
import { Typography, Stack, Tooltip } from '@mui/material';
import { CustomButton, Loader } from 'components/common';
import { useState, useEffect } from 'react';
import { getUserTasksList, sendEmailNotification } from 'services/tasksService';
import { TaskStatusChip } from './components/task-status-chip';
import { ITask, TaskStatusType } from 'interfaces/tasks.interface';
import { IPagination } from 'interfaces/common';
import { ButtonType, ButtonSize, IconName } from 'interfaces/button.interface';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { PlainUserInfo } from 'components/user-profile';
import { Order } from 'interfaces/table.interface';
import { useDictionaries } from 'hooks';
import { handleWierdDates } from 'utils';
import { isAfter } from 'date-fns';

interface TableRowData {
  data: any;
  tableAction: any;
  userId?: string;
}

export interface ITaskTable {
  userId?: string;
  tasks?: ITask[];
}

export const TaskTable = (props: ITaskTable) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [rowsData, setrowsData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [sort, setSort] = useState<string>('to');
  const [asc, setAsc] = useState<Order>('asc');
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    size: 10,
    recordCount: 0,
  });
  const { getDictionaryValue } = useDictionaries();
  const prepareDataRows = ({ data, tableAction, userId }: TableRowData) => {
    return data.map((record: any) => {
      return {
        id: record.id,
        elements: [
          <Typography variant="subtitle1">{record.name}</Typography>,
          record.acceptorInfo && record.acceptorInfo.acceptorFirstName ? (
            <PlainUserInfo
              tableCell
              name={`${record.acceptorInfo.acceptorFirstName} ${record.acceptorInfo.acceptorLastName}`}
              avatar={record.acceptorInfo.acceptorAvatarId}
              profession={`${getDictionaryValue('PositionId', record.acceptorInfo?.acceptorPosition) ?? ''}`}
            />
          ) : (
            <Typography variant="subtitle1">{t('tasks.taskTable.noVerification')}</Typography>
          ),
          <TaskStatusChip clickableNavigation task={record} userId={userId} />,
          <Stack spacing={2} direction="row">
            <Typography
              variant="subtitle1"
              sx={{
                minWidth: '70px',
                textAlign: 'center',
                color: (theme) =>
                  record.status !== TaskStatusType.NEW || isAfter(new Date(record.to), new Date())
                    ? theme.palette.grey[800]
                    : theme.palette.error.main,
              }}
            >
              {record.to ? handleWierdDates(record.to, false) : '----'}
            </Typography>
            {(record.status === TaskStatusType.NEW || record.status === TaskStatusType.REJECTED) && (
              <Tooltip title={'Zostanie wysłany e-mail przypominający o zadaniu'}>
                <CustomButton
                  buttonType={ButtonType.SECONDARY}
                  text="Wyślij powiadomienie"
                  size={ButtonSize.SMALL}
                  onClick={() => tableAction(record)}
                  startIcon={IconName.NOTIFICATION}
                  sx={{
                    mt: 2,
                    mr: 2,
                    float: 'right',
                    width: 186,
                    fontSize: '12px',
                    height: 27,
                    p: '8px 16px 8px 16px',
                    textAlign: 'left',
                    lineHeight: '18px',
                  }}
                />
              </Tooltip>
            )}
          </Stack>,
        ],
      };
    });
  };

  const columnsData = [
    {
      id: 'name',
      width: 35,
      label: t('users.table.task'),
    },
    {
      width: 35,
      label: t('users.table.whoVerifies'),
    },
    {
      // id: 'status',
      width: 15,
      label: t('users.table.status'),
    },
    {
      id: 'to',
      width: 15,
      label: t('users.table.term'),
    },
  ];
  const sendNotification = async (record: any) => {
    setIsLoading(true);
    try {
      const response = await sendEmailNotification({
        userId: props.userId,
        userTaskIds: [record.id],
        emailTemplateId: '95175995-da11-45ac-53b6-08da2794db75',
      });
      if (response.success) {
        enqueueSnackbar(t('users.notification.success'), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('users.notification.error'), {
          variant: 'error',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = sort === property && asc === 'asc';
    setAsc(isAsc ? 'desc' : 'asc');
    setSort(String(property));
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const setNewPageSize = (size: number) => {
    setPagination({
      ...pagination,
      size,
    });
  };
  const setNewPage = (value: number) => {
    setPagination({
      ...pagination,
      page: 1 + value,
    });
  };
  const fetchUserTaskList = async (userId: string) => {
    setIsLoading(true);
    const response = await getUserTasksList(userId, {
      ...pagination,
      sort,
      asc: asc === 'asc' ? true : false,
    });
    if (response.success) {
      setIsLoading(false);
      setrowsData(
        prepareDataRows({
          data: response.value.records,
          tableAction: sendNotification,
          userId: props.userId,
        })
      );
      setPagination({
        page: response.value.page,
        size: response.value.size,
        recordCount: response.value.recordsCount,
      });
    }
  };

  useEffect(() => {
    if (props.userId) {
      fetchUserTaskList(props.userId);
    }
    if (props.tasks) {
      setrowsData(
        prepareDataRows({
          data: props.tasks,
          tableAction: sendNotification,
        })
      );
    }
  }, [pagination.page, pagination.size, sort, asc]);
  return (
    <>
      <Loader open={isLoading} />
      <CustomTable
        columns={columnsData}
        rows={rowsData}
        sx={{ maxHeight: '370px' }}
        pagination={{
          page: pagination.page,
          size: pagination.size,
          recordsCount: pagination.recordCount ?? 0,
          onPageChange: setNewPage,
          onRowsPerPageChange: setNewPageSize,
        }}
        orderBy={sort}
        order={asc}
        onRequestSort={handleRequestSort}
      />
    </>
  );
};
