import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { EditIcon, EyeIcon, RedExclamationIcon, SmallArrowPoint } from 'assets/icons';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { ProgressBar } from 'components/common';
import { UserExpansionTable } from 'components/tables/user-table/components/user-expansion-table';
import { PlainUserInfo } from 'components/user-profile';
// import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { ITableColumn, ITableRow } from 'interfaces/table.interface';
import { useEffect, useMemo, useState } from 'react';
import { IOnboardingUserListData } from 'interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import { TableUserActions } from 'views/Users-page/users-page';
import { setProgressValueBasedOnStatus } from 'utils';
import { IRoles } from 'interfaces/roles.interface';
/**
 *
 * @param data type not yet defined
 * @returns rows and columns
 */
interface Props {
  data: IOnboardingUserListData[];
  tableAction: (data: IOnboardingUserListData, name: TableUserActions) => void;
  roles: { id: string; name: string }[];
  claims: string[];
}
export const useCreateUserTableData = ({ data, tableAction, roles, claims }: Props) => {
  const [rows, setRows] = useState<ITableRow[]>([]);
  const { t } = useTranslation();

  const hasUserClaimToEdit = useMemo(() => {
    return claims?.some(claim => claim === 'Permission.OnboardingTemplates.OnboardingTemplate.EditUserTemplate');
  }, [claims]);
  const userHasSuperiorRole = useMemo(() => {
    return roles.some((role) => {return role.id === IRoles.SUPERIOR});
  }, [roles]);
  const columns: ITableColumn[] = [
    {
      id: 'fullName',
      width: 15,
      label: t('users.table.fullName'),
      hasFilter: true,
    },
    {
      id: 'office',
      width: 15,
      label: t('users.table.office'),
      hasFilter: true,
    },
    {
      id: 'superiorFullName',
      width: 15,
      label: t('users.table.supervisor'),
      hasFilter: true,
    },
    {
      id: 'buddyFullName',
      width: 15,
      label: t('users.table.buddy'),
      hasFilter: true,
    },
    {
      width: 15,
      label: t('users.table.progress'),
    },
    {
      id: 'isBlocked',
      width: 15,
      label: t('users.table.status'),
      hasFilter: true,
    },
    {
      width: 25,
      label: t('users.table.actions'),
    },
    {},
  ];
  const toggleUserActiveStatus = (item: IOnboardingUserListData) =>
  userHasSuperiorRole ? (
      <Chip
        label={item.isBlocked ? t('users.table.blocked') : t('users.table.active')}
        sx={{
          bgcolor: item.isBlocked ? 'error.main' : 'success.main',
        }}
      />
    ) : (
      <Chip
        onClick={() => tableAction(item, TableUserActions.ACTIVATE)}
        label={item.isBlocked ? t('users.table.blocked') : t('users.table.active')}
        sx={{
          bgcolor: item.isBlocked ? 'error.light' : 'success.light',
          '&:hover': {
            bgcolor: item.isBlocked ? 'error.main' : 'success.main',
          },
        }}
      />
    );
  const convertDataToRows = (data: IOnboardingUserListData[]) => {
    const tempRows: any = [];
    data.forEach((item: IOnboardingUserListData) => {
      const { status, progress } = item;
      tempRows.push({
        id: item.userId,
        elements: [
          item.fullName && (
            <PlainUserInfo
              setForExpansion
              tableCell
              name={item.fullName}
              avatar={item.avatar}
              profession={item.position}
            />
          ),
          item.office ? <Typography variant="subtitle1">{item.office}</Typography> : <RedExclamationIcon />,
          item.superiorFullName ? (
            <Typography variant="subtitle1">{item.superiorFullName}</Typography>
          ) : (
            <RedExclamationIcon />
          ),
          item.buddyFullName ? (
            <Typography variant="subtitle1">{item.buddyFullName}</Typography>
          ) : (
            <RedExclamationIcon />
          ),
          <ProgressBar
            value={setProgressValueBasedOnStatus(
              status,
              progress?.percentOfTasksCompletedPreonboarding ?? null,
              progress?.percentOfTasksCompletedOnboarding ?? null
            )}
            width={56}
            status={status}
          />,
          toggleUserActiveStatus(item),
          <Box minWidth={'100px'}>
            {hasUserClaimToEdit && !userHasSuperiorRole && (
              <Tooltip title={`${t('users.editUserScript')}`}>
                <IconButton onClick={() => tableAction(item, TableUserActions.EDIT_TEMPLATE)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {hasUserClaimToEdit &&
            <Tooltip title={`${t('users.editUserOnboardingScenario')}`}>
              <IconButton onClick={() => tableAction(item, TableUserActions.VIEW_ASSIGNED_TASKS)}>
                <PlaylistAddCheckOutlinedIcon sx={{ color: '#111826' }} />
              </IconButton>
            </Tooltip>
            }
            {hasUserClaimToEdit &&
            <Tooltip title={`${t('users.previewUserScript')}`}>
              <IconButton onClick={() => tableAction(item, TableUserActions.VIEW_TEMPLATE)}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
            }
          </Box>,
        ],
        expansion: (
          <UserExpansionTable
            id={item.userId}
            office={item.office ?? ''}
            status={item.status}
            progress={item.progress}
          />
        ),
      });
    });
    setRows(tempRows);
  };
  useEffect(() => {
    convertDataToRows(data);
  }, [data]);
  return {
    rows,
    columns,
  };
};
