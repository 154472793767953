import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const FaqManageView: IView = {
  key: 'ViewFaqManage',
  title: 'faqView.titleManage',
  view: React.lazy(() => import('./manage')),
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  path: '/faq-zarzadzanie',
};

export default FaqManageView;
