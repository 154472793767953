import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/common';
import { ITasks } from 'interfaces/tasks.interface';
import { RootState } from 'store';
import { getOnboardingTasks } from './onboardingTasksAPI';
import { initialState } from './onboardingTasksInitial';
import { IResponseWithPagination } from 'interfaces/common';

export interface onboardingTasksState {
  loading: 'idle' | 'pending';
  error?: SerializedError;
  data: IResponseWithPagination<ITasks[]>;
  currentRequestId?: string;
}

export const fetchOnboardingTasks = createAsyncThunk('onboardingTasks/fetch', async (params?: object) => {
  try {
    const response = await getOnboardingTasks(params);
    return response.data.value;
  } catch (err) {
    return err;
  }
});

export const onboardingTasksSlice = createSlice({
  name: 'onboardingTasks',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.data.page = action.payload + 1;
    },
    newPageSize: (state, action) => {
      state.data.size = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingTasks.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchOnboardingTasks.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.data = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchOnboardingTasks.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectOnboardingTasks = (state: RootState) => state.onboardingTasks;

export default onboardingTasksSlice.reducer;
