import { AxiosError, AxiosRequestConfig } from 'axios';
import { defaultConfig } from 'config/config';
import { API_URL } from 'config/const';

import { appService } from './index';

const setUpInterceptor = (store: any) => {
  appService.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem(defaultConfig.KEY_TOKEN)}`;
      }
      return config;
    },
    (error: AxiosError) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem(defaultConfig.KEY_TOKEN);
        window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
      } else {
        return Promise.reject(error);
      }
    }
  );

  appService.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem(defaultConfig.KEY_TOKEN);
        window.location.assign(`${API_URL}auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
      }
      return Promise.reject(error);
    }
  );
};

export default setUpInterceptor;
