import React from 'react';
import { Typography, TypographyProps, Theme, Box } from '@mui/material';

interface IPageTitleProps {
  title: string;
  props?: TypographyProps;
  subtitle?: string;
  center?: boolean;
}

export const PageTitle = ({ title, subtitle, props, center }: IPageTitleProps) => {
  return (
    <Box mt={10} sx={{ textAlign: center ? 'center' : 'left' }} data-id={'page-title'}>
      {subtitle && (
        <Typography mb={2} variant={'body1'}>
          {subtitle}
        </Typography>
      )}
      <Typography variant="h3" {...props} mb={5}>
        {title}
      </Typography>
    </Box>
  );
};
