import { Typography, Stack, Theme } from '@mui/material';
import { TitleWithIcon } from 'components/common';
import { ContactItem, IInfoCardProps } from 'interfaces/user.interface';
import { useDictionaries } from 'hooks/useDictionaries';
import { handleUsDate } from 'utils/common';

export const InfoCard = ({ title, icon, contactItems }: IInfoCardProps) => {
  const { getDictionaryValue } = useDictionaries();
  return (
    <Stack spacing={3}>
      {title && icon && <TitleWithIcon icon={icon} title={title} />}
      <Stack spacing={2}>
        {contactItems.map((item: ContactItem) => 
          item.text ? (
            <Stack spacing={0} key={item.label}>
              <Typography variant="subtitle2" sx={(theme: Theme) => ({ color: theme.palette.grey[800] })}>
                {item.label}
              </Typography>
              <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                {item.dictionaryInfo
                  ? getDictionaryValue(item.dictionaryInfo.dictionaryName, item.dictionaryInfo.dictionaryValue)
                  : handleUsDate(item.text)}
              </Typography>
            </Stack>
          ) : item.element ? (
            item.element
          ) : (
            <Stack spacing={0} key={item.label}>
              <Typography variant="subtitle2" sx={(theme: Theme) => ({ color: theme.palette.grey[800] })}>
                {item.label}
              </Typography>
              <Typography variant="body1" sx={{ height: '23px' }}></Typography>
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};
