import React, { useEffect, useState, useRef } from 'react';
import './progressWheel.css';

interface IProgressBarProps {
  size: number;
  strokeWidth: number;
  progress: number;
  circleOneStroke: string;
  circleTwoStroke: string;
}

export const ProgressWheel = ({ size, strokeWidth, progress, circleOneStroke, circleTwoStroke }: IProgressBarProps) => {
  const [offset, setOffset] = useState<number>(0);
  const circleRef = useRef<any>(null);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    if (circleRef.current) {
      circleRef.current.style = 'transition stroke-dashoffset 850ms ease-in-out;';
    }
  }, [setOffset, circumference, progress, offset]);

  return (
    <>
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
        <text className="svg-circle-text" x={`${center}`} y={`${center + 10}`}>
          {progress}%
        </text>
      </svg>
    </>
  );
};
