import React from 'react';
import { CardMedia, Box, IconButton, CardActions, Button, Theme } from '@mui/material';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import FileIcon from 'assets/icons/FileIcon.svg';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

interface IProps {
  onDownload: () => void;
}

export const DownloadFileCard: React.FC<IProps> = ({ onDownload }) => {
  return (
    <>
      <CardMedia
        component="img"
        image={FileIcon}
        alt="random icon"
        sx={(theme: Theme) => ({
          transform: 'translateY(13px)',
          width: 72,
          height: 92,
          mb: 5,
        })}
      />
      <Box position="absolute" top={127} left={130}>
        {/* <IconButton
          aria-label="show document"
          sx={(theme: Theme) => ({
            bgcolor: theme.palette.grey[300],
            width: 32,
            height: 32,
            '&:hover': {
              bgcolor: theme.palette.grey[300],
            },
          })}
        >
          <RemoveRedEyeOutlinedIcon />
        </IconButton> */}
      </Box>
      <CardActions
        sx={{
          p: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Button
          fullWidth
          sx={{ borderRadius: 0, height: 74, fontSize: 16 }}
          variant="contained"
          color="secondary"
          onClick={onDownload}
          startIcon={<DownloadIcon />}
        >
          Pobierz dokument
        </Button>
      </CardActions>
    </>
  );
};
