import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const OnboardingCreateUserView: IView = {
  key: 'viewOnboardingCreateUser',
  title: 'preonboardingTemplateUser.addUserView.title',
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  view: React.lazy(() => import('./create-user')),
  path: ['/dodaj-uzytkownika', '/dodaj-uzytkownika/:userId'],
};

export default OnboardingCreateUserView;
