import React from 'react';
import { Breadcrumbs, Link, Typography, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SingleBreadcrumbItem } from 'interfaces/common';

interface ICustomBreadcrumbProps {
  breadcrumbList: SingleBreadcrumbItem[];
}

export const CustomBreadcrumb = ({ breadcrumbList }: ICustomBreadcrumbProps) => {
  const fontColor = (theme: Theme) => theme.palette.grey[700];
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ width: 16, height: 16 }} />}
      aria-label="breadcrumb"
      sx={{
        mt: (theme: Theme) => theme.spacing(2),
      }}
    >
      {breadcrumbList &&
        breadcrumbList.map((item: SingleBreadcrumbItem, index: number) =>
          item.isLink ? (
            <Link key={index} component={RouterLink} to={item.url} underline="hover" variant="subtitle2">
              <Typography variant="subtitle2" color={fontColor}>
                {item.title}
              </Typography>
            </Link>
          ) : (
            <Typography variant="subtitle2" color={fontColor} key={index}>
              {item.title}
            </Typography>
          )
        )}
    </Breadcrumbs>
  );
};
