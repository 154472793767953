import { Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog, CustomInput } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IAddTagDialogProps {
  open: boolean;
  onClose: () => void;
  submitFunction: (data: string) => void;
}
interface IFormInput {
  name: string;
}
export const AddTagDialog = ({ open, onClose, submitFunction }: IAddTagDialogProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { name } = data;
    if (name) {
      submitFunction(name);
      handleClose();
    }
  };
  const handleClose = () => {
    reset();
    onClose();
  };
  return (
    <CustomDialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('knowledgePages.dialogs.addTag')}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => <CustomInput type="text" value={value} onChange={onChange} />}
          />
          {errors.name && (
            <Typography variant="subtitle1" color="error">
              {t('faqView.errors.noEmptyField')}
            </Typography>
          )}
          <CustomButton
            type="submit"
            text={t('button.send')}
            onClick={() => null}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            sx={{
              width: 1,
              mt: 3,
            }}
          />
        </Stack>
      </form>
    </CustomDialog>
  );
};
