import { Card, CardContent, Divider, Theme, Typography, Box } from '@mui/material';
import React from 'react';
import { ProfileAvatar } from './components/profile-avatar';
import randomFace from 'assets/images/randomFace.jpg';

export const UserCard = () => {
  return (
    <Card
      sx={(theme: Theme) => ({
        width: 240,
        height: 352,
        bgcolor: theme.palette.grey[100],
        padding: theme.spacing(5),
        boxShadow: 0,
      })}
    >
      <CardContent>
        <ProfileAvatar avatarUrl={randomFace} />
        <Typography variant="h5" sx={(theme: Theme) => ({ mb: theme.spacing(3), fontWeight: 400 })}>
          Justyna Michałowska
        </Typography>
        <Divider />
        <Box sx={(theme: Theme) => ({ mt: theme.spacing(3) })}>
          <Typography variant="subtitle2">
            Account Manager <span> 24%</span>
          </Typography>
          <Typography variant="subtitle2">imie.nazwisko@adres.com</Typography>
          <Typography variant="subtitle2">+48 488 488 488</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
