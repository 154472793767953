import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog } from 'components/common';
import { ButtonType, ButtonSize } from 'interfaces/button.interface';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IRoles } from 'interfaces/roles.interface';
import { IChangeUserRoles, IUserOnboardingData } from 'interfaces/user.interface';
import { useEffect, useState } from 'react';

interface IChangeRoleDialogProps {
  open: boolean;
  editUser: IUserOnboardingData | undefined;
  onClose: () => void;
  onSubmit: (data: IChangeUserRoles) => void;
}

export const ChangeRoleDialog = ({ open, onClose, onSubmit, editUser }: IChangeRoleDialogProps) => {
  const { t } = useTranslation();
  const [confirmChange, setConfirmChange] = useState<boolean>(false);
  const { control, handleSubmit, reset, getValues } = useForm<IChangeUserRoles>();
  const handleClose = () => {
    onClose();
  };

  const localSubmit = (data: IChangeUserRoles) => {
    if (data) {
      setConfirmChange(true);
    }
  };
  const submitChanges = () => {
    if (confirmChange && editUser) {
      setConfirmChange(false);
      onSubmit(getValues());
      onClose();
    }
  };

  useEffect(() => {
    reset({
      roleIds: editUser?.roles.map((role) => role.roleId) ?? [],
    });
  }, [editUser]);

  return (
    <>
      <CustomDialog open={open} onClose={handleClose}>
        <Stack component={'form'} onSubmit={handleSubmit(localSubmit)} spacing={3}>
          <Typography variant="h3">{t('users.changeRoleDialog.title')}</Typography>
          <Typography variant="body1">{t('users.changeRoleDialog.info')}</Typography>
          <Typography variant="body1">{t('users.changeRoleDialog.disclamer')}</Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            {t('registerView.pickRoles')}
          </Typography>
          <Controller
            name="roleIds"
            rules={{
              required: true,
            }}
            control={control}
            defaultValue={
              editUser?.roles && editUser?.roles.length > 0 ? editUser?.roles.map((role) => role.roleId) : []
            }
            render={({ field }) => (
              <Select multiple {...field}>
                <MenuItem value={IRoles.EMPLOYEE} disabled>
                  {t('roles.Employee')}
                </MenuItem>
                <MenuItem value={IRoles.BUDDY}>{t('roles.Buddy')}</MenuItem>
                <MenuItem value={IRoles.SUPERIOR}>{t('roles.Superior')}</MenuItem>
                <MenuItem value={IRoles.HR}>{t('roles.HR')}</MenuItem>
              </Select>
            )}
          />
          <CustomButton
            text={t('button.confirm')}
            buttonType={ButtonType.SECONDARY_ACCENT}
            size={ButtonSize.SMALL}
            onClick={() => null}
            type={'submit'}
            sx={{
              width: 1,
              mb: 2,
            }}
          />
          <CustomButton
            text={t('button.cancel')}
            buttonType={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => onClose()}
            sx={{
              width: 1,
            }}
          />
        </Stack>
      </CustomDialog>
      <CustomDialog open={confirmChange} onClose={() => setConfirmChange(false)}>
        <Stack spacing={3}>
          <Typography variant="body1">
            {t('registerView.roleChangePopupMessage', { name: `${editUser?.userFirstName} ${editUser?.userLastName}` })}
          </Typography>
          <Typography variant="subtitle1">{t('registerView.roleChangeDisclaimer')}</Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <CustomButton
              text={t('common.yesIDo')}
              onClick={submitChanges}
              buttonType={ButtonType.PRIMARY}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
            <CustomButton
              text={t('button.cancel')}
              onClick={() => setConfirmChange(false)}
              buttonType={ButtonType.SECONDARY_ACCENT}
              size={ButtonSize.SMALL}
              sx={{
                width: 1,
              }}
            />
          </Stack>
        </Stack>
      </CustomDialog>
    </>
  );
};
