import { usersListState } from './usersListSlice';

export const initialState: usersListState = {
  loading: 'idle',
  sort: 'none',
  data: {
    records: [],
    recordsCount: 0,
    page: 1,
    size: 10,
  },
};
