import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AgreeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4925 0.419621C21.7854 0.712514 21.7854 1.18739 21.4925 1.48028L9.39246 13.5803C9.25181 13.7209 9.06105 13.8 8.86213 13.8C8.66322 13.8 8.47245 13.7209 8.3318 13.5803L0.505803 5.75428C0.21291 5.46139 0.21291 4.98651 0.505803 4.69362C0.798696 4.40073 1.27357 4.40073 1.56646 4.69362L8.86213 11.9893L20.4318 0.419621C20.7247 0.126728 21.1996 0.126728 21.4925 0.419621Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
