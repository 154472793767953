import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewTasks: IView = {
  key: 'viewTasks',
  title: 'tasks.title',
  roles: [IRoles.BUDDY, IRoles.SUPERIOR, IRoles.EMPLOYEE, IRoles.NEWEMPLOYEE],
  navigation: true,
  view: React.lazy(() => import('./tasks-page')),
  path: '/zadania',
};

export default ViewTasks;
