import { onboardingKnowledgePagesState } from './onboardingKnowledgePagesSlice';

export const initialState: onboardingKnowledgePagesState = {
  loading: 'idle',
  data: {
    onboardingKnowledgePages: {
      records: [],
      recordsCount: 0,
      page: 1,
      size: 10,
    },
    tags: [],
  },
};
