import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilterBarsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 3.125C9.42462 3.125 8.95825 3.59137 8.95825 4.16667C8.95825 4.74196 9.42462 5.20833 9.99992 5.20833C10.5752 5.20833 11.0416 4.74196 11.0416 4.16667C11.0416 3.59137 10.5752 3.125 9.99992 3.125ZM7.79452 3.54167C8.06657 2.57979 8.95094 1.875 9.99992 1.875C11.0489 1.875 11.9333 2.57979 12.2053 3.54167H16.6666C17.0118 3.54167 17.2916 3.82149 17.2916 4.16667C17.2916 4.51184 17.0118 4.79167 16.6666 4.79167H12.2053C11.9333 5.75354 11.0489 6.45833 9.99992 6.45833C8.95094 6.45833 8.06657 5.75354 7.79452 4.79167L3.33325 4.79167C2.98807 4.79167 2.70825 4.51185 2.70825 4.16667C2.70825 3.82149 2.98807 3.54167 3.33325 3.54167L7.79452 3.54167ZM14.9999 8.95833C14.4246 8.95833 13.9583 9.4247 13.9583 10C13.9583 10.5753 14.4246 11.0417 14.9999 11.0417C15.5752 11.0417 16.0416 10.5753 16.0416 10C16.0416 9.4247 15.5752 8.95833 14.9999 8.95833ZM12.7945 9.375C13.0666 8.41312 13.9509 7.70833 14.9999 7.70833C16.2656 7.70833 17.2916 8.73435 17.2916 10C17.2916 11.2657 16.2656 12.2917 14.9999 12.2917C13.9509 12.2917 13.0666 11.5869 12.7945 10.625H3.33325C2.98807 10.625 2.70825 10.3452 2.70825 10C2.70825 9.65482 2.98807 9.375 3.33325 9.375H12.7945ZM4.99992 14.7917C4.42462 14.7917 3.95825 15.258 3.95825 15.8333C3.95825 16.4086 4.42462 16.875 4.99992 16.875C5.57522 16.875 6.04159 16.4086 6.04159 15.8333C6.04159 15.258 5.57522 14.7917 4.99992 14.7917ZM2.70825 15.8333C2.70825 14.5677 3.73427 13.5417 4.99992 13.5417C6.0489 13.5417 6.93327 14.2465 7.20532 15.2083H16.6666C17.0118 15.2083 17.2916 15.4882 17.2916 15.8333C17.2916 16.1785 17.0118 16.4583 16.6666 16.4583H7.20532C6.93327 17.4202 6.0489 18.125 4.99992 18.125C3.73427 18.125 2.70825 17.099 2.70825 15.8333Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
