import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PrintIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 34 34">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75909 2.09243C9.61864 1.23289 10.7844 0.75 12 0.75H22C23.2156 0.75 24.3814 1.23289 25.2409 2.09243C26.1004 2.95197 26.5833 4.11776 26.5833 5.33333V10.75H28.6667C29.8822 10.75 31.048 11.2329 31.9076 12.0924C32.7671 12.952 33.25 14.1178 33.25 15.3333V22C33.25 23.2156 32.7671 24.3814 31.9076 25.2409C31.048 26.1004 29.8822 26.5833 28.6667 26.5833H26.5833V28.6669C26.5833 31.1982 24.5313 33.2502 22 33.2502H12C9.46869 33.2502 7.41667 31.1982 7.41667 28.6669V26.5833H5.33333C4.11776 26.5833 2.95197 26.1004 2.09243 25.2409C1.23289 24.3814 0.75 23.2156 0.75 22V15.3333C0.75 14.1178 1.23289 12.952 2.09243 12.0924C2.95197 11.2329 4.11776 10.75 5.33333 10.75H7.41667V5.33333C7.41667 4.11776 7.89955 2.95197 8.75909 2.09243ZM9.91667 28.6669C9.91667 29.8175 10.8494 30.7502 12 30.7502H22C23.1506 30.7502 24.0833 29.8175 24.0833 28.6669V22.0002C24.0833 20.8496 23.1506 19.9169 22 19.9169H12C10.8494 19.9169 9.91667 20.8496 9.91667 22.0002V28.6669ZM26.5833 24.0833V22.0002C26.5833 19.4689 24.5313 17.4169 22 17.4169H12C9.46869 17.4169 7.41667 19.4689 7.41667 22.0002V24.0833H5.33333C4.7808 24.0833 4.2509 23.8638 3.86019 23.4731C3.46949 23.0824 3.25 22.5525 3.25 22V15.3333C3.25 14.7808 3.46949 14.2509 3.86019 13.8602C4.2509 13.4695 4.7808 13.25 5.33333 13.25L8.66667 13.25H25.3333L28.6667 13.25C29.2192 13.25 29.7491 13.4695 30.1398 13.8602C30.5305 14.2509 30.75 14.7808 30.75 15.3333V22C30.75 22.5525 30.5305 23.0824 30.1398 23.4731C29.7491 23.8638 29.2192 24.0833 28.6667 24.0833H26.5833ZM24.0833 5.33333V10.75H9.91667V5.33333C9.91667 4.7808 10.1362 4.25089 10.5269 3.86019C10.9176 3.46949 11.4475 3.25 12 3.25H22C22.5525 3.25 23.0824 3.46949 23.4731 3.86019C23.8638 4.2509 24.0833 4.7808 24.0833 5.33333Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
