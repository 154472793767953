import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { IUserOnboardingDetails } from 'interfaces/user.interface';
import { RootState } from 'store';
import { initialState } from './userInitial';
import {
  getUserDetailsAPI,
  getCurrentUserOnboardingDetailsAPI,
  putCurrentUserOnboardingDetailsAPI,
  getCurrentUserRolesAPI,
} from './userAPI';

export interface userState {
  token: string;
  loading: 'idle' | 'pending';
  isAuth?: boolean;
  error?: SerializedError;
  details: IUserOnboardingDetails;
  currentRequestId?: string;
}

interface IPutExtraDetails {
  isSubmitUserForm?: boolean;
  content: {
    [k: string]: string | boolean | number;
  };
}

export const fetchUserDetails = createAsyncThunk('users/fetchCurrentUser', async (_, { rejectWithValue }) => {
  const response = await getUserDetailsAPI();
  const responseRole = await getCurrentUserRolesAPI(response.data.value.id);
  return {
    ...response.data.value,
    roles: responseRole.data.value?.records,
  };
});

export const fetchCurrentUserOnboardingDetails = createAsyncThunk(
  'users/fetchCurrentUserOnboardingDetails',
  async (_, { rejectWithValue }) => {
    try {
      const detailsResponse = await getCurrentUserOnboardingDetailsAPI();
      return {
        details: detailsResponse.data.value,
      };
    } catch (err) {
      rejectWithValue(false);
      return false;
    }
  }
);

export const putCurrentUserOnboardingDetails = createAsyncThunk(
  'users/putCurrentUserOnboardingDetails',
  async ({ content, isSubmitUserForm }: IPutExtraDetails, { rejectWithValue }) => {
    try {
      const response = await putCurrentUserOnboardingDetailsAPI(content, isSubmitUserForm);
      return response.data.value;
    } catch (err) {
      rejectWithValue(false);
      return false;
    }
  }
);

export const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokenUser(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    clear(state) {
      state === initialState;
    },
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get user details
      .addCase(fetchUserDetails.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.isAuth = true;
          state.details = {
            ...state.details,
            ...action.payload,
          };
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.isAuth = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // Get user extra details
      .addCase(fetchCurrentUserOnboardingDetails.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchCurrentUserOnboardingDetails.fulfilled, (state, action: any) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.details.extraDetails = action.payload.details;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchCurrentUserOnboardingDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // Put user extra details
      .addCase(putCurrentUserOnboardingDetails.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(putCurrentUserOnboardingDetails.fulfilled, (state, action) => {
        const { requestId, arg } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          if (action.meta.arg) {
            state.details.extraDetails.content = action.meta.arg.content as any;
            state.details.isSubmitUserForm = true;
          }
          state.currentRequestId = undefined;
        }
      })
      .addCase(putCurrentUserOnboardingDetails.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const selectUser = (state: RootState) => state.user;
export const { setTokenUser, setAuth, clear } = actions;

export default reducer;
