import React, { useEffect, useMemo, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { centerFlex } from 'common-styles/common-styles';
import { MediaCustom } from '../media';
import { CustomDataPicker } from './custom-data-uploader';
import { appService } from 'services';
import { getFiles } from 'store/files/filesAPI';
import { IResponse } from 'interfaces/common';
import { CustomDialog } from '../custom-dialog';
import { useToggle } from 'hooks/useToggle';
import { ButtonSize, ButtonType } from 'interfaces/button.interface';
import { CustomButton } from '../custom-button';
import CustomInput from '../custom-input';
import { YouTubeFrame } from '../youtube-frame';
import { YOUTUBE_EMBED } from 'config/const';
import { useTranslation } from 'react-i18next';
// import { useSnackbar } from 'notistack';
import { useFileTags } from 'hooks';

export enum IFileType {
  VIDEO = 'Video',
  IMAGE = 'Photo',
  YOUTUBE = 'Youtube',
}

interface IProps {
  value?: File | string;
  onChange: (file: File | string | undefined) => void;
  label: string;
  defaultValue: string;
  predefinedTagIds: string[];
}

export const MediaUploader: React.FC<IProps> = ({ defaultValue, onChange, value, predefinedTagIds }) => {
  const [openUrl, setOpenUrl] = useToggle();
  // const { enqueueSnackbar } = useSnackbar();

  const [url, setUrl] = useState<string>('');
  const [defaultItems, setDefaultItems] = useState<any[]>([]);
  const { selectedTags } = useFileTags(predefinedTagIds);
  const fetchDefaultValues = () => {
    if (defaultValue) {
      if (!isYoutubeString) {
        setDefaultValues();
      }
    }
  };
  const { t } = useTranslation();

  const checkValueCurrent = (currentValue: string) => currentValue.includes(YOUTUBE_EMBED);
  const checkType = (currentValue: File | string) => typeof currentValue === 'string' && currentValue;
  const isString = useMemo(() => value && checkType(value), [value]);
  const isYoutubeString = useMemo(() => (isString ? checkValueCurrent(isString) : value), [value]);

  const setDefaultValues = async () => {
    try {
      const {
        data: { value },
      } = await appService.get('/files-manage/ids?FileIds=' + defaultValue);

      const {
        data: { value: valueUrl },
      } = await appService.get<IResponse<string>>('/files/url/' + defaultValue);

      if (value) {
        setDefaultItems([
          {
            ...value[0],
            fetchUrl: valueUrl,
          },
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const submitUrl = () => {
    onChange(url);
    setOpenUrl();
  };

  useEffect(() => {
    fetchDefaultValues();
  }, []);

  const resetValue = () => {
    onChange(undefined);
    setDefaultItems([]);
  };
  return (
    <Box
      display={'flex'}
      sx={{
        flexDirection: !value && !isYoutubeString ? 'row' : 'column',
      }}
    >
      {!isYoutubeString ? (
        <>
          <CustomDataPicker
            oneChoice={true}
            fetch={getFiles}
            onRemove={resetValue}
            hiddenButton={!!isYoutubeString}
            defaultValue={defaultItems}
            buttonAddText={t('button.addfilejpg')}
            removeChoice={true}
            requireTags
            searchTags={selectedTags}
            itemListLabel={(item: any) => (
              <Box key={item.id} display={'flex'}>
                <Typography
                  sx={(theme) => ({
                    width: 300,
                    mr: theme.spacing(2),
                    wordWrap: 'break-word',
                  })}
                  mr={(theme) => theme.spacing(2)}
                >
                  {t('commonTable.name')}: {item.originalFileName}
                </Typography>
                <Typography sx={{ ...centerFlex, mr: '20px' }}>
                  {t('commonTable.size')}: {item.size}
                </Typography>
                <Typography sx={{ ...centerFlex, mr: '20px' }}>
                  {t('commonTable.type')}: {item.fileType}
                </Typography>
                <Box>
                  <Link
                    target={'_blank'}
                    href={item.url}
                    sx={{ ...centerFlex, mr: '20px' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t('button.review')}
                  </Link>
                </Box>
              </Box>
            )}
            itemSelected={(item: any) => {
              onChange(item.id);
              return <MediaCustom src={item.fetchUrl ?? item.url} type={item.fileType} />;
            }}
          />
          {!value && (
            <CustomButton
              buttonType={ButtonType.SECONDARY_ACCENT}
              text={`+ ${t('button.addUrlFile')}`}
              sx={{ fontSize: '14px', mx: '16px' }}
              size={ButtonSize.SMALL}
              onClick={setOpenUrl}
            />
          )}
          <CustomDialog open={openUrl} onClose={setOpenUrl}>
            <DialogTitle sx={{ fontSize: '30px' }}>Dodaj url filmu</DialogTitle>
            <DialogContent sx={{ minWidth: '400px' }}>
              <Typography>Wklej tutaj link filmu z YouTube</Typography>
              <Typography variant="caption" sx={(theme) => ({ mb: theme.spacing(2), display: 'block' })}>
                na przykład: https://www.youtube.com/watch?v=123456789
              </Typography>
              <CustomInput
                onChange={(event) => setUrl(event.currentTarget.value)}
                value={url}
                label={'URL filmu'}
                type={'text'}
              />
            </DialogContent>
            <DialogActions>
              <CustomButton
                buttonType={ButtonType.SECONDARY_ACCENT}
                text={t('button.saveAs')}
                sx={{ fontSize: '14px' }}
                size={ButtonSize.SMALL}
                onClick={submitUrl}
              />
              <CustomButton
                buttonType={ButtonType.PRIMARY}
                text={t('button.back')}
                sx={{ fontSize: '14px' }}
                size={ButtonSize.SMALL}
                onClick={setOpenUrl}
              />
            </DialogActions>
          </CustomDialog>
        </>
      ) : (
        <>
          <YouTubeFrame url={typeof value === 'string' ? value : ''} />
        </>
      )}
      {isYoutubeString && (
        <CustomButton
          text={t('button.delete')}
          buttonType={ButtonType.PRIMARY}
          onClick={resetValue}
          sx={{ fontSize: '14px', width: '100px', mt: '16px' }}
          size={ButtonSize.SMALL}
        />
      )}
    </Box>
  );
};
