import { IView } from 'interfaces/common';
import UserPreonboardingView from 'views/Welcome-page/views/home-employee';
import UsersAssignHomeView from 'views/Welcome-page/views/home-assigns';
import AuthView from 'views/Auth';
import FaqView from 'views/FAQ';
import FaqManageView from 'views/FAQ/views/manage';
import FaqCreateView from 'views/FAQ/views/create';
import ContentView from 'views/ContentPage';
import Error from 'views/Error';
import AddFileView from 'views/Settings/views/Files-manage/views/add';
import ViewUserAssignBuddy from 'views/Users-page/views/assign-buddy';
import ViewUserAssignResources from 'views/Users-page/views/assign-superior';
import ViewUserAssignSuperior from 'views/Users-page/views/assign-resources';
import ViewUserSingle from 'views/Users-page/views/single-user';
import UsersView from 'views/Users-page/';
import ViewFinishOnboarding from 'views/Users-page/views/finish-onboarding';
import Main from 'views/Main';
import TaskView from 'views/Tasks-page';
import SettingsView from 'views/Settings';
import TaskViewPreview from 'views/Tasks-page/views/task-preview';
import TaskViewUser from 'views/Tasks-page/views/mission';
import QuizUserView from 'views/Tasks-page/views/quiz';
import TaskViewStatus from 'views/Tasks-status';
import TaskManageView from 'views/Tasks-manage';
import TaskManageViewAdd from 'views/Tasks-manage/views/create-task-view';
import ViewPreviewQuizManage from 'views/Tasks-manage/views/preview-quiz-view';
import KnowledgeView from 'views/Knowledge-page';
import KnowledgeViewCreate from 'views/Knowledge-page/views/create-page';
import KnowledgeViewBase from 'views/Knowledge-page/views/knowledge-base';
import KnowledgeViewSingle from 'views/Knowledge-page/views/single-knowledge';
import FileTemplateAddView from 'views/Settings/views/Template-files-manage/views/add';
import BadgeAddView from 'views/Settings/views/Badges/views/add';
import OnboardingUserDetailsView from 'views/Onboarding/template/details';
import OnboardingUserCreateView from 'views/Onboarding/template/create';
import OnboardingUserTemplateView from 'views/Onboarding/views/edit-template';
import OnboardingUserTaskTemplateView from 'views/Onboarding/views/edit-tasks';
import OnboardingUserPreviewView from 'views/Onboarding/template/preview';
import OnboardingAssignTaskView from 'views/Onboarding/template/tasks';
import OnboardingUserStatusView from 'views/Onboarding/template/status';
import OnboardingUserVerificationView from 'views/Onboarding/template/verification';
import OnboardingTemplateEditView from 'views/Onboarding/template/edit-template';
import OnboardingTemplateUserEditView from 'views/Onboarding/views/edit-user-template';
import OnboardingTemplatePreviewView from 'views/Onboarding/views/preview-template';
import OnboardingTemplateUserPreviewView from 'views/Onboarding/views/preview-user-template';
import OnboardingTemplateManage from 'views/Onboarding/views/manage-templates';
import SchemaAddView from './Settings/views/DetailsSchema/views/add';
import ThemeManage from './Settings/views/ThemeManage';
import ViewPreviewSurveyManage from './Tasks-manage/views/preview-survey-view';
import SurveyView from './Tasks-page/views/survey';
import ViewEditUserData from './Users-page/views/edit-user-data';
import ScenariosListView from './Scenarios-list';
import OnboardingTasksView from './Onboarding/views/onboarding-tasks';

const viewsApp: IView[] = [
  AuthView,
  Error,
  ViewPreviewSurveyManage,
  FaqManageView,
  SurveyView,
  BadgeAddView,
  SchemaAddView,
  TaskViewUser,
  TaskViewPreview,
  ThemeManage,
  TaskViewStatus,
  AddFileView,
  UserPreonboardingView,
  UsersView,
  KnowledgeView,
  TaskView,
  ContentView,
  ScenariosListView,
  TaskManageViewAdd,
  ViewFinishOnboarding,
  TaskManageView,
  ViewPreviewQuizManage,
  OnboardingUserCreateView,
  FileTemplateAddView,
  OnboardingUserDetailsView,
  OnboardingUserTemplateView,
  OnboardingUserTaskTemplateView,
  OnboardingUserPreviewView,
  UsersAssignHomeView,
  OnboardingUserStatusView,
  OnboardingUserVerificationView,
  OnboardingAssignTaskView,
  OnboardingTemplateEditView,
  OnboardingTemplateManage,
  ViewUserAssignBuddy,
  ViewUserAssignResources,
  ViewUserAssignSuperior,
  ViewUserSingle,
  KnowledgeViewCreate,
  KnowledgeViewBase,
  KnowledgeViewSingle,
  FaqView,
  FaqCreateView,
  SettingsView,
  QuizUserView,
  Main,
  ViewEditUserData,
  OnboardingTemplateUserEditView,
  OnboardingTemplatePreviewView,
  OnboardingTemplateUserPreviewView,
  OnboardingTasksView,
];
export default viewsApp;
