import { ITemplateSection } from 'interfaces/onboarding-templates.interface';
import { defaultLeftTextAndImage, defaultRightTextAndImage } from 'views/Onboarding/dialogs/add-section';

const findInEntries = (object: any, equal: string) => {
  const entriesObject = Object.entries(object);
  return entriesObject.find(([objectName]) => objectName === equal);
};

export const parseFormDataToTemplate = (formUserTemplate: any, setDefault?: boolean) =>
  Object.entries(formUserTemplate).reduce((prev: any, [sectionName, rowsObject]: any) => {
    const flatFields = Object.values(rowsObject).flatMap((fields: any) => Object.entries(fields));
    const currentValue = {
      name: sectionName,
      fields: [
        ...flatFields.map(([fieldName, fieldValue]) => {
          return {
            name: fieldName,
            [setDefault ? 'defaultValue' : 'value']: fieldValue,
          };
        }),
      ],
    };
    prev.push(currentValue);
    return prev;
  }, []);

export const parseFormDataToEditTemplate = (templateSections: ITemplateSection[], templateSubmitData: any) =>
  templateSections.reduce((previousValue: ITemplateSection[], templateItem: any) => {
    const currentTemplate = findInEntries(templateSubmitData, templateItem.name);
    if (currentTemplate) {
      const [_, rowsTemplate] = currentTemplate;
      const filledFields = [
        ...templateItem.fields.map((field: any) => {
          if (field?.row) {
            const currentRow = findInEntries(rowsTemplate, 'row-' + field?.row);
            if (currentRow) {
              const [_, fieldsTemplate] = currentRow;
              const currentField = findInEntries(fieldsTemplate, field?.name);
              if (currentField) {
                return {
                  ...field,
                  defaultValue: currentField[1],
                };
              } else {
                return {
                  ...field,
                };
              }
            } else {
              return field;
            }
          } else {
            return field;
          }
        }),
      ];
      previousValue.push({
        ...templateItem,
        fields: [...filledFields],
      });
    } else {
      previousValue.push({
        ...templateItem,
      });
    }
    return previousValue;
  }, []);

export const mapTemplates = (template: ITemplateSection[], currentTemplate: ITemplateSection[]) =>
  template.map((section) => {
    const currentSectionTemplate = currentTemplate.find((value) => value.name === section.name);
    const newFields = section.fields.map((field) => {
      const currentField = currentSectionTemplate?.fields.find((fieldItem) => fieldItem.name === field.name);
      return {
        ...field,
        defaultValue: currentField?.defaultValue ?? currentField?.value ?? '',
      };
    });
    return {
      ...currentSectionTemplate,
      ...section,
      fields: [...newFields],
    };
  });

export const mapViewTemplate = (template: ITemplateSection[]) =>
  template.map((section) => {
    const newFields = section.fields.map((field) =>
      field
        ? {
            ...field,
            value: field.defaultValue,
          }
        : field
    );
    return {
      ...section,
      fields: [...newFields],
    };
  });

export const reduceFields = (fields: any[], nameOf: string) =>
  fields.reduce<string[]>((prev: string[], next) => {
    if ('step-' !== nameOf) {
      if (next.name.indexOf(nameOf) > -1) {
        prev.push(next.value as string);
      }
    } else {
      if (next.name.indexOf(nameOf) > -1 && next.name !== 'step-') {
        prev.push(next.value as string);
      }
    }
    return prev;
  }, []);

export const reduceFieldsObjects = (fields: any[], nameOf: string) =>
  fields.reduce((prev: any[], [nextKey, nextValue]: any) => {
    if (nextKey.indexOf(nameOf) > -1) {
      prev.push(nextValue);
    }
    return prev;
  }, []);

export const compareTemplateSections = (parseTemplate: any, sections: any) => {
  const parsedTemplates = parseTemplate.sections.map((sectionValue: ITemplateSection) => {
    const currentFields = sectionValue.fields.map((fieldValue) => {
      const selectSection = sections.find((section: any) => section.name === sectionValue.name);
      if (selectSection && fieldValue) {
        const selectFields = selectSection.fields.find((field: any) => field.name === fieldValue.name);
        if (selectFields) {
          return {
            ...fieldValue,
            defaultValue: selectFields.value,
          };
        } else {
          return {
            ...fieldValue,
          };
        }
      } else {
        return {
          ...fieldValue,
        };
      }
    });
    return {
      ...sectionValue,
      fields: [...currentFields],
    };
  });
  const customSections = sections.filter((section: any) => section.name.indexOf('section-custom') > -1);
  return [
    ...parsedTemplates,
    ...customSections.map((custom: any) => {
      const newSection = custom.name === 'section-custom-1' ? defaultLeftTextAndImage : defaultRightTextAndImage;
      return {
        ...newSection,
        fields: newSection.fields.map((field: any) => ({
          ...field,
          defaultValue: custom.fields.find((customField: any) => customField.name === field.name).value ?? '',
        })),
      };
    }),
  ];
};
