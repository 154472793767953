import { IPagination } from 'interfaces/common';
import { IResourceAssignItem } from 'interfaces/resources.interface';
import appService from 'services/common';

export interface IUserResourcesData {
  id: string;
  resources: {
    code: string;
    id: string;
    name: string;
    parametersContent: string;
  };
}

export const getResourcesAssignedToUserByUserId = async (userId: string) => {
  return await appService.get(`/onboarding-resources-assign-manage/${userId}/user`, {
    params: { size: 100 },
  });
};

export const removeResourcesByAssignId = async (assignId: string, resources: string[]) => {
  return await appService.post(`/onboarding-resources-assign-manage/${assignId}/remove/resources`, resources);
};
export const editResourcesByAssignId = async (assignId: string, data: IResourceAssignItem) => {
  return await appService.put(`/onboarding-resources-assign-manage/${assignId}`, data);
};

export const postAssignResourcesToUser = async (data: IResourceAssignItem) => {
  try {
    return await appService.post('/onboarding-resources-assign-manage/add', data);
  } catch (error) {
    return error;
  }
};
