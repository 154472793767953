import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewTasksManage: IView = {
  key: 'viewTasksUserMission',
  title: 'tasks.taskCreation.taskBase',
  roles: [
    IRoles.ADMINISTRATOR,
    IRoles.SUPERIOR,
    IRoles.HR,
    IRoles.BUDDY,
    IRoles.EMPLOYEE,
    IRoles.NEWEMPLOYEE,
    IRoles.CANDIDATE,
  ],
  view: React.lazy(() => import('./mission-view')),
  path: '/zadania/misja/:id',
};

export default ViewTasksManage;
