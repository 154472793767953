import { Avatar, AvatarProps } from '@mui/material';
import { fetchAvatar } from 'services/usersService';
import { useEffect, useState } from 'react';

interface IGUAvatarProps extends AvatarProps {
  avatarId?: string;
  avatarUrl?: string;
}

export const GUAvatar = (props: IGUAvatarProps) => {
  const { avatarUrl, avatarId, alt } = props;
  const [userAvatar, setUserAvatar] = useState('');
  const getAvatar = async () => {
    if (avatarUrl) {
      setUserAvatar(avatarUrl);
    } else if (avatarId) {
      const imageUrl = await fetchAvatar(avatarId);
      setUserAvatar(imageUrl);
    } else {
      const imageUrl = await fetchAvatar(undefined);
      setUserAvatar(imageUrl);
    }
  };
  useEffect(() => {
    getAvatar();
  }, [avatarId]);

  return <Avatar {...props} alt={alt} src={userAvatar} />;
};
