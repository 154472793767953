import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewFileUpload: IView = {
  key: 'viewFileTemplateUpload',
  title: 'fileTemplateView.title',
  view: React.lazy(() => import('./add-file-manage')),
  roles: [IRoles.ADMINISTRATOR, IRoles.HR],
  path: ['/dodaj-szablon-pliku', '/edytuj-szablon-pliku/:id'],
};

export default ViewFileUpload;
