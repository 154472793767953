import { SvgIcon, SvgIconProps } from '@mui/material';

export const BrokenUpArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.445312" width="64" height="64" rx="32" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4453 25.334C35.4453 24.7817 35.893 24.334 36.4453 24.334H44.4453C44.9976 24.334 45.4453 24.7817 45.4453 25.334V33.334C45.4453 33.8863 44.9976 34.334 44.4453 34.334C43.893 34.334 43.4453 33.8863 43.4453 33.334V27.7482L34.4858 36.7078C34.0952 37.0983 33.4621 37.0983 33.0715 36.7078L28.4453 32.0815L21.1524 39.3744C20.7619 39.7649 20.1287 39.7649 19.7382 39.3744C19.3477 38.9839 19.3477 38.3507 19.7382 37.9602L27.7382 29.9602C28.1287 29.5697 28.7619 29.5697 29.1524 29.9602L33.7786 34.5864L42.0311 26.334H36.4453C35.893 26.334 35.4453 25.8863 35.4453 25.334Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
