import React from 'react';
import { CardMedia, CardActions, Button, Theme, Typography } from '@mui/material';
import GreenCheckIcon from 'assets/icons/GreenCheckIcon.svg';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
interface IProps {
  onDownload?: () => void;
}
export const FileLoadedCard: React.FC<IProps> = ({ onDownload }) => {
  const onDownloadFile = () => {
    if (onDownload) {
      onDownload();
    }
  };
  return (
    <>
      <CardMedia
        component="img"
        image={GreenCheckIcon}
        alt="random icon"
        sx={(theme: Theme) => ({
          width: 92,
          height: 92,
          mb: theme.spacing(4),
        })}
      />
      <Typography variant="subtitle1">Dokument załadowany</Typography>
      <CardActions
        sx={{
          p: 0,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Button
          fullWidth
          sx={{ borderRadius: 0, height: 74, fontSize: 16 }}
          variant="contained"
          color="success"
          onClick={onDownloadFile}
          startIcon={<RemoveRedEyeOutlinedIcon />}
        >
          Zobacz dokument
        </Button>
      </CardActions>
    </>
  );
};
