import { IDefaultInteface, IResponse, IResponseWithPagination } from 'interfaces/common';
import { IFiles, IFileTemplates } from 'interfaces/files.interface';
import appService from 'services/common';

const getTemplatesFile = (params?: object) => {
  return appService.get<IResponse<IResponseWithPagination<IFileTemplates[]>>>('/onboarding-template-files-manage', {
    params,
  });
};
const getTemplatesFileItem = (id: string) => {
  return appService.get<IResponse<IFileTemplates>>('/onboarding-template-files-manage/' + id);
};

const putTemplatesFiles = (id: string, data: FormData) => {
  return appService.put<IResponse<IDefaultInteface[]>>('/onboarding-template-files-manage/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const downloadFileById = (id: string) => {
  return appService.get<Blob>(`/onboarding-template-files-manage/${id}/download`, {
    responseType: 'blob',
  });
};

const postTemplatesFiles = (data: FormData) => {
  return appService.post<IResponse<IResponseWithPagination<string>>>('/onboarding-template-files-manage', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export { getTemplatesFile, putTemplatesFiles, postTemplatesFiles, getTemplatesFileItem, downloadFileById };
