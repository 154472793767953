import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewPreviewSurveyManage: IView = {
  key: 'viewPreviewSurveyManage',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./preview-survey-view')),
  path: '/tasks-manage/survey-preview/:id',
};

export default ViewPreviewSurveyManage;
