import { StatusProgress } from 'interfaces/user.interface';

export const setProgressValueBasedOnStatus = (
  status: StatusProgress,
  preonboardingValue: number | null,
  onboardingValue: number | null
) => {
  switch (status) {
    case StatusProgress.BOARDED:
      return (onboardingValue ?? 0) + (preonboardingValue ?? 0);
    case StatusProgress.PREONBOARDING:
      return preonboardingValue ?? 0;
    case StatusProgress.ONBOARDING:
      return (onboardingValue ?? 0) + (preonboardingValue ?? 0);
    default:
      return 0;
  }
};

export const userProgressValue = (percent = 0) => {
  return percent > 100 ? 100 : Math.round(percent);
}