import React from 'react';
import { IView } from 'interfaces/common';
import { IRoles } from 'interfaces/roles.interface';

const ViewTasksManage: IView = {
  key: 'viewTasksManage',
  title: 'tasks.taskCreation.taskBase',
  roles: [IRoles.ADMINISTRATOR, IRoles.SUPERIOR, IRoles.HR],
  view: React.lazy(() => import('./tasks-page')),
  navigation: true,
  path: '/tasks-manage',
};

export default ViewTasksManage;
