import { DialogActions, DialogContent, DialogTitle, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { CustomButton, CustomDialog, CustomInput, CustomSelect, Loader } from 'components/common';
import { t } from 'i18next';
import { ButtonType, ButtonSize, IconName } from 'interfaces/button.interface';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
interface IAddSectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  lengthSection?: number;
}
export const defaultLeftTextAndImage = {
  name: 'section-custom-1',
  label: '',
  index: 1,
  rows: 2,
  fields: [
    {
      row: 1,
      name: 'headline',
      label: '1 - Nagłówek',
      type: 'text',
    },
    {
      row: 1,
      name: 'text',
      label: '2 - Treść',
      type: 'text',
      lines: 2,
    },
    {
      row: 2,
      name: 'image',
      label: 'Dodaj wideo lub obrazek',
      type: 'media',
    },
  ],
};
export const defaultRightTextAndImage = {
  name: 'section-custom-2',
  label: '',
  index: 1,
  rows: 2,
  fields: [
    {
      row: 2,
      name: 'headline',
      label: '1 - Nagłówek',
      type: 'text',
    },
    {
      row: 2,
      name: 'text',
      label: '2 - Treść',
      type: 'text',
      lines: 2,
    },
    {
      row: 1,
      name: 'image',
      label: 'Dodaj wideo lub obrazek',
      type: 'media',
    },
  ],
};

const defaultSections = [defaultLeftTextAndImage, defaultRightTextAndImage];
export const AddSectionDialog = ({ open, onClose, onSubmit, lengthSection }: IAddSectionDialogProps) => {
  const [currentSection, setCurrentSection] = useState('section-custom-1');

  const onChangeSection = (e: SelectChangeEvent<string>) => {
    setCurrentSection(e.target.value);
  };
  const handleSubmitForm = () => {
    const selectedSection = defaultSections.find((value) => value.name === currentSection);
    if (selectedSection && lengthSection) {
      onSubmit({
        ...selectedSection,
        name: selectedSection.name + '-' + (lengthSection+1),
        id: uuid(),
        index: lengthSection + 1,
      });
      onClose();
    }
  };
  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: '30px' }}>Dodaj nową sekcję</DialogTitle>
      <DialogContent>
        <Typography mb={(theme) => theme.spacing(3)}>Aby dodać nową sekcję, wybierz rodzaj sekcji z listy</Typography>
        <CustomSelect
          label={'Wybierz szablon'}
          value={currentSection}
          onChange={onChangeSection}
          options={[
            {
              label: 'Szablon treść i obrazek (lewa)',
              value: 'section-custom-1',
            },
            {
              label: 'Szablon treść i obrazek (prawa)',
              value: 'section-custom-2',
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          type="button"
          text={t('button.add')}
          onClick={handleSubmitForm}
          buttonType={ButtonType.SECONDARY_ACCENT}
          size={ButtonSize.SMALL}
          sx={{
            width: 1,
          }}
        />
      </DialogActions>
    </CustomDialog>
  );
};
