import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12025 12C8.12025 14.1538 9.85575 15.8974 11.9996 15.8974C14.1435 15.8974 15.879 14.1538 15.879 12C15.879 9.84616 14.1435 8.10256 11.9996 8.10256C9.85575 8.10256 8.12025 9.84616 8.12025 12ZM9.75366 12C9.75366 10.7692 10.7745 9.74359 11.9996 9.74359C13.2247 9.74359 14.2455 10.7692 14.2455 12C14.2455 13.2308 13.2247 14.2564 11.9996 14.2564C10.7745 14.2564 9.75366 13.2308 9.75366 12Z"
        fill="#111826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14389 12.5226C1.71822 13.5 5.5378 20 12 20C18.4483 20 22.2653 13.4368 22.8524 12.4274C22.888 12.3661 22.9117 12.3253 22.9234 12.3077C23.0255 12.1026 23.0255 11.7949 22.9234 11.5897C22.9122 11.5728 22.8897 11.5345 22.8561 11.4774C22.2818 10.5 18.4622 4 12 4C5.55171 4 1.73467 10.5632 1.14765 11.5726C1.11199 11.6339 1.08826 11.6747 1.07657 11.6923C0.974478 11.8974 0.974478 12.2051 1.07657 12.4103C1.08784 12.4272 1.11032 12.4655 1.14389 12.5226ZM12 18.359C6.89559 18.359 3.52668 13.4359 2.70998 12C3.52668 10.5641 6.89559 5.64103 12 5.64103C17.1044 5.64103 20.4733 10.5641 21.29 12C20.4733 13.3333 17.2065 18.359 12 18.359Z"
        fill="#111826"
      />
    </SvgIcon>
  );
};
