import React, { useState } from 'react';
import { Box, Button, Collapse, IconButton, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { UpArrow } from 'assets/icons/UpArrow';
import { DownArrow } from 'assets/icons/DownArrow';
import { HelpIcon } from 'assets/icons/HelpIcon';
import { CustomSwitch } from 'components/common';
import { ArrowChevronUp, TrashIcon } from 'assets/icons';
import { WithChildren } from 'interfaces/with-children';

type ExpandableSectionComponentProps = WithChildren<{
  title: string;
  id?: string;
  hideDelete?: boolean;
  open?: boolean;
  hide?: boolean;
  showArrows?: boolean;
  showDisableToggle?: boolean;
  showInfoIcon?: boolean;
  onDelete?: (value: any) => void;
  onIndexUp?: (value: any) => void;
  onIndexDown?: (value: any) => void;
  onDisableToggle?: (value: any) => void;
}>;

export const ExpandableSectionComponent = ({
  title,
  onDelete,
  hideDelete,
  showArrows = true,
  showDisableToggle = true,
  showInfoIcon = true,
  onIndexDown,
  onIndexUp,
  hide = false,
  onDisableToggle,
  children,
  id,
  open,
}: ExpandableSectionComponentProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(open ?? false);

  const onActive = () => {
    if (onDisableToggle) {
      onDisableToggle(id);
    }
    setActive(!active);
  };
  return (
    <Box
      sx={{
        overflow: hide ? 'hidden' : 'none',
        height: hide ? 0 : 'auto',
        overflowX: { xs: 'scroll', md: 'inherit', mb: (theme) => theme.spacing(3) },
      }}
    >
      <Box
        sx={(theme: Theme) => ({
          width: 1,
          height: { md: 40, xs: 70 },
          backgroundColor: theme.palette.grey[300],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          mt: theme.spacing(10),
          minWidth: 700,
        })}
      >
        <Typography variant="body2">{title}</Typography>
        <Stack spacing={2} direction="row">
          {showArrows && (
            <>
              <Tooltip title="Przesuń sekcję w górę" placement="top" arrow>
                <IconButton onClick={onIndexUp} sx={{ p: 0 }}>
                  <UpArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title="Przesuń sekcję w dół" placement="top" arrow>
                <IconButton onClick={onIndexDown} sx={{ p: 0 }}>
                  <DownArrow />
                </IconButton>
              </Tooltip>
            </>
          )}
          {showInfoIcon && (
            <Tooltip
              title="Automat wypełnił teksty do baneru głównego, możesz je edytować poniżej."
              placement="top"
              arrow
            >
              <IconButton sx={{ p: 0 }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          )}
          {hideDelete && (
            <Tooltip title="Usuń sekcje." placement="top" arrow>
              <IconButton onClick={onDelete} sx={{ p: 0 }}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          )}
          {showDisableToggle && (
            <Tooltip title="Włącz / wyłącz sekcję" placement="top" arrow>
              <Box>
                <CustomSwitch checked={active} onChange={onActive} />
              </Box>
            </Tooltip>
          )}

          <Button
            color="secondary"
            sx={{
              backgroundColor: 'white',
              p: '0px 16px',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            endIcon={!collapsed ? <ArrowChevronUp /> : <ArrowChevronUp sx={{ transform: 'rotate(180deg)' }} />}
            onClick={() => setCollapsed(!collapsed)}
          >
            <Typography variant="subtitle1">{!collapsed ? 'Zwiń' : 'Rozwiń'}</Typography>
          </Button>
        </Stack>
      </Box>
      <Collapse sx={{ boxShadow: '4px 20px 30px rgba(0, 0, 0, 0.1)' }} in={!collapsed}>
        <Box
          sx={{
            p: (theme: Theme) => ({ md: theme.spacing(10, 14), xs: theme.spacing(3) }),
            position: 'relative',
            minWidth: 700,
            overflow: 'hidden',
          }}
        >
          {active && (
            <Box
              sx={{
                position: 'absolute',
                width: 1,
                height: 1,
                top: '0px',
                left: '0px',
                backgroundColor: '#c6c9d0a8',
                zIndex: 10,
              }}
            />
          )}
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
